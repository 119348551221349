/* eslint-disable no-unused-vars */
import { Field, Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import { valueFormat, hasPermission, parseClasses, parseURL } from '../utils'
import CustomForm from './common/forms/CustomForm'
import TextInput from './common/forms/inputs/Text'
import QueryBuilder from './common/QueryBuilder'
import { Button } from './ui/Button'
import { Scrollbar } from './ui/Scrollbars'
// import AdvancedSearch from './common/search/AdvancedSearch'
import FieldGroup from './common/forms/FieldGroup'
import Meta from './common/datatable/Meta'
import DataTable from './common/datatable/DataTable'
import Card from './common/Card'


const RecipientSelectSidebar = props => {
  const [ loading, setLoading ] = useState(false)
  const [ results, setResults ] = useState([])
  const [ searching, setSearching ] = useState(false)
  let form = useRef(null)

  useEffect(() => {
    const fetchModel = () => new Promise((resolve, reject) => {
      const values = {
        modelname: props.config.modelname,
        endpoint: props.config.endpoint,
        params: props.config.params,
        modellist: true
      }
      props.actions.fetchMany({ values, resolve, reject })
    })
    fetchModel().then(() => { setLoading(false) })
    return () => {
      props.actions.toggleWideSidebar()
    }
  }, [])

  const handleSubmit = (values, actions) => {
    const { params, field, modelname } = props
    const { fetchMany } = props.actions
    setSearching(true)
    setResults([])
    const vals = {
      modelname,
      conflicts: true,
      term: values.term,
      params: { ...values },
      modellist: true
    }
    new Promise((resolve, reject) => {
      fetchMany({ values: vals, resolve, reject })
    }).then(r => {
      setResults(r)
      setSearching(false)
      actions.setSubmitting(false)
    }).catch(() => {
      setSearching(false)
      actions.setSubmitting(false)
    })
  }

  const resetPage = step => { // Reset page to initial
    const hdrs = document.querySelectorAll('.orderable')
    for (const hdr of hdrs) { hdr.classList.remove('orderedby', 'reversed') }
    if (step) { hdrs[0].classList.add('orderedby') }
  }

  const handleReset = () => { // Handles reset of the advanced search form
    resetPage(true)
    const search = new URLSearchParams(props.config.params).toString()
    props.history.push(`${this.props.history.location.pathname}?${search}`)
  }

  const addVerb = formikProps => {
    const { advsearch } = props.config
    if (advsearch) {
      return (
        <div className="search-fields">
          {advsearch.map((group, gidx) => {
            const fields = group.map(field => {
              const fieldProps = props.config.fields.find(f => f.name === field.name)
              let classes = field.classes ? parseClasses(field.classes, form.values) : ''
              classes = classes.split(' ')
              let cols = classes.find(c => c.includes('col-'))
              classes = classes.filter(c => !c.includes('col-'))
              cols = cols.replace('col-', '')
              const verbprops = {
                ...fieldProps,
                ...field,
                group: `group-${gidx}`,
                name: `${field.name}${field.verb ? `__${field.verb}` : ''}`,
                classes,
                required: false,
                readOnly: false,
                quality: false,
                cols,
                match: props.match,
                id: field.id ? `field-${field.id}-${field.name}` : `field-${field.name}`
              }
              if (verbprops.input?.endsWith('Notes')) {
                verbprops.input = verbprops.input.replace('Notes', '')
              }
              if (verbprops.input === 'Extras') {
                verbprops.hidelegend = true
              }
              if (verbprops.input === 'LocationSelect') {
                if (verbprops.extraparams && form) { // Used for form submission
                  verbprops.params = parseURL(
                    verbprops.extraparams,
                    form.values,
                    props.user ? props.user.agent : false,
                    props.user ? props.cache.settings[this.props.user.agent.site.id] : false
                  )
                }
                if ([ 'locations', 'suburbs' ].includes(verbprops.modelname) && this.state.areas) {
                  props.params = parseURL(
                    `${verbprops.extraparams}&id__in=${Object.keys(this.state.locations).join(',')}`,
                    { ...this.form.values, area__in: Object.keys(this.state.areas) },
                    this.props.user ? this.props.user.agent : false,
                    this.props.user ? this.props.cache.settings[this.props.user.agent.site.id] : false
                  )
                  props.force_filter = this.state.locations ? this.state.locations : null
                }
                if ([ 'areas' ].includes(verbprops.modelname) && this.state.areas) {
                  props.params = parseURL(
                    `${verbprops.extraparams}&id__in=${Object.keys(this.state.areas).join(',')}`,
                    { ...this.form.values },
                    this.props.user ? this.props.user.agent : false,
                    this.props.user ? this.props.cache.settings[this.props.user.agent.site.id] : false
                  )
                  props.force_filter = this.state.areas ? this.state.areas : null
                }
              }
              return verbprops
            })
            return (
              <FieldGroup
                card={false}
                groupname={`group-${gidx}`}
                key={`group-${gidx}`}
                match={props.match}
                config={{
                  fields
                }}
                fields={fields}
                render={({ renderFieldGroup, hidden }) => {
                  if (hidden) { return null }
                  return renderFieldGroup({
                    fields
                  })
                }}
              />
            )
          })}
        </div>
      )
    }
    return null
  }

  const { user, config, modelname, model, selected, cache } = props
  if (loading) { return null }
  return (
    <div id="recipient-selector-sidebar" ref={el => el} className="wide-sidebar recipient-selector-sidebar">
      <div className="wide-sidebar-container">
        <Formik
          initialValues={{}}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >{ formik => {
            form = formik
            return (
              <CustomForm
                component="div"
                render={() => (
                  <div className="wide-sidebar-pane">
                    <div className="wide-sidebar-heading">
                      <h4>Search {config.plural}</h4>
                      <Button
                        type="button"
                        icon="#icon24-X-Large"
                        className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                        onClick={() => { props.actions.toggleWideSidebar() }}
                      />
                    </div>
                    <Scrollbar
                      style={{ height: 'calc(100vh - 218px)' }}
                      renderView={({ style, ...rest }) => <div {...rest} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                    >
                      <div className="wide-sidebar-content">
                        <Field
                          component={TextInput}
                          name="term"
                          label="Search"
                          _value={form.values.term}
                          defaultValue={form.initialValues.term}
                          id="term"
                          type="text"
                        />

                        {props.config && addVerb(formik)}

                        <div className="search-buttons">
                          <Button
                            icon="#icon16-Search"
                            type="button"
                            disabled={formik.isSubmitting || !formik.dirty}
                            className="btn btn-primary btn-icon-16 btn-icon-left"
                            onClick={form.handleSubmit}
                          >Search</Button>

                          {formik.status && formik.status.success &&
                            <Button type="button" onClick={e => {
                              form.resetForm()
                            }} title="Reset filters" icon="#icon16-Refresh" className="btn btn-primary btn-icon-16 btn-icon-left">
                              Reset Filters
                            </Button>
                          }
                        </div>

                        {props.config && props.model && props.model.params ? (
                          <Meta
                            modelname={props.config.modelname}
                            toggleManager={props.actions.toggleManager}
                            model={model}
                            match={props.match}
                            config={props.config}
                            params={(props.model) ? props.model.params : null}
                            count={(props.model) ? props.model.count : 0}
                            previous={(props.model) ? props.model.previous : null}
                            next={(props.model) ? props.model.next : null}
                            advanced={true}
                            user={props.user}
                            cache={props.cache}
                            fetchMany={props.actions.fetchMany}
                            findStatus={() => config.statusField}
                            findDomain={() => null}
                            selectedRecords={selected}
                            noquery={true}
                          />
                        ) : null }

                        {(results && results.length) ? (
                          <>
                            <fieldset className="editgroup">
                              <div><h3>Results</h3></div>
                              <Card
                                background={false}
                                body={
                                  <div className="listbody">
                                    <DataTable
                                      actions={props.actions}
                                      model={model}
                                      cache={cache[config.modelname]}
                                      currency={cache.settings[user.agent.site.id].default_currency}
                                      region={cache.settings[user.agent.site.id].region}
                                      portals={cache.portals}
                                      match={props.match}
                                      config={config}
                                      tableconfig={props.tableconfig}
                                      selected={selected}
                                      resetPage={resetPage}
                                      selectable={true}
                                      meta={true}
                                      user={{
                                        permissions: user.permissions,
                                        agent: {
                                          id: user.agent.id,
                                          branches: user.agent.branches,
                                          site: user.agent.site
                                        }
                                      }}
                                      domains={null}
                                      redirectSchema={null}
                                      advanced={true}
                                      findStatus={() => config.statusField}
                                      findDomain={() => null}
                                    />
                                  </div>
                                }
                              />
                            </fieldset>
                            <div className="search-buttons">
                              <Button className="btn btn-primary" type='button' onClick={() => props.addSelected(modelname, selected)}>Add Selected</Button>
                              <Button className="btn btn-primary" type='button' onClick={() => props.addAll(modelname, model)}>Add All</Button>
                            </div>
                          </>
                        ) : null }
                      </div>
                    </Scrollbar>
                  </div>
                )}
              />
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

RecipientSelectSidebar.propTypes = {
  config: PropTypes.object,
  configs: PropTypes.object,
  model: PropTypes.object,
  location: PropTypes.object,
  limit: PropTypes.number,
  field: PropTypes.object,
  siblingform: PropTypes.object,
  actions: PropTypes.object,
  history: PropTypes.object,
  autofill: PropTypes.object,
  tableconfig: PropTypes.object,
  meta_cache: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object,
  sidebar: PropTypes.string,
  label: PropTypes.string,
  params: PropTypes.string,
  selectContact: PropTypes.func,
  toggleLookup: PropTypes.func,
  modelname: PropTypes.string,
  selected: PropTypes.array,
  addSelected: PropTypes.func,
  addAll: PropTypes.func
}

export default RecipientSelectSidebar
