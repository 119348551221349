/* eslint-disable new-cap */
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { List, Map } from 'immutable'

import ModelEdit from '../../components/common/ModelEdit'
import { withStep } from '../../components/common/StepComponent'
import { MINUSER, PORTALS, GLOBALPORTALS, CACHEDMODELID, SELECTED, CACHE, ADDONS, CONFIG, MODELNAME, MODELID, SETTINGS } from '../../selectors'


const mapStateToProps = state => {
  const modelname = MODELNAME(state)
  const modelid = MODELID(state)
  let model = CACHEDMODELID(state, modelname, modelid)
  const branches = CACHE(state, 'branches')
  const globalportals = GLOBALPORTALS(state)
  const portals = PORTALS(state)
  const selected = SELECTED(state, modelname)
  let config = CONFIG(state, modelname)
  const addons = ADDONS(state)
  const minuser = MINUSER(state)
  const siteid = minuser.getIn([ 'agent', 'site', 'id' ])
  const settings = SETTINGS(state, siteid)
  const cache = CACHE(state)

  if (modelname === 'theme-settings') {
    // Theme settings config is defined on the theme model
    // If the theme model has variables, then we need to set the config
    const thememodel = model
    model = null
    if (thememodel?.getIn([ 'meta', 'theme', 'theme_config' ])) {
      config = config.set('fields', JSON.parse(thememodel.getIn([ 'meta', 'theme', 'theme_config' ])).map(f => {
        f.group = 'Theme Settings'
        return f
      }))
      if (thememodel?.get('variables')) {
        let values = Map({ id: thememodel.get('id'), thememodelname: 'theme-settings', domain: thememodel.get('domain') })
        thememodel.get('variables').forEach(field => {
          if (![ null, undefined ].includes(field.get('value'))) {
            values = values.set(field.get('variable_key'), field.get('value'))
          }
        })
        model = values
      }
    }
  }

  if (minuser && minuser.get('agent') && model &&
    [ 'residential', 'commercial', 'holiday', 'projects', 'agents' ].includes(modelname) &&
    minuser.getIn([ 'agent', 'site' ])) {
    const serviced_locations = settings.getIn([ 'serviced_locations' ])
    const enforce_serviced_locations = settings.getIn([ 'enforce_serviced_locations' ])
    if (serviced_locations && enforce_serviced_locations) {
      if (modelname === 'agents') {
        let allowed_areas = List()
        let allowed_suburbs = List()
        const display_branches = model.get('display_on_branches')
        if (display_branches) {
          display_branches.forEach(b => {
            allowed_areas = allowed_areas.concat(branches.getIn([ `${b}`, 'serviced_areas' ]))
            allowed_suburbs = allowed_suburbs.concat(branches.getIn([ `${b}`, 'serviced_suburbs' ]))
          })
        }
        model = model.set('allowed_areas', allowed_areas)
        model = model.set('allowed_suburbs', allowed_suburbs)
      }
    }
  }

  return {
    modelname,
    modelid,
    model,
    config,
    cache,
    selected,
    user: minuser,
    portals,
    globalportals,
    addons
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { routeConfig, location, className, match, actions } = ownProps
  return ({ ...stateProps, routeConfig, location, className, match, actions })
}

export default connect(mapStateToProps, null, mergeProps)(withImmutablePropsToJS(withStep(ModelEdit)))
