/* eslint-disable new-cap */
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany } from '../../actions'
import { CONFIG, MINUSER } from '../../selectors'
import Card from '../common/Card'
import SimpleTable from '../common/simpletable/SimpleTable'
import InlineSelect from '../common/forms/inputs/InlineSelect'
import InlineAgentSelect from './inputs/InlineAgentSelect'
import InlineBranchSelect from './inputs/InlineBranchSelect'
import withDelay from './withDelay'


const STAGES = [
  { label: 'Stage', value: '' },
  { label: 'New', value: 'New' },
  { label: 'Contacted', value: 'Contacted' },
  { label: 'Viewing', value: 'Viewing' },
  { label: 'Offer', value: 'Offer' },
  { label: 'Sold / Rented', value: 'Sold' },
  { label: 'Inactive', value: 'Inactive' },
  { label: 'Rental Application', value: 'Rental Application' }
]

const LatestLeadsWidget = ({ actions, user, branches, config }) => {
  const [ branchId, setBranchId ] = useState(branches ? branches[0] : '')
  const [ agentId, setAgentId ] = useState(user.agent.id || '')
  const [ stage, setStage ] = useState('')

  const abortController = useRef(new AbortController())

  useEffect(() => () => {
    abortController.current.abort()
  }, [])

  return (
    <Card
      id="latest-leads-widget"
      classes="grid-col-1"
      bodyclass="stats-card no-top-padding latest-leads-widget"
      background
      header={
        <>
          <h3>Latest Leads</h3>
          <div className="details-section-buttons min-flex tablemeta">
            <InlineBranchSelect
              branches={branches}
              user={user}
              actions={actions}
              selectedValue={branchId}
              onChange={e => {
                setBranchId(e.value)
                setAgentId('')
              }}
            />
            <InlineAgentSelect
              user={user}
              actions={actions}
              branchId={branchId}
              selectedValue={agentId}
              onChange={e => {
                setAgentId(e.value)
              }}
            />
            <div className="filter-stage">
              <InlineSelect
                id="stage"
                name="stage"
                className="inline-select"
                classNamePrefix="inline"
                options={STAGES}
                selectedValue={stage}
                onChange={e => {
                  setStage(e.value)
                }}
              />
            </div>
          </div>
        </>
      }
      body={
        <SimpleTable
          config={config}
          action={({ resolve, reject }) => actions.fetchMany({
            noloader: true,
            values: {
              modelname: 'leads',
              signal: abortController.current.signal,
              params: {
                contact__branch: branchId,
                contact_agents__in: agentId,
                status__in: user.agent.site.region !== 'ae' ? [ 'Active', 'No-Consent', 'Non-Compliant' ] : [ 'Active' ],
                stage,
                order_by: '-created',
                limit: 10,
                fields: [
                  'id',
                  'created',
                  'source',
                  'contact',
                  'residential',
                  'commercial',
                  'holiday',
                  'project',
                  'contact',
                  'stage',
                  'first_name',
                  'last_name',
                  'status'
                ],
                meta_fields: [
                  'residential',
                  'commercial',
                  'holiday',
                  'project',
                  'contact'
                ]
              }
            }, resolve, reject
          })}
          params={{
            stage,
            contact__branch: branchId,
            contact_agents__in: agentId
          }}
          header={[
            {
              label: 'Date Added',
              name: 'created',
              orderable: false,
              format: 'datetime'
            },
            {
              label: 'Address',
              name: [
                'listing.unit_number',
                'listing.complex_name',
                ',',
                'listing.building_name',
                ',',
                'listing.street_number',
                'listing.street_name'
              ],
              orderable: false,
              container: 'meta',
              link: '/secure/:site/:meta.listing.model/:meta.listing.id'
            },
            {
              label: 'Source',
              name: 'source',
              orderable: false
            },
            {
              label: 'Contact',
              name: 'contact',
              orderby: 'contact__first_name',
              modelname: 'contacts',
              placeholder: 'Enter email, first name or last name',
              link: '/secure/:site/contacts/:contact',
              optionlabel: [ 'first_name', 'last_name' ],
              labelseparator: ' ',
              orderable: false
            },
            {
              label: 'Status',
              name: 'status',
              orderable: false
            },
            {
              label: 'Stage',
              name: 'stage',
              orderable: false,
              format: 'stage',
              link: ':meta.listing.link/../leads/:id'
            }
          ]}
          user={user}
        />
      }
    />
  )
}

LatestLeadsWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  config: PropTypes.object,
  branches: PropTypes.arrayOf(PropTypes.number)
}


const mapStateToProps = state => {
  const user = MINUSER(state)
  const config = CONFIG(state, 'leads')
  return ({
    user,
    config
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(withDelay(LatestLeadsWidget)))
