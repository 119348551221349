import PropTypes from 'prop-types'
import React from 'react'
import { Formik, Field, getIn } from 'formik'
import { matchPath } from 'react-router-dom'

import log from '../logging'
import { buildLambdaURI } from '../utils'
import TextInput from './common/forms/inputs/Text'
import Loader from './common/Loader'
import Footer from './Footer'
import { Button } from './ui/Button'
import { Scrollbar } from './ui/Scrollbars'
import CustomForm from './common/forms/CustomForm'


class AgentSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = { domain: '' }
    this.siteinput = null
    this.updateInput = this.updateInput.bind(this)
  }

  componentDidMount() {
    const { actions, user, ui } = this.props
    const { agents, retoke } = user
    let redirect = ui.redirect
    const retoke_props = retoke || {}
    if (location.pathname.startsWith('/secure')) { redirect = location }
    if (redirect?.pathname?.startsWith('/secure')) {
      const secure_match = matchPath(getIn(ui.redirect, [ 'pathname' ], ui.redirect), { path: '/secure/:site(\\d+)', exact: false, strict: false })
      if (secure_match) {
        const site = parseInt(secure_match.params.site, 10)
        const site_agents = agents.filter(a => a.site.id === site)
        if (site_agents.length === 1) {
          retoke_props.siteid = site_agents[0].site.id
          retoke_props.agentid = site_agents[0].id
        }
      }
    }
    if (retoke_props?.siteid) {
      new Promise((resolve, reject) => (actions.applyRetoke({
        ...user, retoke: retoke_props
      }, agents, redirect, resolve, reject)))
        .then(() => {
          log.info('Retoke applied')
        })
        .catch(() => {
          log.error('Apply retoke failed')
        })
    }
    if (this.siteinput) { this.siteinput.focus() } // Auto-focus on site text input
  }

  updateInput(e) {
    this.setState({ domain: e.target.value })
  }

  render() {
    const { user, actions, ui } = this.props
    const { agents } = user
    const cropped = []
    const filteredagents = agents.filter(agent => {
      // allow partial search match on domain or site id
      const domain = `${getIn(agent, 'site.id', '')}:${getIn(agent, 'site.domain', '')}:${getIn(agent, 'site.website_name', '')}`.toLowerCase()
      return domain.indexOf(this.state.domain ? this.state.domain.toLowerCase().trim() : '') !== -1
    })
    filteredagents.forEach(agent => {
      if (agent.image_url) {
        const parms = {
          cx: agent.profile_picture_coord_x,
          cy: agent.profile_picture_coord_y,
          cw: agent.profile_picture_width,
          ch: agent.profile_picture_height,
          w: agent.profile_picture_width,
          h: agent.profile_picture_height
        }
        const c = buildLambdaURI(agent.image_url, parms)
        cropped.push(c)
      } else if (agent.site.favicon) {
        cropped.push(buildLambdaURI(agent.site.favicon, { w: 180, h: 180 }))
      } else {
        cropped.push(null)
      }
    })
    if (agents.length === 1) { return <Loader noblock={true} /> } // agent will be loaded directly
    let loading = false
    if (ui.isLoading) { loading = true }
    return (
      <div className="wrapper login">
        {loading &&
          <Loader noblock={true} />
        }
        <div className="content">
          <div className="content-wrapper">
            <div className="login-box agentselector">
              <h3>Select a profile</h3>
              <div className="login-box-body">
                {user.is_prop_data_user &&
                  <Formik
                    enableReinitialize={true}
                    initialValues={{}}
                    onSubmit={values => {
                      this.setState({ domain: values.search })
                    }}
                  >{ formik => (
                      <CustomForm
                        component="div"
                        form={formik}
                        onChange={() => {
                          this.setState({ domain: formik.values.search })
                        }}
                        render={() => (
                          <Field
                            component={TextInput}
                            name="search"
                            id="search"
                            onChange={e => {
                              this.setState({ domain: e.target.value })
                            }}
                            suffix={(
                              <Button icon="#icon24-Search" type="button" className="input-group-addon btn btn-icon-24 btn-none" />
                            )}
                            autoComplete="off"
                            type="search"
                            placeholder="Enter a Domain or part thereof"
                            className="term input-group-suffix"
                            forwardRef={el => (this.siteinput = el)}
                          />
                        )}
                      />
                    )}
                  </Formik>
                }
                <Scrollbar style={{ height: 340 }}>
                  <div className="agent-list">
                    {filteredagents.map((agent, aidx) => (
                      <div className="agent-option" onClick={() => actions.selectAgent(agent, user)} key={`agent-${aidx}`}>
                        <div className="avatar avatar-md">
                          {!cropped[aidx] && user.is_prop_data_user ? (
                            <div className="thumbimg"><svg viewBox="0 0 32 32">
                              <path d="M15.671 5.87927C10.1449 5.87927 5.69104 10.4147 5.69104 16.042V28.3045C2.22693 25.3648 0 20.9974 0 16.042C0 7.13911 7.0107 0 15.7535 0C17.2381 0 18.6402 0.251969 19.9599 0.587927V6.88714C18.6402 6.21522 17.2381 5.87927 15.671 5.87927ZM25.7334 16.042C25.7334 21.6693 21.2795 26.2047 15.7534 26.2047C14.1863 26.2047 12.7842 25.8688 11.4645 25.1969V31.4121C12.8667 31.832 14.2688 32 15.7534 32C24.4137 32 31.5069 24.8609 31.5069 15.958C31.5069 11.0026 29.28 6.55118 25.8159 3.61155L25.7334 16.042ZM20.0423 16.042C20.0423 13.6063 18.1453 11.6745 15.7534 11.6745C13.3616 11.6745 11.4645 13.6063 11.4645 16.042C11.4645 18.4777 13.3616 20.4094 15.7534 20.4094C18.0629 20.4094 20.0423 18.3937 20.0423 16.042Z"/>
                            </svg></div>
                          ) : null}
                          {cropped[aidx] ? (
                            <div title={`${agent.first_name} ${agent.last_name}`} className="thumbimg">
                              <img alt={`${agent.first_name} ${agent.last_name}`} src={cropped[aidx]} />
                            </div>
                          ) : null }
                        </div>
                        <div>
                          <h4>{agent.first_name} {agent.last_name}</h4>
                          <div className="site-name">{agent.site.website_name}</div>
                          <div className="site-email">{agent.email ? agent.email : user.email}</div>
                          <div className="site-domain">{agent.site.domain}</div>
                        </div>
                      </div>
                    )) }
                    {filteredagents.length === 0 &&
                      <div className="notfound">
                        <h4>Sorry</h4>
                        No agents were found that match your filter.
                      </div>
                    }
                  </div>
                </Scrollbar>
              </div>
            </div>
            <Footer />
          </div>
          <div className="overlay"></div>
        </div>
      </div>
    )
  }
}

AgentSelector.propTypes = {
  user: PropTypes.object,
  actions: PropTypes.object,
  ui: PropTypes.object
}

export default AgentSelector
