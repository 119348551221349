import { Field, Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import { valueFormat, hasPermission } from '../utils'
import CustomForm from './common/forms/CustomForm'
import TextInput from './common/forms/inputs/Text'
import QueryBuilder from './common/QueryBuilder'
import { Button } from './ui/Button'
import { Scrollbar } from './ui/Scrollbars'


const ContactTable = props => (
  <div className="datatable contactselector">
    <div className="datatable-headers" data-columns="6">
      <div>Date & Time</div>
      <div>Full Name</div>
      <div>Assigned To</div>
      <div>Contact No.</div>
      <div>Email Address</div>
      <div></div>
    </div>
    <div className="datatable-body" data-columns="6">
      {props.children}
    </div>
  </div>
)

ContactTable.propTypes = {
  children: PropTypes.node
}

const ContactRow = props => {
  const { contact } = props
  return (
    <div className="datatable-row">
      <div><span className="tablecell">{valueFormat('date', contact.created)}</span></div>
      <div><span className="tablecell">{`${contact.first_name ? contact.first_name : ''} ${contact.last_name ? contact.last_name : ''}`}</span></div>
      <div><span className="tablecell">{`${contact.meta && contact.meta.agent ? contact.meta.agent.first_name : ''} ${contact.meta && contact.meta.agent ? contact.meta.agent.last_name : ''}`}</span></div>
      <div><span className="tablecell">{contact.cell_number}</span></div>
      <div><span className="tablecell">{contact.email}</span></div>
      <div><span className="tablecell select-contact" onClick={() => props.selectContact(contact)}><svg viewBox="0 0 32 32"><use href="/images/icons-16.svg#icon16-Plus" /></svg></span></div>
    </div>
  )
}

ContactRow.propTypes = {
  model: PropTypes.object,
  contact: PropTypes.object,
  config: PropTypes.object,
  updateModel: PropTypes.func,
  selectContact: PropTypes.func
}

const ContactSearchSidebar = props => {
  const [ initvals, setInitVals ] = useState({ ...props.autofill, active: true })
  const [ results, setResults ] = useState([])
  const [ searching, setSearching ] = useState(false)
  let form = useRef(null)

  useEffect(() => {
    const { autofill } = props
    const newinitvals = { ...autofill }
    props.config.fields.forEach(field => {
      if (field.twin && props.siblingform.values[field.name]) {
        newinitvals[field.name] = props.siblingform.values[field.name]
      }
    })
    setInitVals({ ...newinitvals, active: true })
    if (autofill && autofill.term) { form.submitForm() }
    return () => {
      props.actions.toggleWideSidebar()
    }
  }, [])

  useEffect(() => {
    const { autofill } = props
    const newinitvals = { ...autofill }
    props.config.fields.forEach(field => {
      if (field.twin && props.siblingform.values[field.name]) {
        newinitvals[field.name] = props.siblingform.values[field.name]
      }
    })
    setInitVals({ ...newinitvals, active: true })
    if (autofill && autofill.term) { form.submitForm() }
  }, [ props.autofill ])

  const handleSubmit = (values, actions) => {
    const { params, field } = props
    const { fetchMany } = props.actions
    const query = new QueryBuilder(params ? params : '?trigram_fields=first_name,last_name,email&status_in=Active,No-Consent,Non-Compliant')
    query.setParam('id__in__not', field.value?.filter(v => v))
    const queryparams = query.getAllArgs()
    queryparams.meta_fields = [ 'branch' ]
    setSearching(true)
    setResults([])
    const vals = {
      modelname: 'contacts',
      conflicts: true,
      term: values.term,
      params: queryparams
    }
    new Promise((resolve, reject) => {
      fetchMany({ values: vals, resolve, reject })
    }).then(r => {
      setResults(r)
      setSearching(false)
      actions.setSubmitting(false)
    }).catch(() => {
      setSearching(false)
      actions.setSubmitting(false)
    })
  }

  const { label, user, noadd } = props
  return (
    <div id="contact-creator-sidebar" ref={el => el} className="wide-sidebar contact-creator-sidebar">
      <div className="wide-sidebar-container">
        <Formik
          initialValues={initvals}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >{ formik => {
            form = formik
            return (
              <CustomForm
                component="div"
                render={() => (
                  <div className="wide-sidebar-pane">
                    <div className="wide-sidebar-heading">
                      <h4>{`${label}: Search Contacts`}</h4>
                      <Button
                        type="button"
                        icon="#icon24-X-Large"
                        className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                        onClick={() => { props.actions.toggleWideSidebar() }}
                      />
                    </div>
                    <Scrollbar
                      style={{ height: 'calc(100vh - 218px)' }}
                      renderView={({ style, ...rest }) => <div {...rest} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                    >
                      <div className="wide-sidebar-content">
                        <Field
                          component={TextInput}
                          name="term"
                          _value={form.values.term}
                          defaultValue={form.initialValues.term}
                          id="term"
                          type="text"
                          suffix={(
                            <Button
                              type="button" // This cannot be submit otherwise sibling form is submitted
                              icon="#icon24-Search"
                              onClick={formik.handleSubmit}
                              disabled={formik.isSubmitting}
                              className="btn btn-none input-group-addon btn-icon btn-icon-24"
                            />
                          )}
                        />
                        {(results && results.length) ? (
                          <fieldset className="editgroup">
                            { hasPermission([ 'contacts_add' ], user.permissions) && !noadd ?
                              <div className="datatable-row datatable-row-empty">
                                <div><span className="tablecell">{'Can\'t find the contact you\'re looking for?'}
                                  <Button component="a" href="#1" type="button" onClick={e => {
                                    e.preventDefault()
                                    props.actions.toggleWideSidebar()
                                    setTimeout(() => {
                                      props.toggleLookup()
                                    }, 1000)
                                  }} className="has-link">
                                    Add new
                                  </Button>
                                </span></div>
                              </div>
                              : null
                            }
                            <div className="datatable-row datatable-row-empty">
                              <div><h5>Results</h5>
                                <ContactTable results={results !== false ? results : false}>
                                  {results.map((result, midx) =>
                                    <ContactRow
                                      key={`lead-${midx}`}
                                      contact={result}
                                      selectContact={props.selectContact}
                                    />
                                  )}
                                </ContactTable>
                              </div>
                            </div>
                          </fieldset>
                        ) : null }
                        {formik.submitCount > 0 && !results.length && !searching ? (
                          <fieldset className="editgroup">
                            <h5>Results</h5>
                            <ContactTable results={results !== false ? results : false}>
                              <div className="datatable-row datatable-row-empty">
                                <div><span className="tablecell">No results found. {
                                  hasPermission([ 'contacts_add' ], user.permissions) ?
                                    <Button component="a" href="#1" type="button" onClick={e => {
                                      e.preventDefault()
                                      props.actions.toggleWideSidebar()
                                      setTimeout(() => {
                                        props.toggleLookup()
                                      }, 1000)
                                    }} className="has-link">
                                Add new
                                    </Button> :
                                    null}
                                </span></div>
                              </div>
                            </ContactTable>
                          </fieldset>
                        ) : null}
                      </div>
                    </Scrollbar>
                  </div>
                )}
              />
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

ContactSearchSidebar.propTypes = {
  config: PropTypes.object,
  field: PropTypes.object,
  siblingform: PropTypes.object,
  actions: PropTypes.object,
  autofill: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object,
  sidebar: PropTypes.string,
  label: PropTypes.string,
  params: PropTypes.string,
  selectContact: PropTypes.func,
  toggleLookup: PropTypes.func,
  noadd: PropTypes.bool
}

export default ContactSearchSidebar
