/* eslint-disable new-cap */
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { Map } from 'immutable'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { hasPermission, hasAddons, parseURL } from '../../utils'
import QueryBuilder from '../../components/common/QueryBuilder'
import ModelList from '../../components/common/ModelList'
import ModelChoice from '../../components/common/ModelChoice'
import { UI, MINUSER, SETTINGS, PREFERENCES, MODEL, CACHE, PORTALS, SELECTED, CONFIGS, ADDONS, CONFIG } from '../../selectors'


class ModelListContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      advanced: props.model && props.model.params.advanced ? true : false,
      error: false,
      fetching: false,
      checkedperms: false,
      searched: getIn(props, 'config.modelname') === 'portals' ? true : false
    }
    this.toggleAdvanced = this.toggleAdvanced.bind(this)
    this.hasViewPermission = this.hasViewPermission.bind(this)
    this.resetPage = this.resetPage.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.findStatus = this.findStatus.bind(this)
    this.findDomain = this.findDomain.bind(this)
    this._is_mounted = true
    this.timer = false
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.props.actions.dismissNotice() // Remove all notices on mount
    }, 3000)

    const limit = sessionStorage.getItem('limit') ? sessionStorage.getItem('limit') : getIn(this.props, 'config.params.limit')

    if (this.props.location.search &&
      Object.entries(this.props.tableconfig).length !== 0 &&
      !this.state.searched) { // Do we have query args in the URL and have they been applied?
      new Promise((resolve, reject) => {
        const qs = new QueryBuilder(this.props.location.search)
        const values = {
          modelname: this.props.config.modelname,
          endpoint: this.props.config.endpoint,
          params: { ...qs.getAllArgs(), limit },
          modellist: true
        }
        this.props.actions.fetchMany({ values, resolve, reject })
        if (this.props.config.advsearch) { // fetch the related models for the search fields
          this.props.config.advsearch.forEach(group => {
            group.filter(f => {
              if (f.modelname) { return true }
              const mfield = this.props.config.fields.find(cf => cf.name === f.name)
              if (mfield && mfield.modelname) { return true }
              return false
            }).forEach(field => { // Remove explicit false values
              let { name } = field
              const mfield = this.props.config.fields.find(cf => cf.name === name)
              if (field.verb) {
                name += `__${field.verb}`
              }
              if (values.params[name]) {
                const modelname = field.modelname || mfield.modelname
                const value = values.params[name]
                const searchvalues = {
                  modelname,
                  select: true,
                  params: {
                    id__in: value
                  },
                  signal: this.AbortController.signal
                }
                this.props.actions.fetchMany({ values: searchvalues, resolve, reject })
              }
            })
          })
        }
        this.setState({ searched: true })
      }).catch(e => {
        if (this._is_mounted) { this.setState({ error: e }) }
      }).then(() => {
        if (this._is_mounted) { this.setState({ error: false }) }
      })
    } else if (
      Object.entries(this.props.tableconfig).length !== 0) {
      const { modelname, endpoint } = this.props.config
      let { params } = this.props.model
      if (!params) { params = this.props.config.params }
      new Promise((resolve, reject) => {
        const values = {
          modelname: modelname,
          endpoint: endpoint,
          params: { ...params, limit },
          modellist: true
        }
        this.props.actions.fetchMany({ values, resolve, reject })
      }).catch(e => {
        if (this._is_mounted) { this.setState({ error: e }) }
      }).then(() => {
        if (this._is_mounted) { this.setState({ error: false }) }
      })
    }
  }

  componentDidUpdate(prevProps) {
    const { params, modelname, endpoint } = this.props.config
    const { settings } = this.props
    if (settings && !this.state.checkedperms && this.props.config && !this.hasViewPermission()) { // If we're not allowed to be here, redirect to designated redirect
      this.setState({ checkedperms: true })
      const redirect = parseURL(this.props.routeConfig.list.redirect)
      this.props.actions.registerRedirect(redirect)
    }

    const qs = new QueryBuilder(this.props.location.search)
    const limit = sessionStorage.getItem('limit') ? sessionStorage.getItem('limit') : getIn(params, 'limit', 20)

    let shouldUpdate = false // Update the cache when a column is added
    if (prevProps.tableconfig.column_preferences &&
      this.props.tableconfig.column_preferences.length > prevProps.tableconfig.column_preferences.length) {
      // A column was added - check to see if it contains a foreign model by getting diff
      const diff = this.props.tableconfig.column_preferences.filter(({ name: id1 }) =>
        !prevProps.tableconfig.column_preferences.some(({ name: id2 }) => id2 === id1)
      )
      const lastdiff = diff.slice(-1).pop()
      if (lastdiff.modelname || lastdiff.metafield) { shouldUpdate = true } // Foreign model column added
    }

    if (((prevProps.location.search !== this.props.location.search) || // The URL search params changed ie. clicked a column heading
      (this.props.location.search && !this.state.searched) || // A new search was performed were there was none previously
      shouldUpdate) // A foreign column was added so we need to pull that data
      && Object.entries(this.props.tableconfig).length !== 0) { // We need the table config to make the query based on required fields
      new Promise((resolve, reject) => {
        const values = {
          params: { ...qs.getAllArgs(), limit: limit ? limit : params.limit },
          modelname,
          endpoint,
          modellist: true
        }
        this.props.actions.fetchMany({ values, resolve, reject })
        const advanced = (prevProps.match.params.model !== this.props.match.params.model) ? false : this.state.advanced
        this.setState({ searched: true, error: false, advanced })
      }).catch(e => {
        if (this._is_mounted) { this.setState({ error: e }) }
      }).then(() => {
        if (this._is_mounted) { this.setState({ error: false }) }
      })
    } else if ((prevProps.match.params.model !== this.props.match.params.model && // Model type changed
      this.props.config.modelname !== 'portals') || // And the model is not portals
      (prevProps.match.params.log !== this.props.match.params.log)) { // Syndication log type changed
      this.setState({ advanced: false, error: false })
      new Promise((resolve, reject) => {
        const values = {
          params: { ...qs.getAllArgs(), limit: limit ? limit : params.limit },
          modelname,
          endpoint,
          modellist: true
        }
        this.props.actions.fetchMany({ values, resolve, reject })
      }).catch(e => {
        if (this._is_mounted) { this.setState({ error: e }) }
      }).then(() => {
        if (this._is_mounted) { this.setState({ error: false }) }
      })
    }
  }

  componentWillUnmount() {
    if (this.timer) { clearTimeout(this.timer) }
    this._is_mounted = false
  }

  findStatus() {
    if (this.props.config.statusField) {
      return this.props.config.statusField
    }
    return this.props.config.fields.find(f => f.name === 'active') ? (
      this.props.config.fields.find(f => f.name === 'active')
    ) : this.props.config.fields.find(f => f.label === 'Status')
  }

  findDomain() {
    return this.props.config.domainField
  }

  toggleAdvanced() {
    this.setState({ advanced: !this.state.advanced })
  }

  resetPage(step) { // Reset page to initial
    const hdrs = document.querySelectorAll('.orderable')
    for (const hdr of hdrs) { hdr.classList.remove('orderedby', 'reversed') }
    if (step) { hdrs[0].classList.add('orderedby') }
  }

  handleReset() { // Handles reset of the advanced search form
    this.resetPage(true)
    const search = new URLSearchParams(this.props.config.params).toString()
    this.props.history.push(`${this.props.history.location.pathname}?${search}`)
  }

  hasViewPermission() {
    const { user, config, addons } = this.props
    if (config.addons) {
      const hasaddons = hasAddons(config.addons, addons)
      return hasaddons
    } // Entire module is disabled
    const requiredPermissions = this.props.routeConfig.list.permissions
    if (user.permissions.includes('is_prop_data_user') && !config.addons) { return true }
    if (!requiredPermissions && !config.addons) { return true }
    if (hasPermission(requiredPermissions, user.permissions)) { return true } // Implicit permissions
    return false
  }

  render() {
    if (this.state.error) {
      return (
        <div id="content" className="content">
          <div className="view viewerror container-fluid">
            <div className="dataerror err">
              <svg viewBox="0 0 576 512"><path d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/></svg>
              <h3>Data unavailable.</h3>
              <span>There was an error fetching this data. Please try again later.</span><br /><br />
              <code className="error">{this.state.error.error ? this.state.error.error : this.state.error.toString()}</code>
            </div>
          </div>
        </div>
      )
    }

    // We need the model index as well as the cache data to display the list view
    if (!this.props.model || !this.props.cache[this.props.modelname]) { return null }

    if (this.props.modelname === 'email-templates' && this.props.match.params.action === 'add') {
      return (
        <ModelChoice
          {...this.props}
          advanced={this.state.advanced}
          toggleAdvanced={this.toggleAdvanced}
          handleReset={this.handleReset}
          resetPage={this.resetPage}
          findStatus={this.findStatus}
          findDomain={this.findDomain}
        />
      )
    }

    return (
      <ModelList
        {...this.props}
        advanced={this.state.advanced}
        toggleAdvanced={this.toggleAdvanced}
        handleReset={this.handleReset}
        resetPage={this.resetPage}
        findStatus={this.findStatus}
        findDomain={this.findDomain}
      />
    )
  }
}

ModelListContainer.propTypes = {
  routeConfig: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  ui: PropTypes.object,
  app: PropTypes.object,
  actions: PropTypes.object,
  site: PropTypes.object,
  className: PropTypes.string,
  model: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  selected: PropTypes.array,
  addons: PropTypes.array,
  tableconfig: PropTypes.object,
  config: PropTypes.object,
  configs: PropTypes.object,
  cache: PropTypes.object,
  modelname: PropTypes.string,
  portals: PropTypes.object,
  settings: PropTypes.bool
}


const mapStateToProps = (state, ownProps) => { // We need to send as little as possible to the component
  let modelname = ownProps.match.params.model
  const configs = CONFIGS(state)
  if (ownProps.match.params.log) { modelname += ownProps.match.params.log }

  const portals = PORTALS(state)
  const settings = SETTINGS(state)
  const user = MINUSER(state)
  const ui = UI(state)
  const preferences = PREFERENCES(state, modelname)
  const model = MODEL(state, modelname)
  const selected = SELECTED(state, modelname)
  const cache = CACHE(state)
  const addons = ADDONS(state)
  const config = CONFIG(state, modelname)

  let minconfigs = Map() // Only pass in the minimal configs required
  configs.keySeq().toArray().forEach(configname => { // Does not include defaultState
    if (configs.getIn([ configname, 'endpoint' ])) {
      minconfigs = minconfigs.set(configname, Map({
        endpoint: configs.getIn([ configname, 'endpoint' ]),
        search: configs.getIn([ configname, 'search' ])
      }))
    }
  })
  let meta_cache = Map({ settings: {} })
  meta_cache = meta_cache.set('settings', cache.get('settings'))
  return {
    model: model && model.has('index') ? model : false,
    selected,
    tableconfig: Map({ column_preferences: preferences ? preferences : Map({}) }),
    user,
    ui,
    log: ownProps.match.params.log, // Syndication logs
    config,
    configs: minconfigs, // Don't use immutable
    cache,
    meta_cache,
    modelname,
    portals,
    addons,
    settings: settings ? true : false
  }
}


const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { routeConfig, location, actions, match, id, className, history } = ownProps
  return ({ ...stateProps, routeConfig, location, actions, match, id, className, history })
}


export default connect(mapStateToProps, null, mergeProps)(withImmutablePropsToJS(ModelListContainer))
