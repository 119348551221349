export const SEND_RESET = 'SEND_RESET'
export const SEND_ACTIVATION = 'SEND_ACTIVATION'
export const ACTIVATE_USER = 'ACTIVATE_USER'
export const RESET_SUCCESS = 'RESET_SUCCESS'
export const DO_RESET = 'DO_RESET'
export const DO_LOGIN = 'DO_LOGIN'
export const UNSELECT_AGENT = 'UNSELECT_AGENT'
export const SELECT_AGENT = 'SELECT_AGENT'
export const RE_TOKE = 'RE_TOKE'
export const APPLY_RETOKE = 'APPLY_RETOKE'
export const LOAD_TOKE = 'LOAD_TOKE'
export const REMOVE_MODAL = 'REMOVE_MODAL'
export const DO_LOGOUT = 'DO_LOGOUT'
export const AUTH_FAIL = 'AUTH_FAIL'
export const TOGGLE_NAV = 'TOGGLE_NAV'
export const TOGGLE_MENU = 'TOGGLE_MENU'
export const CLOSE_NAV = 'CLOSE_NAV'
export const OPEN_NAV = 'OPEN_NAV'
export const CLOSE_MENU = 'CLOSE_MENU'
export const TOGGLE_ALERTS = 'TOGGLE_ALERTS'
export const TOGGLE_MANAGER = 'TOGGLE_MANAGER'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const CHANGE_CAPTION = 'CHANGE_CAPTION'
export const CHANGE_DATE = 'CHANGE_DATE'
export const ROTATE_IMAGE = 'ROTATE_IMAGE'
export const FETCH_ONE = 'FETCH_ONE'
export const FETCH_ACTIVITY = 'FETCH_ACTIVITY'
export const FETCH_NOTES = 'FETCH_NOTES'
export const FETCH_FILE = 'FETCH_FILE'
export const CREATE_NOTE = 'CREATE_NOTE'
export const FETCH_MANY = 'FETCH_MANY'
export const SELECT_ONE = 'SELECT_ONE'
export const SELECT_ALL = 'SELECT_ALL'
export const SELECT_NONE = 'SELECT_NONE'
export const REGISTER_MODEL = 'REGISTER_MODEL'
export const UNREGISTER_MODEL = 'UNREGISTER_MODEL'
export const ADD_TABLE_CONFIG_FIELD = 'ADD_TABLE_CONFIG_FIELD'
export const REMOVE_TABLE_CONFIG_FIELD = 'REMOVE_TABLE_CONFIG_FIELD'
export const UPDATE_TABLE_CONFIG = 'UPDATE_TABLE_CONFIG'
export const CREATE_PREFERENCE = 'CREATE_PREFERENCE'
export const UPDATE_PREFERENCE = 'UPDATE_PREFERENCE'
export const DELETE_PREFERENCE = 'DELETE_PREFERENCE'
export const BULK_EDIT_MODEL = 'BULK_EDIT_MODEL'
export const UPDATE_MODEL = 'UPDATE_MODEL'
export const CREATE_MODEL = 'CREATE_MODEL'
export const DELETE_MODEL = 'DELETE_MODEL'
export const CHECK_DELETE_MODEL = 'CHECK_DELETE_MODEL'
export const REGISTER_REDIRECT = 'REGISTER_REDIRECT'
export const UNREGISTER_REDIRECT = 'UNREGISTER_REDIRECT'
export const MOUNT_MENU = 'MOUNT_MENU'
export const UNMOUNT_MENU = 'UNMOUNT_MENU'
export const CONNECT_WEBSOCKET = 'CONNECT_WEBSOCKET'
export const DISCONNECT_WEBSOCKET = 'DISCONNECT_WEBSOCKET'
export const UNREGISTER_WEBSOCKET = 'UNREGISTER_WEBSOCKET'
export const PUSH_ALERT = 'PUSH_ALERT'
export const PUSH_MESSAGE = 'PUSH_MESSAGE'
export const EXPORT_DATA = 'EXPORT_DATA'
export const NOTIFY = 'NOTIFY'
export const DISMISS_NOTICE = 'DISMISS_NOTICE'
export const DISMISS_ALERT = 'DISMISS_ALERT'
export const FETCH_MATCHES = 'FETCH_MATCHES'
export const FETCH_HIGHLIGHTS = 'FETCH_HIGHLIGHTS'
export const FETCH_PORTAL_LOGS = 'FETCH_PORTAL_LOGS'
export const TOGGLE_SITE_PORTAL = 'TOGGLE_SITE_PORTAL'
export const CREATE_SITE_PORTAL = 'CREATE_SITE_PORTAL'
export const CREATE_PORTAL_CONFIG = 'CREATE_PORTAL_CONFIG'
export const CREATE_BRANCH_PORTAL_CONFIG = 'CREATE_BRANCH_PORTAL_CONFIG'
export const DELETE_BRANCH_PORTAL_CONFIG = 'DELETE_BRANCH_PORTAL_CONFIG'
export const UPDATE_BRANCH_PORTAL_CONFIG = 'UPDATE_BRANCH_PORTAL_CONFIG'
export const SYNDICATE_PORTAL_ITEM = 'SYNDICATE_PORTAL_ITEM'
export const SYNDICATE_ITEMS = 'SYNDICATE_ITEMS'
export const HIGHLIGHT_MATCH = 'HIGHLIGHT_MATCH'
export const UNHIGHLIGHT_MATCH = 'UNHIGHLIGHT_MATCH'
export const GENERATE_QR = 'GENERATE_QR'
export const ALERT_AGENTPROPERTYLEAD = 'ALERT_AGENTPROPERTYLEAD'
export const TOGGLE_CONTACT_CREATOR = 'TOGGLE_CONTACT_CREATOR'
export const DISMISS_CONTACT_CREATOR = 'DISMISS_CONTACT_CREATOR'
export const GENERATE_BROCHURE = 'GENERATE_BROCHURE'
export const FETCH_DASHBOARD = 'FETCH_DASHBOARD'
export const FETCH_BRANCH_MESSAGE_COMMENTS = 'FETCH_BRANCH_MESSAGE_COMMENTS'
export const FETCH_BRANCH_MESSAGES = 'FETCH_BRANCH_MESSAGES'
export const CREATE_BRANCH_MESSAGE_COMMENT = 'CREATE_BRANCH_MESSAGE_COMMENT'
export const FETCH_PROFILE_MATCHES = 'FETCH_PROFILE_MATCHES'
export const EMAIL_PROFILE_MATCHES = 'EMAIL_PROFILE_MATCHES'
export const FETCH_LOCATIONS = 'FETCH_LOCATIONS'
export const FETCH_SERVICED_ATTRIBUTES = 'FETCH_SERVICED_ATTRIBUTES'
export const FETCH_REFERRAL_CONTACT_MATCH = 'FETCH_REFERRAL_CONTACT_MATCH'
export const GET_LEADS_ANALYSIS = 'GET_LEADS_ANALYSIS'
export const GET_LEADS_BREAKDOWN = 'GET_LEADS_BREAKDOWN'
export const HIDE_LOADER = 'HIDE_LOADER'
export const TOGGLE_WIDE_SIDEBAR = 'TOGGLE_WIDE_SIDEBAR'
export const DISMISS_WIDE_SIDEBAR = 'DISMISS_WIDE_SIDEBAR'
export const FETCH_DEEDS = 'FETCH_DEEDS'
export const CHECK_VERSION = 'CHECK_VERSION'
export const FETCH_ACTIVITY_HISTORY = 'FETCH_ACTIVITY_HISTORY'
export const AUTOSAVE_FORM = 'AUTOSAVE_FORM'
export const AUTOSAVE_CHECK = 'AUTOSAVE_CHECK'
export const RECALCULATE_COMMISSION = 'RECALCULATE_COMMISSION'
export const AUTOSAVE_APPLY = 'AUTOSAVE_APPLY'
export const AUTOSAVE_DISCARD = 'AUTOSAVE_DISCARD'
export const DOWNLOAD_IMAGES = 'DOWNLOAD_IMAGES'
export const RENEW_TOKEN = 'RENEW_TOKEN'
export const FETCH_LISTING_HISTORY = 'FETCH_LISTING_HISTORY'
export const FETCH_FEED_LOGS = 'FETCH_FEED_LOGS'
export const FETCH_AGENT_STATISTICS = 'FETCH_AGENT_STATISTICS'
export const FETCH_BRANCH_STATISTICS = 'FETCH_BRANCH_STATISTICS'
export const FETCH_LISTING_ANALYSIS = 'FETCH_LISTING_ANALYSIS'
export const GET_ALERTS_BREAKDOWN = 'GET_ALERTS_BREAKDOWN'
export const CREATE_LEAD_INTERACTION = 'CREATE_LEAD_INTERACTION'
export const UPDATE_LEAD_INTERACTION = 'UPDATE_LEAD_INTERACTION'
export const FETCH_VIEWING_FEEDBACK = 'FETCH_VIEWING_FEEDBACK'
export const FETCH_TEMPLATE_CONFIG = 'FETCH_TEMPLATE_CONFIG'
export const FETCH_PAGE_TEMPLATE_CONFIG = 'FETCH_PAGE_TEMPLATE_CONFIG'
export const UPDATE_TEMPLATE_PREVIEW = 'UPDATE_TEMPLATE_PREVIEW'
export const EMAIL_TEMPLATE = 'EMAIL_TEMPLATE'
export const FETCH_P24_URLS = 'FETCH_P24_URLS'
export const FETCH_CLEVER_COMPOSE = 'FETCH_CLEVER_COMPOSE'
export const FETCH_VACANCY_PRO = 'FETCH_VACANCY_PRO'
export const NOTIFY_REFERRALREQUEST = 'NOTIFY_REFERRALREQUEST'
export const CACHE_DELTA = 'CACHE_DELTA'
export const CALCULATE_LEASE_VALUE = 'CALCULATE_LEASE_VALUE'
export const UPDATE_HOME_PAGE = 'UPDATE_HOME_PAGE'
export const FETCH_LISTING_COUNTS = 'FETCH_LISTING_COUNTS'
export const FETCH_FIELD_DEFAULT = 'FETCH_FIELD_DEFAULT'
export const FETCH_DEAL_FINANCIAL_STRUCTURE = 'FETCH_DEAL_FINANCIAL_STRUCTURE'
export const UPDATE_DEAL_FINANCIAL_STRUCTURE = 'UPDATE_DEAL_FINANCIAL_STRUCTURE'
export const FETCH_VACANCY_PRO_SUBURBS = 'FETCH_VACANCY_PRO_SUBURBS'
export const SEND_NEWSLETTER_TEST = 'SEND_NEWSLETTER_TEST'
export const MERGE_MODEL = 'MERGE_MODEL'
export const FETCH_CREDIT_CHECK = 'FETCH_CREDIT_CHECK'
export const FETCH_CREDIT_CHECK_MODULES = 'FETCH_CREDIT_CHECK_MODULES'
export const LOGIN_CREDIT_CHECK = 'LOGIN_CREDIT_CHECK'
export const RESEND_APPLICATION = 'RESEND_APPLICATION'
export const COPY_TO_DISPLAY_ON_BRANCHES = 'COPY_TO_DISPLAY_ON_BRANCHES'
export const SEND_MARKETING_EMAIL = 'SEND_MARKETING_EMAIL'
export const VALIDATE_SENDGRID_DOMAINS = 'VALIDATE_SENDGRID_DOMAINS'
