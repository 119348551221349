/* eslint-disable no-process-env */
/* eslint-disable new-cap */
import { shouldPolyfill } from '@formatjs/intl-datetimeformat/should-polyfill'
import '@formatjs/intl-numberformat/polyfill-force'
import classNames from 'classnames'
import merge from 'deepmerge'
import { getIn, setIn as formikSetIn } from 'formik'
import { fromJS, is, Iterable, List, Map as ImmutableMap, OrderedSet, isCollection } from 'immutable'
import { add, differenceInCalendarDays, endOfMonth, endOfTomorrow, endOfWeek, endOfYear, endOfYesterday, startOfDay, startOfMonth, startOfTomorrow, startOfWeek, startOfYear, startOfYesterday, sub } from 'date-fns'
import invert from 'invert-color'
import template from 'lodash/template'
import mixpanel from 'mixpanel-browser'
import Papa from 'papaparse'
import isEqual from 'react-fast-compare'
import React, { useRef } from 'react'
import 'whatwg-fetch'

import ListingPopup from './components/common/tooltips/ListingPopup'
import QueryBuilder from './components/common/QueryBuilder'
import WhatsAppButton from './components/common/WhatsAppButton'
import { Button } from './components/ui/Button'
import db from './db'
import './locale-data/en-ZA.js' // default formatting options
import log from './logging'
import { history } from './store'
import ContactPopup from './components/common/tooltips/ContactPopup'


export const breakpoint = window.matchMedia('(min-width: 800px)')

export const objectFieldList = [
  'profile_photo',
  'listing_popup',
  'contact_popup',
  'image',
  'image_url',
  'date',
  'day',
  'daymonth',
  'week',
  'month',
  'monthyear',
  'shortmonthyear',
  'shortday',
  'shortdatetime',
  'shortdate',
  'datetime',
  'time',
  'stage',
  'criteria',
  'stage-expanded',
  'contact_whatsapp_link',
  'whatsapp_link_only',
  'tagged',
  'feedlink',
  'int',
  'link',
  'number',
  'address'
]

async function polyfill(locale) {
  if (shouldPolyfill()) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-datetimeformat/polyfill')
  }

  if (Intl.DateTimeFormat.polyfilled) {
    // Parallelize CLDR data loading
    const dataPolyfills = [ import('@formatjs/intl-datetimeformat/add-all-tz') ]

    switch (locale) {
      default:
        dataPolyfills.push(
          import('@formatjs/intl-datetimeformat/locale-data/en-ZA')
        )
        break
      case 'fr':
        dataPolyfills.push(
          import('@formatjs/intl-datetimeformat/locale-data/fr')
        )
        break
    }
    await Promise.all(dataPolyfills)
  }
}
polyfill()

export const capitalize = s => {
  if (typeof s !== 'string') { return '' }
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const title = string => {
  if (typeof string !== 'string') { return '' }
  return string.split(' ').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')
}

export const slugify = text => {
  if (!text) { return '' }
  if (typeof text !== 'string') { text = text.toString() }
  return text.toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

export const buildLambdaURI = (image, parms) => {
  try {
    const re = /(.*)\.(\w{3,4})($|\?)/
    const prefix = image.match(re)[1]
    const extension = image.match(re)[2]
    if (extension === 'svg') {
      return image
    }
    const { cx, cy, cw, ch } = parms
    const crop = [ cx, cy, cw, ch ].some(k => k)
    if (crop) { // Cropping
      return `${prefix}_t_c_cx_${parms.cx}_cy_${parms.cy}_cw_${parms.cw}_ch_${parms.ch}_w_${parms.w}_h_${parms.h}.${extension}`
    }
    return `${prefix}_t_w_${parms.w}_h_${parms.h}.${extension}`
  } catch (e) {
    return image
  }
}

export function humanize(num) {
  if (num === undefined) {
    return
  }

  if (num % 100 >= 11 && num % 100 <= 13) {
    // eslint-disable-next-line consistent-return
    return `${num}th`
  }

  switch (num % 10) {
    // eslint-disable-next-line consistent-return
    case 1: return `${num}st`
    // eslint-disable-next-line consistent-return
    case 2: return `${num}nd`
    // eslint-disable-next-line consistent-return
    case 3: return `${num}rd`
    // eslint-disable-next-line consistent-return
    default: return `${num}th`
  }
}


/*
* Format all file sizes to system default
*/
export const normaliseFileSize = (bytes, si = false) => { // Default to a base of 1024
  const thresh = si ? 1000 : 1024
  if (Math.abs(bytes) < thresh) { return `${bytes} B` }
  const units = si
    ? [ 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ]
    : [ 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB' ]
  let u = -1
  do {
    bytes /= thresh
    ++u
  } while (Math.abs(bytes) >= thresh && u < units.length - 1)
  return `${bytes.toFixed(1)} ${units[u]}`
}

export function request(url, options) {
  const j = { status: 0, body: '' }
  let timeout = 60000
  if (options && options.method === 'POST') { timeout = 300000 }
  const p = Promise.race([
    fetch(url, options),
    new Promise((resolve, reject) => {
      setTimeout(() => reject(new Error('No response. Check your internet connection.')), timeout) // 60 second timeout on requests
    })
  ])
  const r = p.then(response => {
    if (response.status >= 200 && response.status < 300) { return response }
    j.status = response.status
    j.ok = response.ok
    j.error = response.statusText
    j.token = response.headers.get('token')
    j.token = j.token ? j.token : null
    throw response
  }).then(response => { // Headers are received first
    j.token = response.headers.get('token')
    j.token = j.token ? j.token : null
    j.status = response.status
    j.ok = response.ok
    j.headers = response.headers
    if (response.headers.get('content-type') === 'application/zip') {
      return response.arrayBuffer()
    }
    return response.text()
  }).then(text => { // Then the response body
    j.body = text
    return j
  }).catch(e => {
    try {
      if (e.message) { // Timeout
        j.status = 408
        j.ok = false
        j.error = e.message
      } else {
        return e.text().then(errorMessage => { // Process error body
          try {
            const response = JSON.parse(errorMessage)
            j.ok = false
            if (response.detail) {
              j.error = response.detail
            }
            if (response.non_field_errors) {
              j.error = response.non_field_errors[0]
            }
            j.raw = response
          } catch (er) {
            j.error = e.statusText
            j.ok = false
          }
          return j
        })
      }
      return j
    } catch (f) {
      return j
    }
  })
  return r
}
/* XMLHttpRequest with progress */
export function upload(url, opts = {}, onProgress) {
  const j = { status: 0, body: '', ok: true, error: '', token: null }
  const p = Promise.race([
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.open(opts.method || 'get', url, true) // method, url, async
      for (const k in opts.headers || {}) { if (opts.headers[k]) { xhr.setRequestHeader(k, opts.headers[k]) } }
      xhr.onload = e => resolve({
        text: e.target?.responseText,
        token: xhr.getResponseHeader('Token'),
        status: xhr.status,
        ok: true,
        statusText: xhr.statusText
      })
      xhr.onerror = reject
      xhr.upload.onprogress = onProgress
      xhr.send(opts.body)
    })
  ])
  const r = p.then(response => {
    if (response.status >= 200 && response.status < 300) { return response }
    j.status = response.status
    j.ok = false
    j.body = response.text
    j.error = response.statusText
    j.token = response.token ? response.token : null
    throw j // Goes straight to catch below
  }).then(response => {
    j.token = response.token ? response.token : null
    j.status = response.status
    j.ok = true
    j.body = response.text ? JSON.parse(response.text) : ''
    return j
  }).catch(() => j)
  return r
}

// The below merges two states while overwriting lists instead of concating them.
const isList = List.isList
export function merger(a, b) {
  if (a && a.mergeWith && !isList(a) && !isList(b) && isCollection(a) && isCollection(b)) {
    return a.mergeWith(merger, b)
  }
  return b
}

export function mergeLists (current, v, key) {
  if (List.isList(v)) {
    if (List.isList(current) && !current.isEmpty()) {
      const updated_merged = current.map(c => { // Merge existing items
        const replace = v.find(o => is(o.get(key), c.get(key)))
        if (replace) {
          return c.merge(replace)
        }
        return c
      })
      const updated_concat = v.filter(c => !updated_merged.find(f => is(f.get(key), c.get(key)))) // Append new items
      return updated_merged.merge(updated_concat)
    }
  }
  return v
}

export function mergeDeepArrays (state, value, key, skip = false) {
  const mergeArrays = (data, basePath) => {
    let next = data
    data.forEach((v, k) => {
      const path = basePath.concat([ k ])
      if (List.isList(v)) {
        if (state.hasIn(path)) {
          const current = state.getIn(path)
          if (List.isList(current) && !current.isEmpty()) {
            const updated_merged = current.map(c => { // Merge existing items
              if (key && ImmutableMap.isMap(c)) {
                const replace = v.filter(o => is(o.get(key), c.get(key)))
                if (replace.size === 1) { // found 1 match
                  return c.merge(replace.getIn([ '0' ]))
                } else if (replace.size > 1) { // found multiple matches - check labels too
                  const multiple = v.find(o => is(o.get(key), c.get(key)) && is(o.get('label'), c.get('label')))
                  return c.merge(multiple)
                }
              }
              return c
            })
            const updated_concat = v
              .filter(c => key && ImmutableMap.isMap(c) && !updated_merged.find(f => is(f.get(key), c.get(key)))) // Append new items
            if (List.isList(updated_merged)) {
              state = state.setIn(path, OrderedSet(updated_merged.concat(updated_concat.filter(item =>
                updated_merged.indexOf(item) < 0
              ))))
              next = next.deleteIn(path)
            }
          }
        }
      } else if (ImmutableMap.isMap(v)) {
        next = next.setIn(path, mergeArrays(v, []))
      }
    })

    return next
  }
  const updatedData = mergeArrays(fromJS(value), [])
  function list_merger(a, b) {
    if (a && a.mergeWith && !isList(a) && !isList(b)) {
      return a.mergeWith(list_merger, b)
    }
    if (isList(a) && isList(b)) {
      return b.concat(a.filter(item => b.indexOf(item) < 0))
    }
    return b
  }
  // Use only the override if there is one
  if (skip && updatedData.size) { return updatedData }
  return list_merger(state, updatedData)
}

export const getConfig = async (modelname, region) => {
  let config
  let config_file
  switch (modelname) {
    case 'assetcategories':
      config_file = 'assetcategory.json'
      break
    case 'assets':
      config_file = 'asset.json'
      break
    case 'franchises':
      config_file = 'franchise.json'
      break
    case 'branches':
      config_file = 'branch.json'
      break
    case 'agents':
      config_file = 'agent.json'
      break
    case 'vehicles':
      config_file = 'vehicle.json'
      break
    case 'agentnotifications':
      config_file = 'agentnotification.json'
      break
    case 'activity':
      config_file = 'activity.json'
      break
    case 'teams':
      config_file = 'team.json'
      break
    case 'groups':
      config_file = 'group.json'
      break
    case 'designations':
      config_file = 'designation.json'
      break
    case 'races':
      config_file = 'race.json'
      break
    case 'residential':
      config_file = 'residential.json'
      break
    case 'commercial':
      config_file = 'commercial.json'
      break
    case 'projects':
      config_file = 'project.json'
      break
    case 'holiday':
      config_file = 'holiday.json'
      break
    case 'contacts':
      config_file = 'contact.json'
      break
    case 'contactconsent':
      config_file = 'contactconsent.json'
      break
    case 'images':
      config_file = 'image.json'
      break
    case 'documents':
      config_file = 'document.json'
      break
    case 'tags':
      config_file = 'tag.json'
      break
    case 'snippets':
      config_file = 'snippet.json'
      break
    case 'documentcategories':
      config_file = 'documentcategory.json'
      break
    case 'leads':
      config_file = 'lead.json'
      break
    case 'subscribers':
      config_file = 'subscriber.json'
      break
    case 'profiles':
      config_file = 'profile.json'
      break
    case 'modules':
      config_file = 'module.json'
      break
    case 'page-templates':
      config_file = 'pagetemplate.json'
      break
    case 'articles':
      config_file = 'article.json'
      break
    case 'cms':
      config_file = 'cms.json'
      break
    case 'navigation':
      config_file = 'navigation.json'
      break
    case 'location-profiles':
      config_file = 'locationprofile.json'
      break
    case 'pages':
      config_file = 'page.json'
      break
    case 'themes':
      config_file = 'theme.json'
      break
    case 'theme-settings':
      config_file = 'theme-settings.json'
      break
    case 'agent-schedule':
      config_file = 'agent-schedule.json'
      break
    case 'agent-availability':
      config_file = 'agent-availability.json'
      break
    case 'sitevariables':
      config_file = 'sitevariable.json'
      break
    case 'regions':
      config_file = 'region.json'
      break
    case 'divisions':
      config_file = 'division.json'
      break
    case 'locations':
      config_file = 'location.json'
      break
    case 'provinces':
      config_file = 'province.json'
      break
    case 'countries':
      config_file = 'country.json'
      break
    case 'areas':
      config_file = 'area.json'
      break
    case 'categories':
      config_file = 'category.json'
      break
    case 'settings':
      config_file = 'settings.json'
      break
    case 'portals':
      config_file = 'portal.json'
      break
    case 'globalportals':
      config_file = 'globalportals.json'
      break
    case 'deeds':
      config_file = 'deed.json'
      break
    case 'globaldeeds':
      config_file = 'globaldeeds.json'
      break
    case 'syndicationresidential':
      config_file = 'syndication/residential.json'
      break
    case 'syndicationcommercial':
      config_file = 'syndication/commercial.json'
      break
    case 'syndicationholiday':
      config_file = 'syndication/holiday.json'
      break
    case 'syndicationbranches':
      config_file = 'syndication/branch.json'
      break
    case 'syndicationagents':
      config_file = 'syndication/agent.json'
      break
    case 'contacttypes':
      config_file = 'contacttypes.json'
      break
    case 'templates':
      config_file = 'template.json'
      break
    case 'templatecategories':
      config_file = 'templatecategory.json'
      break
    case 'alerts':
      config_file = 'alert.json'
      break
    case 'lightstone':
      config_file = 'deeds-lookups/lightstone.json'
      break
    case 'portalconfig':
      config_file = 'portalconfig.json'
      break
    case 'notes':
      config_file = 'note.json'
      break
    case 'routes':
      config_file = 'route.json'
      break
    case 'dashboard':
      config_file = 'dashboard.json'
      break
    case 'referrals':
      config_file = 'referral.json'
      break
    case 'offers':
      config_file = 'offer.json'
      break
    case 'seller-feedback':
      config_file = 'seller-feedback.json'
      break
    case 'valuations':
      config_file = 'valuation.json'
      break
    case 'servicedattribute':
      config_file = 'servicedattribute.json'
      break
    case 'canvassing':
      config_file = 'canvassing.json'
      break
    case 'lightstonesuburb':
      config_file = 'lightstonesuburb.json'
      break
    case 'domains':
      config_file = 'domain.json'
      break
    case 'deals':
      config_file = 'deal.json'
      break
    case 'newsletters':
      config_file = 'newsletter.json'
      break
    case 'newsletter-templates':
      config_file = 'newsletter-template.json'
      break
    case 'brand-assets':
      config_file = 'brand-asset.json'
      break
    case 'brand-recipients':
      config_file = 'brand-recipient.json'
      break
    case 'brand-templates':
      config_file = 'brand-template.json'
      break
    case 'marketing-emails':
      config_file = 'marketing-email.json'
      break
    case 'email-templates':
      config_file = 'email-template.json'
      break
    case 'email-recipients':
      config_file = 'email-recipient.json'
      break
    case 'campaigns':
      config_file = 'campaign.json'
      break
    case 'insights':
      config_file = 'insight.json'
      break
    case 'area-groups':
      config_file = 'area-group.json'
      break
    case 'applications':
      config_file = 'application.json'
      break
    case 'lease-applications':
      config_file = 'lease-application.json'
      break
    case 'pets':
      config_file = 'pet.json'
      break
    case 'credit-checks':
      config_file = 'credit-check.json'
      break
    case 'integrations':
      config_file = 'integration.json'
      break
    case 'redirects':
      config_file = 'redirect.json'
      break
    case 'lightstonetown':
      config_file = 'lightstonetown.json'
      break
    case 'reports':
      config_file = 'report.json'
      break
    default:
      config_file = null
      break
  }
  if (!config_file) {
    return null
  }

  const reviver = (key, value) =>
    (Iterable.isKeyed(value) ? value.toOrderedMap() : value.toList())

  config = await require(`./config/${config_file}`)
  if (config.config) {
    config = config.config
  }
  config = fromJS(config, reviver)
  const override_file = [ region, config_file ].join('/')
  let overrides = ImmutableMap()
  try {
    overrides = (region && region !== 'default') ? await require(`./config/regions/${override_file}`) : {}
    if (overrides.config) {
      overrides = overrides.config
    }
    overrides = fromJS(overrides, reviver)
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(e)
    }
  }
  try {
    return mergeDeepArrays(config, overrides, 'name', config_file === 'route.json')
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(e)
    }
    return overrides
  }
}

export const getAllConfigs = async region => {
  const configs = {}
  const modelnames = [
    'activity',
    'agentnotifications',
    'agents',
    'agent-schedule',
    'agent-availability',
    'alerts',
    'areas',
    'articles',
    'assetcategories',
    'assets',
    'branches',
    'brand-assets',
    'brand-recipients',
    'brand-templates',
    'canvassing',
    'campaigns',
    'categories',
    'cms',
    'commercial',
    'contactconsent',
    'contacts',
    'contacttypes',
    'countries',
    'dashboard',
    'deals',
    'deeds',
    'designations',
    'divisions',
    'documentcategories',
    'documents',
    'domains',
    'franchises',
    'globaldeeds',
    'globalportals',
    'groups',
    'holiday',
    'images',
    'leads',
    'lightstone',
    'lightstonesuburb',
    'lightstonetown',
    'location-profiles',
    'locations',
    'modules',
    'navigation',
    'notes',
    'offers',
    'pages',
    'page-templates',
    'portalconfig',
    'portals',
    'profiles',
    'projects',
    'provinces',
    'vehicles',
    'races',
    'referrals',
    'regions',
    'reports',
    'residential',
    'routes',
    'seller-feedback',
    'servicedattribute',
    'settings',
    'sitevariables',
    'snippets',
    'subscribers',
    'syndicationagents',
    'syndicationbranches',
    'syndicationcommercial',
    'syndicationholiday',
    'syndicationresidential',
    'tags',
    'teams',
    'templates',
    'templatecategories',
    'themes',
    'theme-settings',
    'valuations',
    'newsletters',
    'newsletter-templates',
    'marketing-emails',
    'email-templates',
    'email-recipients',
    'insights',
    'area-groups',
    'applications',
    'lease-applications',
    'pets',
    'credit-checks',
    'integrations',
    'redirects'
  ]
  for (const modelname of modelnames) {
    configs[modelname] = await getConfig(modelname, region)
  }
  return configs
}

export const checkImageSize = (image, minwidth = 1024, minheight = 768) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.onload = () => {
    const i = new Image()
    i.onload = () => {
      if (i.height < minheight || i.width < minwidth) {
        reject('Too small')
      } else {
        resolve('Image ok')
      }
    }
    i.src = reader.result
  }
  reader.readAsDataURL(image)
})

/*
* Update URLs with correct data
*/
export const normaliseURI = (uri, w = false) => {
  // Add in AWS resizing lamba
  if (w && uri) {
    uri = uri.replace(/(.*)(.jpg|.png)$/gi, `$1_t_w_${w}_h_${(w * 1.5)}$2`)
  }
  // Remove Prop Data code fart
  const re = new RegExp('^http://https://')
  if (re.test(uri)) {
    return uri.replace(/(^\w+:|^)\/\//, '')
  }
  return uri
}

export const getNodes = str => new DOMParser().parseFromString(str, 'text/html').body.childNodes

export const createJSX = (nodeArray, level = 0, includeType = false, replaceLineBreaks = true) => (
  nodeArray.map((node, idx) => {
    const attributeObj = {}
    const {
      attributes,
      localName,
      childNodes,
      nodeValue,
      nodeType
    } = node
    if (includeType) {
      attributeObj.nodeType = nodeType
    }
    if (nodeType === 3) {
      if (replaceLineBreaks) {
        return nodeValue.split('\n').map((line, i) => (
          <React.Fragment key={i}>
            {line}
            <br />
          </React.Fragment>
        ))
      }
      return nodeValue
    }

    if (nodeType === 8) {
      attributeObj.style = {}
      attributeObj.style.display = 'none'
      return React.createElement(
        'span',
        attributeObj,
        [ nodeValue ]
      )
    }

    if (attributes) {
      Array.from(attributes).forEach(attribute => {
        if (attribute.name === 'style') {
          const styleAttributes = attribute.nodeValue.split(';')
          const styleObj = {}
          styleAttributes.forEach(attr => {
            const [ key, value ] = attr.split(':')
            if (key.indexOf('-') !== -1) {
              const parts = key.split('-')
              const start = parts[0]
              const other = parts.slice(1, parts.length).map(p => capitalize(p)).join('')

              styleObj[`${start}${other}`] = value
            } else {
              styleObj[key] = value
            }
          })
          attributeObj[attribute.name] = styleObj
        } else if (attribute.name.indexOf('-') !== -1) {
          const parts = attribute.name.split('-')
          const start = parts[0]
          const other = parts.slice(1, parts.length).map(p => capitalize(p)).join('')
          attributeObj[`${start}${other}`] = attribute.nodeValue
        } else {
          attributeObj[attribute.name] = attribute.nodeValue
        }
      })
      attributeObj.key = `${localName || 'node'}-${level}-${idx}`
    }
    const hasChildren = childNodes && Array.isArray(Array.from(childNodes)) && Array.from(childNodes).length
    attributeObj.className = attributeObj.class
    if (attributeObj.class) {
      delete attributeObj.class
    }
    if (localName) {
      return React.createElement(
        localName,
        attributeObj,
        hasChildren ? createJSX(Array.from(childNodes), level + 1, includeType, replaceLineBreaks) : null
      )
    }

    /* else if (nodeValue.indexOf('\n') !== -1 && !hasChildren) {
    return createJSX(Array.from(getNodes(nodeValue.replace('\n', '<br />'))))
  } */
    return nodeValue
  })
)

export const logEvent = (event, props) => {
  log.info(event, props)
  if ([ 'production' ].indexOf(process.env.NODE_ENV) !== -1 &&
    process.env.JEST_WORKER_ID === undefined
  ) { // Only log production events
    if (process.env.REACT_APP_ENV !== 'staging') {
      mixpanel.init('14203741ba5030f2b72a64a27674c720') // Production
    } else {
      mixpanel.init('433ad00fc7e6727d44db9bcfc5f7b3cc') // Staging
    }
    if (event === 'LOGIN_SUCCESS') {
      mixpanel.identify(props.id)
      mixpanel.people.set('User', props.email)
      mixpanel.people.increment('Logins')
    }
    if (event === 'SELECT_AGENT_SUCCESS') {
      mixpanel.people.set('Email', props.agent_email)
      mixpanel.people.set('$email', props.agent_email)
      mixpanel.people.set('Site', props.site_id)
      mixpanel.people.set('Domain', props.domain)
      mixpanel.people.set('Name', props.agent_name)
      mixpanel.people.set('$name', props.agent_name)
      mixpanel.people.set('$avatar', props.agent_avatar)
      if (!props.is_prop_data_user) {
        mixpanel.add_group('Company', props.domain)
      }
    }
    mixpanel.people.increment('Events')
    mixpanel.track(event, props)
  }
}

export const generateAddress = (model, cache, full = true) => {
  const complex = [ model.unit_number, model.complex_name ].filter(p => p).join(' ')
  const street = [ model.street_number, model.street_name ].filter(p => p).join(' ')
  const building = model.building_name
  if (!full) { return [ complex, building, street ].filter(l => l && l.trim()).join(', ') }
  const location = (model.location && model.meta?.location) ? [ model.meta.location.suburb, model.meta.location.province, model.meta.location.country ].join(', ') : ''

  if (typeof full === 'object') {
    const parts_list = full.parts.map(part => {
      switch (part) {
        case 'complex':
          return complex
        case 'street':
          return street
        case 'building':
          return building
        case 'location':
          return location
        default:
          return getIn(model, part)
      }
    }).filter(p => p)
    return parts_list.join(', ')
  }
  return [ complex, building, street, location ].filter(l => l && l.trim()).join(', ')
}

export const nFormatter = (num, digits) => {
  const si = [
    { value: 1, symbol: '' },
    { value: 1E3, symbol: 'k' },
    { value: 1E6, symbol: 'M' },
    { value: 1E9, symbol: 'B' },
    { value: 1E12, symbol: 'T' },
    { value: 1E15, symbol: 'P' },
    { value: 1E18, symbol: 'E' }
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  let i
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) { break }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol
}

export const formatFeedLink = (ref, portal, location, listing_type) => {
  if ([ 'gumtree', 'iol-property', 'myproperty' ].includes(portal)) {
    let domain = 'd2dxvxt6nwp56w.cloudfront.net'
    let portal_name = portal
    if ([ 'production' ].indexOf(process.env.NODE_ENV) !== -1) {
      domain = 'd21tw07c6rnmp0.cloudfront.net'
    }
    let filename = `${portal}.xml`
    if (portal === 'iol-property') {
      filename = 'gravity.txt'
      portal_name = 'gravity'
    }
    const link = `https://${domain}/media/uploads/${ref.site}/syndication/${portal_name}/${filename}`
    return link
  }
  let link = false
  if (!ref) { return link }
  switch (portal) {
    case 'property24': {
      const slugged_type = slugify(listing_type)
      const p24_type = [ 'to-let', 'holiday-letting' ].includes(slugged_type) ? 'to-rent' : slugged_type // for-sale is for-sale
      let domain = 'www.exdev.property24-test.com'
      link = `https://${domain}/${p24_type}/search?SearchText=${ref}`
      if (process.env.REACT_APP_ENV === 'production') {
        if (!location) { return null }
        switch (location.country) {
          case 'Nigeria':
            domain = 'www.property24.com.ng'
            link = `https://${domain}/Search/ListingNumber/?ListingNumber=${ref}`
            break
          case 'Kenya':
            domain = 'www.property24.co.ke'
            link = `https://${domain}/${p24_type}/search?SearchText=${ref}`
            break
          case 'Zimbabwe':
            domain = 'www.property24.co.zw'
            link = `https://${domain}/Search/ListingNumber/?ListingNumber=${ref}`
            break
          case 'Mozambique':
            domain = 'www.property24.co.mz'
            link = `https://${domain}/Search/ListingNumber/?ListingNumber=${ref}`
            break
          case 'Zambia':
            domain = 'www.property24.co.zm'
            link = `https://${domain}/Search/ListingNumber/?ListingNumber=${ref}`
            break
          case 'Republic Of Mauritius':
            domain = 'www.property24.co.mu'
            link = `https://${domain}/Search/ListingNumber/?ListingNumber=${ref}`
            break
          case 'Botswana':
            domain = 'www.property24.co.bw'
            link = `https://${domain}/Search/ListingNumber/?ListingNumber=${ref}`
            break
          case 'Namibia':
            domain = 'www.property24.co.na'
            link = `https://${domain}/Search/ListingNumber/?ListingNumber=${ref}`
            break
          default:
            domain = 'www.property24.com'
            link = `https://${domain}/${p24_type}/search?SearchText=${ref}`
            break
        }
      }
      return link
    }
    case 'private-property':
      link = `http://www.privateproperty.co.za/${location.suburb_slug}-${ref}.htm`
      return link
    case 'hamptons-international':
      link = `https://www.hamptons-international.com/properties/${ref}/sales `
      return link
    case 'hubspot':
      link = `https://app.hubspot.com/contacts/${ref}/objects/0-1/views/all/list?query=${location}`
      return link
    case 'facebook':
      link = `https://www.facebook.com/${ref}`
      return link
    case 'rightmove':
      link = `https://www.rightmove.com/properties/${ref}`
      return link
    default:
      return link
  }
}

/*
* Formating helper
*/
export const valueFormat = (format, string, col = {}) => {
  if (!format) { return string }
  if (
    (
      (typeof string === 'object' &&
      !objectFieldList.includes(format)
      ) || (typeof string === 'undefined' && format !== 'number')
    ) && !Array.isArray(string)
  ) { return string }
  if (
    (string === null || string === '') &&
      format !== 'yesno' &&
      format !== 'yesnoicon' &&
      format !== 'int' &&
      format !== 'number'
  ) { return string }
  let newstring = string
  let decimals = 0
  let numbers
  const url_re = new RegExp('^(?:f|ht)tps?://')
  try {
    switch (format) {
      case 'percent':
        try {
          decimals = (string.toString().indexOf('.') !== -1 && string.toString().indexOf('.00') === -1) ? 2 : 0
          const numberString = new Intl.NumberFormat('en-ZA', {
            style: 'decimal',
            minimumFractionDigits: decimals
          }).formatToParts(string.toString()).map(({ type, value }) => {
            switch (type) {
              case 'decimal': return '.'
              default: return value
            }
          }).reduce((str, part) => str + part)
          if (isNaN(numberString)) {
            return '0%'
          }
          return `${numberString}%`
        } catch (e) {
          log.error(e)
        }
        return `${string}%`
      case 'percent_change': {
        decimals = (string.toString().indexOf('.') !== -1 && string.toString().indexOf('.00') === -1) ? 2 : 0
        const numberString = new Intl.NumberFormat('en-ZA', {
          style: 'decimal',
          minimumFractionDigits: decimals
        }).formatToParts(string.toString()).map(({ type, value }) => {
          switch (type) {
            case 'decimal': return '.'
            default: return value
          }
        }).reduce((str, part) => str + part)
        if (col.negative) {
          return <div className={`percentage-change ${!col.reverse ? 'failed' : 'success'}`}><svg viewBox="0 0 32 32"><use href="/images/glyphs.svg#glyph-TriangleDown" /></svg>{numberString}%</div>
        }
        return <div className={`percentage-change ${!col.reverse ? 'success' : 'failed'}`}><svg viewBox="0 0 32 32"><use href="/images/glyphs.svg#glyph-TriangleUp" /></svg>{numberString}%</div>
      }
      case 'feedurl': {
        const link = formatFeedLink(string, col.portal, col.location, col.listing_type)
        return link ? link : ''
      }
      case 'feedlink':
        if (string) {
          if (typeof string === 'object') {
            col.portal = getIn(string, 'meta.portal.slug')
            if ([ 'gumtree', 'iol-property', 'myproperty' ].includes(col.portal)) {
              col.reference = string
              string = 'View File'
            } else {
              return ''
            }
          }
          const link = formatFeedLink(col.reference, col.portal, col.location, col.listing_type)
          newstring = link ? <a className='has-link' href={link} target='_blank' rel="noreferrer">{string}</a> : ''
        } else {
          newstring = ''
        }
        return newstring
      case 'tagged':
        if (Array.isArray(string)) {
          newstring = string.map((tag, tid) => <div key={`tag-${tid}`} className="tags-selected"><div className="tag-label">{tag.label}</div> <div className={`tag-tags ${tag.level}`}>{tag.level}</div></div>)
          return newstring
        }
        return string
      case 'feedstatus':
        switch (string) {
          case 'pending': {
            return <svg className="pending" viewBox="0 0 32 32"><use href="/images/icons-16.svg#icon16-Clock" /></svg>
          }
          case 'failed': {
            return <svg className="failed" viewBox="0 0 32 32"><use href="/images/icons-16.svg#icon16-X-Large" /></svg>
          }
          case 'disabled': {
            return <svg className="failed" viewBox="0 0 32 32"><use href="/images/icons-16.svg#icon16-Cog" /></svg>
          }
          case 'success': {
            if (col.pconfig) {
              const link = formatFeedLink(
                col.pconfig.reference, col.pconfig.meta.portal.slug, col.location, col.listing_type
              )
              return link ? (
                <a className='has-link' href={link} target="_blank" rel="noreferrer">
                  <svg className="success" viewBox="0 0 32 32"><use href="/images/icons-16.svg#icon16-Check" /></svg>
                </a>
              ) : (
                <svg className="success" viewBox="0 0 32 32"><use href="/images/icons-16.svg#icon16-Check" /></svg>
              )
            }
            return <svg className="success" viewBox="0 0 32 32"><use href="/images/icons-16.svg#icon16-Check" /></svg>
          }
          default: { // withdrawn
            return <svg className="withdrawn" viewBox="0 0 32 32"><use href="/images/icons-16.svg#icon16-Archive" /></svg>
          }
        }
      case 'currency':
        string = String(string)
        try {
          decimals = (string.toString().indexOf('.') !== -1 && string.toString().indexOf('.00') === -1) ? 2 : 0
          const numberString = new Intl.NumberFormat('en-ZA', {
            style: 'currency',
            currency: col.currency,
            minimumFractionDigits: decimals
          }).formatToParts(string).map(({ type, value }) => {
            switch (type) {
              case 'decimal': return '.'
              default: return value
            }
          }).reduce((str, part) => str + part)
          return numberString || 0
        } catch (e) {
          log.error(e)
        }
        return string
      case 'currencyabbr':
        newstring = String(string)
        try {
          if (col.currency) {
            const currency = new Intl.NumberFormat('en-ZA', {
              style: 'currency',
              currency: col.currency,
              notation: 'compact',
              minimumFractionDigits: decimals,
              maximumFractionDigits: 1
            }).format(newstring)
            return currency || 0
          }
          return newstring || 0
        } catch (e) {
          log.error(e)
        }
        return string
      case 'price_range':
        if (newstring) {
          if (!Array.isArray(newstring)) {
            newstring = newstring.trim().split(' ')
          }
          if (Array.isArray(newstring) && newstring.length > 1) {
            newstring = newstring.map(price => {
              if (!price || [ '0', 'null' ].includes(price)) { return 'Any' }
              if (price.toString().indexOf('.') !== -1 && price.toString().indexOf('.00') === -1) {
                decimals = 2
              }
              return new Intl.NumberFormat('en-ZA', {
                style: 'currency',
                currency: col.currency,
                minimumFractionDigits: decimals
              }).formatToParts(price).map(({ type, value }) => {
                switch (type) {
                  case 'decimal': return '.'
                  default: return value
                }
              }).reduce((str, part) => str + part)
            }).join(' - ')
            if (newstring === 'Any - Any') {
              newstring = 'Any'
            }
          }
        } else {
          newstring = 'Any'
        }
        return newstring
      case 'size_range':
        if (newstring) {
          if (!Array.isArray(newstring)) {
            numbers = newstring.trim().split(' ')
          } else {
            numbers = newstring
          }
          if (Array.isArray(numbers) && numbers.length > 1) {
            return numbers.map(num => {
              if (isNaN(num)) { return num }
              let val = new Intl.NumberFormat('en-ZA', { minimumFractionDigits: 0 }).format(num)
              if (!val || [ '0', 'null' ].includes(val)) { return 'Any' }
              switch (col.measurement_type) {
                case 'Hectares':
                  val = `${val}Ha`
                  break
                case 'Square Feet':
                  val = `${val}ft${String.fromCodePoint(178)}`
                  break
                case 'Acres':
                  val = `${val}Acre`
                  break
                default:
                  val = `${val}m${String.fromCodePoint(178)}`
                  break
              }
              return val
            }).join(' - ')
          }
        } else {
          return 'Any'
        }
        return newstring
      case 'range':
        numbers = newstring.trim().split(' ')
        if (Array.isArray(numbers) && numbers.length > 1) {
          return numbers.map(num => {
            if (isNaN(num)) { return num }
            return new Intl.NumberFormat('en-ZA', { minimumFractionDigits: 0 }).format(num)
          }).join(' - ')
        }
        return newstring
      case 'date':
        try {
          const datestring = new Date(newstring)
          return new Intl.DateTimeFormat('en-ZA', { year: 'numeric', month: 'short', day: '2-digit' }).format(datestring)
        } catch (e) {
          log.error(e)
        }
        return newstring
      case 'day':
        try {
          const datestring = new Date(newstring)
          return new Intl.DateTimeFormat('en-ZA', { day: '2-digit' }).format(datestring)
        } catch (e) {
          log.error(e)
        }
        return newstring
      case 'daymonth':
        try {
          const datestring = new Date(newstring)
          return new Intl.DateTimeFormat('en-ZA', { day: '2-digit', month: 'short' }).format(datestring)
        } catch (e) {
          log.error(e)
        }
        return newstring
      case 'week':
        try {
          const datestring = new Date(newstring)
          const d = new Date(Date.UTC(datestring.getFullYear(), datestring.getMonth(), datestring.getDate()))
          const dayNum = d.getUTCDay() || 7
          d.setUTCDate(d.getUTCDate() + 4 - dayNum)
          const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
          return Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
        } catch (e) {
          log.error(e)
        }
        return newstring
      case 'month':
        try {
          const datestring = new Date(newstring)
          return new Intl.DateTimeFormat('en-ZA', { month: 'numeric' }).format(datestring)
        } catch (e) {
          log.error(e)
        }
        return newstring
      case 'monthyear':
        try {
          const datestring = new Date(newstring)
          return new Intl.DateTimeFormat('en-ZA', { month: 'short', year: 'numeric' }).format(datestring)
        } catch (e) {
          log.error(e)
        }
        return newstring
      case 'shortmonthyear':
        try {
          const datestring = new Date(newstring)
          return new Intl.DateTimeFormat('en-ZA', { month: 'numeric', year: 'numeric' }).format(datestring)
        } catch (e) {
          log.error(e)
        }
        return newstring
      case 'shortday':
        try {
          const datestring = new Date(newstring)
          return new Intl.DateTimeFormat('en-ZA', { day: 'numeric' }).format(datestring)
        } catch (e) {
          log.error(e)
        }
        return newstring
      case 'shortdatetime':
        if (newstring) {
          try {
            const datestring = new Date(newstring)
            const dt = {}
            new Intl.DateTimeFormat('en-ZA', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: false })
              .formatToParts(datestring)
              .forEach(part => {
                switch (part.type) {
                  case 'year':
                    dt.year = part.value
                    break
                  case 'month':
                    dt.month = part.value
                    break
                  case 'day':
                    dt.day = part.value
                    break
                  case 'hour':
                    dt.hour = part.value
                    break
                  case 'minute':
                    dt.minute = part.value
                    break
                  case 'second':
                    dt.second = part.value
                    break
                  default:
                    break
                }
              })
            return `${dt.year}/${dt.month}/${dt.day} ${dt.hour}:${dt.minute}:${dt.second}`
          } catch (e) { log.error(e) }
        } else {
          newstring = ''
        }
        return newstring
      case 'shortdate':
        if (newstring) {
          try {
            const datestring = new Date(newstring)
            const dt = {}
            new Intl.DateTimeFormat('en-ZA', { year: 'numeric', month: 'numeric', day: 'numeric' })
              .formatToParts(datestring)
              .forEach(part => {
                switch (part.type) {
                  case 'year':
                    dt.year = part.value
                    break
                  case 'month':
                    dt.month = part.value
                    break
                  case 'day':
                    dt.day = part.value
                    break
                  default:
                    break
                }
              })
            return `${dt.year}-${dt.month}-${dt.day}`
          } catch (e) { log.error(e) }
        } else {
          newstring = ''
        }
        return newstring
      case 'datetime':
        try {
          const datestring = new Date(newstring)
          return new Intl.DateTimeFormat('en-ZA', { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false }).format(datestring)
        } catch (e) { log.error(e) }
        return newstring
      case 'time':
        try {
          let datestring = newstring
          try {
            datestring = new Date(newstring)
            datestring.getTime()
            if (isNaN(datestring.getTime())) {
              throw new Error('Invalid Date')
            }
          } catch (e) {
            datestring = new Date()
            const timeparts = newstring.split(':').filter(t => !isNaN(parseInt(t, 10)))
            datestring.setHours(timeparts[0])
            datestring.setMinutes(timeparts[1])
            datestring.setSeconds(0)
            datestring.setMilliseconds(0)
          }
          return new Intl.DateTimeFormat('en-ZA', { hour: '2-digit', minute: '2-digit', hour12: false }).format(datestring)
        } catch (e) {
          log.error(e)
        }
        return newstring
      case 'timeinterval':
        try {
          // eslint-disable-next-line no-console
          const datestrings = newstring.split(' ')
          return datestrings.map(datestring => {
            try {
              const newDate = new Date(datestring)
              newDate.getTime()
              if (isNaN(newDate.getTime())) {
                throw new Error('Invalid Date')
              }
              return new Intl.DateTimeFormat('en-ZA', { hour: '2-digit', minute: '2-digit', hour12: false }).format(newDate)
            } catch (e) {
              const newDate = new Date()
              const timeparts = datestring.split(':').filter(t => !isNaN(parseInt(t, 10)))
              newDate.setHours(timeparts[0])
              newDate.setMinutes(timeparts[1])
              newDate.setSeconds(0)
              newDate.setMilliseconds(0)
              return new Intl.DateTimeFormat('en-ZA', { hour: '2-digit', minute: '2-digit', hour12: false }).format(newDate)
            }
          }).join(' - ')
        } catch (e) {
          log.error(e)
        }
        return newstring
      case 'number':
        if (!string) { return 0 }
        return new Intl.NumberFormat('en-ZA', { minimumFractionDigits: 0 }).formatToParts(string).map(({ type, value }) => {
          switch (type) {
            case 'decimal': return '.'
            default: return value
          }
        }).reduce((str, part) => str + part)
      case 'decimal': {
        if (!string) { return 0 }
        string = String(string)
        try {
          decimals = (string.toString().indexOf('.') !== -1 && string.toString().indexOf('.00') === -1) ? 2 : 0
          const numberString = new Intl.NumberFormat('en-ZA', {
            style: 'decimal',
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
            useGrouping: false
          }).formatToParts(string).map(({ type, value }) => {
            switch (type) {
              case 'decimal': return '.'
              default: return value
            }
          }).reduce((str, part) => str + part)
          return numberString || 0
        } catch (e) {
          log.error(e)
        }
        break
      }
      case 'decimal_3': {
        if (!string) { return 0 }
        string = String(string)
        try {
          decimals = 3
          const numberString = new Intl.NumberFormat('en-ZA', {
            style: 'decimal',
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
            useGrouping: false
          }).formatToParts(string).map(({ type, value }) => {
            switch (type) {
              case 'decimal': return '.'
              default: return value
            }
          }).reduce((str, part) => str + part)
          return numberString || 0
        } catch (e) {
          log.error(e)
        }
        break
      }
      case 'tel':
        newstring = string
        // Not doing number formatting anymore
        // newstring = string.replace(/\s/g, '')
        // if (newstring.indexOf('+27') === 0) {
        //   newstring = newstring.replace(/(\+\d{2})(\d{2})(\d{3})(\d{4})/, '$1 $2 $3 $4')
        // } else if (newstring.indexOf('27') === 0) {
        //   newstring = newstring.replace(/(\d{2})(\d{2})(\d{3})(\d{4})/, '$1 $2 $3 $4')
        // } else if (newstring.indexOf('0') === 0) {
        //   newstring = newstring.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')
        // } else {
        //   newstring = newstring.replace(/(.{3})/g, '$1 ')
        // }
        return <a href={`tel:${string.replace(/\s/g, '')}`} key={`tel-${string.length}`} className='has-link'>{newstring}</a>
      case 'whatsapp':
        newstring = string
        // Not doing number formatting anymore
        // newstring = string.replace(/\s/g, '')
        // if (newstring.indexOf('+27') === 0) {
        //   newstring = newstring.replace(/(\+\d{2})(\d{2})(\d{3})(\d{4})/, '$1 $2 $3 $4')
        // } else if (newstring.indexOf('27') === 0) {
        //   newstring = newstring.replace(/(\d{2})(\d{2})(\d{3})(\d{4})/, '$1 $2 $3 $4')
        // } else if (newstring.indexOf('0') === 0) {
        //   newstring = newstring.replace(/(\d{3})(\d{3})(\d{4})/, '$1 $2 $3')
        // } else {
        //   newstring = newstring.replace(/(.{3})/g, '$1 ')
        // }
        return (
          <React.Fragment key={`tel-${string.length}`}>
            <div>
              <a
                className="has-link"
                href={`tel:${string.replace(/\s/g, '')}`}
              >
                {newstring}
              </a>
            </div>
            <div>
              <WhatsAppButton
                component={Button}
                className="btn btn-primary"
                type="button"
                resetButtonStyle={false}
                separator=" : "
                phone={newstring.startsWith('0') ? newstring.replace('0', col.country_code) : newstring}
              >
                <svg viewBox="0 0 32 32"><use href="/images/icons-16.svg#icon16-WhatsApp" /></svg> WhatsApp Me
              </WhatsAppButton>
            </div>
          </React.Fragment>
        )
      case 'mailto':
        return <a href={`mailto:${string}`} key={`mailto-${string.length}`} className='has-link'>{string}</a>
      case 'url':
        if (!url_re.test(newstring)) {
          newstring = `http://${newstring}`
        }
        return newstring
      case 'link':
        /* filetypelink depends on this */
        if (typeof string === 'string' && !url_re.test(string)) {
          string = `http://${string}`
        } else if (typeof string === 'object') {
          if (string[col.name]) {
            if (col && col.name === 'video_id') {
              if (string.video_streaming_platform === 'YouTube') {
                string = `https://www.youtube.com/watch?v=${string.video_id}`
              } else if (string.video_streaming_platform === 'Vimeo') {
                string = `https://www.vimeo.com/${string.video_id}`
              }
            }
          } else {
            string = string[col.name]
          }
        }
        return string
      case 'absolute_url': {
        break
      }
      case 'list':
        if (!Array.isArray(string) && col.input === 'SortableTexts' && string.includes(', ')) {
          string = string.split(', ')
        }
        newstring =
        (
          <ul>
            {Array.isArray(string) ? string.map((v, vidx) =>
              <li key={`li-${col.name}-${vidx}`}>{v}</li>
            ) : (
              <li key={`li-${col.name}`}>{string}</li>
            )}
          </ul>
        )
        return newstring
      case 'choice':
        if (col && Array.isArray(col.options) && !col.multi) { // Multi shoud already be formatted
          // eslint-disable-next-line
          newstring = col.options.find(option => option.value == string)
          if (newstring) { newstring = newstring.label }
        }
        return newstring
      case 'yesno':
        newstring = string ? 'Yes' : 'No'
        return newstring
      case 'yesnoicon':
        newstring = string ? (
          <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-Check-Small" /></svg>
        ) : (
          <svg viewBox="0 0 32 32"><use href="/images/icons-24.svg#icon24-X-Small" /></svg>
        )
        return newstring
      case 'viewicon':
        newstring = <div className="icon viewicon"><svg viewBox="0 0 16 16"><use href="/images/icons-16.svg#icon16-EyeOpen" /></svg></div>
        return newstring
      case 'active':
        newstring = string ? 'Active' : 'Inactive'
        return newstring
      case 'mediastatus':
        newstring = string ? 'Saved' : 'Draft'
        return newstring
      case 'image':
        if (newstring && newstring !== '') {
          const parms = {
            w: 320,
            h: 240
          }
          if (typeof string === 'object') {
            newstring = string.file
            if (string.width) {
              parms.w = string.width
            }
            if (string.height) {
              parms.h = string.height
            }
          }
          const cropped = buildLambdaURI(newstring, parms)
          return React.createElement('img', { src: cropped })
        }
        return newstring
      case 'image_url':
        if (newstring && newstring !== '') {
          const parms = {
            w: 320,
            h: 240
          }
          if (typeof string === 'object') {
            newstring = string.file
            if (string.width) {
              parms.w = string.width
            }
            if (string.height) {
              parms.h = string.height
            }
          }
          const cropped = buildLambdaURI(newstring, parms)
          return cropped
        }
        return newstring
      case 'profile_photo':
        if (newstring && newstring !== '') {
          const parms = {
            cx: string.profile_picture_coord_x,
            cy: string.profile_picture_coord_y,
            cw: string.profile_picture_width,
            ch: string.profile_picture_height,
            w: 40,
            h: 40
          }
          if (string.image) {
            const cropped = buildLambdaURI(string.image, parms)
            return <div className="thumbimg"><img src={cropped} alt={string.full_name} /></div>
          }
          return (
            <div className="thumbimg">
              <svg viewBox="0 0 96 96">
                <circle cx="50%" cy="50%" r="50%" />
                <text textAnchor="middle" x="50%" y="50%" dy="0.35em" fontSize="32" fontFamily="Open Sans">{string.initials}</text>
              </svg>
            </div>
          )
        }
        return newstring
      case 'filesize':
        newstring = normaliseFileSize(newstring, true)
        return newstring
      case 'filetype':
        /* filetypelink depends on this */
        switch (newstring) {
          case 'application/x-abiword':
          case 'application/msword':
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          case 'application/vnd.oasis.opendocument.text':
            return <svg viewBox="0 0 32 32"><use href="/images/glyphs.svg#glyph-DocumentDoc" /></svg>
          case 'application/pdf':
            return <svg viewBox="0 0 32 32"><use href="/images/glyphs.svg#glyph-DocumentPdf" /></svg>
          case 'application/vnd.ms-excel':
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return <svg viewBox="0 0 32 32"><use href="/images/glyphs.svg#glyph-DocumentXls" /></svg>
          case 'application/vnd.oasis.opendocument.presentation':
          case 'application/vnd.ms-powerpoint':
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return <svg viewBox="0 0 32 32"><use href="/images/glyphs.svg#glyph-DocumentPpt" /></svg>
          case 'text/plain':
            return <svg viewBox="0 0 32 32"><use href="/images/glyphs.svg#glyph-DocumentTxt" /></svg>
          case 'text/csv':
            return <svg viewBox="0 0 32 32"><use href="/images/glyphs.svg#glyph-DocumentCsv" /></svg>
          case 'image/jpeg':
            return <svg viewBox="0 0 32 32"><use href="/images/glyphs.svg#glyph-DocumentJpg" /></svg>
          case 'image/png':
            return <svg viewBox="0 0 32 32"><use href="/images/glyphs.svg#glyph-DocumentPng" /></svg>
          default:
            return null
        }
      case 'score':
        switch (newstring) {
          case 3:
            return (<svg viewBox="0 0 32 32" className="btmstar hot"><use href="/images/glyphs.svg#glyph-Star" /></svg>)
          case 2:
            return (<svg viewBox="0 0 32 32" className="btmstar warm"><use href="/images/glyphs.svg#glyph-Star" /></svg>)
          default:
            return (<svg viewBox="0 0 32 32" className="btmstar cold"><use href="/images/glyphs.svg#glyph-Star" /></svg>)
        }
      case 'filetypelink': {
        const ft = React.createElement('span', {}, valueFormat('filetype', newstring, col))
        const l = valueFormat('link', newstring, col)
        return React.cloneElement(l, {}, ft)
      }
      case 'measurement_type': {
        if (isNaN(string)) { return string }
        decimals = (string.toString().indexOf('.') !== -1 && string.toString().indexOf('.00') === -1) ? 2 : 0
        newstring = new Intl.NumberFormat('en-ZA', { minimumFractionDigits: decimals }).formatToParts(string).map(({ type, value }) => {
          switch (type) {
            case 'decimal': return '.'
            default: return value
          }
        }).reduce((str, part) => str + part)
        switch (col.measurement_type) {
          case 'Hectares':
            newstring = `${newstring}Ha`
            break
          case 'Square Feet':
            newstring = `${newstring}ft${String.fromCodePoint(178)}`
            break
          case 'Acres':
            newstring = `${newstring}Acre`
            break
          default:
            newstring = `${newstring}m${String.fromCodePoint(178)}`
            break
        }
        return newstring
      }
      case 'unit': {
        switch (newstring) {
          case 'Hectares':
            newstring = 'Ha'
            break
          case 'Square Feet':
            newstring = `ft${String.fromCodePoint(178)}`
            break
          case 'Acres':
            newstring = 'Acre'
            break
          default:
            newstring = `m${String.fromCodePoint(178)}`
            break
        }
        return newstring
      }
      case 'contact_popup': {
        return <ContactPopup contact={string} col={col}>{string.first_name} {string.last_name}</ContactPopup>
      }
      case 'listing_popup': {
        return <ListingPopup listing={string} col={col}>{string.web_ref}</ListingPopup>
      }
      case 'address': {
        newstring = [
          'address_line_1',
          'address_line_2',
          'city',
          'province',
          'country',
          'postal_code'
        ].map(k => string[k]).filter(Boolean).join(', ')
        return newstring
      }
      case 'domstring':
      case 'raw':
      case 'linebreakdomstring':
      case 'editorstring': {
        return <div className="domstring" dangerouslySetInnerHTML={{ __html: string }} />
      }
      case 'humanize': {
        return humanize(newstring)
      }
      case 'redact': {
        return Array.prototype.map.call(newstring.toString(), () => '*').join('')
      }
      case 'iframe': {
        newstring = template(newstring, {
          interpolate: /{{([\s\S]+?)}}/g
        })({
          subscriber: { meta: { contact: { first_name: 'Subscriber' } } },
          unsubscribe_url: ''
        })
        return <iframe srcDoc={newstring} scrolling="yes" style={{ width: '100%', height: '100vh' }} />
      }
      case 'iframe-link': {
        return <iframe src={newstring} scrolling="yes" style={{ width: '100%', height: '100vh' }} />
      }
      case 'referral-contact': {
        if ([ 'first_name', 'last_name', 'email', 'cell_number' ].includes(col.name)) {
          const names = [ 'first_name', 'last_name' ].includes(col.name)
          const perms = col.permissions && col.permissions.includes('referral_can_view_created_contact')
          if (col[col.name]) {
            return <div className="referral-contact"><div className="referral-contact-icon match"><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>{names || perms ? newstring : ''}</div>
          }
          return <div className="referral-contact"><div className="referral-contact-icon"><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-X-Small" /></svg></div>{newstring}</div>
        }
        return null
      }
      case 'stage':
        newstring = getIn(string, 'stage', getIn(string, 'meta.lead.stage'))
        if (string.status === 'Inactive') {
          newstring = 'Archived'
        }
        if ([ 'To Let', 'Holiday Letting' ].includes(getIn(string, 'meta.listing.listing_type')) && newstring === 'Sold') {
          newstring = 'Rented'
        }
        return <div className={`lead-stage ${newstring}`}>{newstring}</div>
      case 'deal-stage':
        if (newstring === 'Closed') {
          newstring = 'Registered / Closed'
        }
        return <div className={`deal-stage ${slugify(string)}`}>{newstring}</div>
      case 'application-stage':
        return <div className={`application-stage ${newstring.replace(/\s/gi, '-')}`}>{newstring}</div>
      case 'stage-expanded':
        return (
          <div className="lead-progress">
            <div className="lead-progress-item">
              <div className={classNames('lead-progress-icon', { New: [ 'New', 'Contacted', 'Viewing', 'Offer', 'Sold', 'Archived', 'Lease Application', 'Rental Application' ].includes(string.stage) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
              <div className="lead-progress-label">New</div>
            </div>
            <div className="lead-progress-divider" />
            <div className="lead-progress-item">
              <div className={classNames('lead-progress-icon', { Contacted: [ 'Contacted', 'Viewing', 'Offer', 'Sold', 'Lease Application', 'Rental Application' ].includes(string.stage) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
              <div className="lead-progress-label">Contacted</div>
            </div>
            <div className="lead-progress-divider" />
            <div className="lead-progress-item">
              <div className={classNames('lead-progress-icon', { Viewing: [ 'Viewing', 'Offer', 'Sold' ].includes(string.stage) || string.meta.interactions?.some(i => i.viewing) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
              <div className="lead-progress-label">Viewing</div>
            </div>
            <div className="lead-progress-divider" />
            <div className="lead-progress-item">
              <div className={classNames('lead-progress-icon', { 'Lease-Application': [ 'Lease Application', 'Rental Application' ].includes(string.stage) || string.meta.interactions?.some(i => i.rental_application) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
              <div className="lead-progress-label">Rental Application</div>
            </div>
            <div className="lead-progress-divider" />
            <div className="lead-progress-item">
              <div className={classNames('lead-progress-icon', { Offer: [ 'Offer', 'Sold' ].includes(string.stage) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
              <div className="lead-progress-label">Offer</div>
            </div>
            <div className="lead-progress-divider" />
            <div className="lead-progress-item">
              <div className={classNames('lead-progress-icon', { Sold: [ 'Sold' ].includes(string.stage) })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
              <div className="lead-progress-label">{[ 'To Let', 'Holiday Letting' ].includes(getIn(string, 'meta.listing.listing_type')) ? 'Rented' : 'Sold'}</div>
            </div>
            <div className="lead-progress-divider" />
            <div className="lead-progress-item">
              <div className={classNames('lead-progress-icon', { Archived: string.status === 'Inactive' })}><svg viewBox='0 0 32 32'><use href="/images/icons-16.svg#icon16-Check-Small" /></svg></div>
              <div className="lead-progress-label">Archived</div>
            </div>
          </div>
        )
      case 'contact_whatsapp_link': {
        let whatsapp_link = ''
        let contact_number = ''
        let dial_code = ''
        const { modelname } = col
        if (typeof newstring === 'string') {
          return newstring
        }
        if ([ 'residential', 'commercial', 'holiday', 'projects' ].includes(modelname)) {
          dial_code = getIn(newstring, 'meta.branch.meta.country_code', getIn(col, 'model.meta.branch.country_code', '+27'))
        }
        if (modelname === 'agents') {
          dial_code = getIn(newstring, 'meta.branches[0].country_code', '+27')
        }
        if (modelname === 'contacts') {
          dial_code = getIn(newstring, 'meta.branch.country_code', '+27')
        }
        if (modelname === 'profiles') {
          dial_code = getIn(newstring, 'meta.contact.meta.branch.country_code', '+27')
        }
        if (modelname === 'leads') {
          dial_code = getIn(newstring, 'meta.contact.meta.branch.country_code', '+27')
        }
        if (modelname === 'profiles') {
          contact_number = getIn(newstring, `meta.${col.name}`, '')
        } else {
          contact_number = getIn(newstring, col.name, '') || ''
        }
        if (!contact_number) {
          return null
        }
        dial_code = dial_code || '+27'
        if (!contact_number.startsWith(dial_code)) {
          if (contact_number.startsWith('0')) {
            contact_number = contact_number.replace('0', dial_code)
          } else if (!contact_number.startsWith('+')) {
            contact_number = dial_code + contact_number
          }
        }

        whatsapp_link = contact_number.replace(/\s/g, '').replace('+', '')
        return (
          <div className='whatsapp-tel-link'>
            <WhatsAppButton
              component={Button}
              className="whatsapp-link has-link"
              resetButtonStyle={false}
              separator=" : "
              phone={whatsapp_link}
            >
              <svg viewBox='0 0 14 15'><use href='/images/icons-24.svg#icon24-WhatsApp' /></svg>
            </WhatsAppButton>
            <a href={`tel:${contact_number.replace(/\s/g, '')}`} key={`tel-${string.length}-${col.name}`} className='has-link'>{contact_number}</a>
          </div>
        )
      }
      case 'whatsapp_link_only': {
        let whatsapp_link = ''
        let contact_number = ''
        let dial_code = ''
        const { modelname } = col
        if (typeof newstring === 'string') {
          return newstring
        }
        if ([ 'residential', 'commercial', 'holiday', 'projects' ].includes(modelname)) {
          dial_code = getIn(newstring, 'meta.branch.meta.country_code', getIn(col, 'model.meta.branch.country_code', '+27'))
        }
        if (modelname === 'agents') {
          dial_code = getIn(newstring, 'meta.branches[0].country_code', '+27')
        }
        if (modelname === 'contacts') {
          dial_code = getIn(newstring, 'meta.branch.country_code', '+27')
        }
        if (modelname === 'profiles') {
          dial_code = getIn(newstring, 'meta.contact.meta.branch.country_code', '+27')
        }
        if (modelname === 'leads') {
          dial_code = getIn(newstring, 'meta.contact.meta.branch.country_code', '+27')
        }
        if (modelname === 'profiles') {
          contact_number = getIn(newstring, `meta.${col.name}`, '')
        } else {
          contact_number = getIn(newstring, col.name, '') || ''
        }
        if (!contact_number) {
          return null
        }
        if (!contact_number.startsWith(dial_code)) {
          if (contact_number.startsWith('0')) {
            contact_number = contact_number.replace('0', dial_code)
          } else if (!contact_number.startsWith('+')) {
            contact_number = dial_code + contact_number
          }
        }

        whatsapp_link = contact_number.replace(/\s/g, '').replace('+', '')
        return (
          <div className='whatsapp-link'>
            <svg viewBox='0 0 14 15'><use href='/images/icons-24.svg#icon24-WhatsApp' /></svg>
            <WhatsAppButton
              component={Button}
              className="has-link"
              resetButtonStyle={false}
              separator=" : "
              phone={whatsapp_link}
            >
              WhatsApp
            </WhatsAppButton>
          </div>
        )
      }
      case 'title': {
        return title(newstring.replace(/-/gi, ' '))
      }
      case 'int': { // Can also use number for pretty formatting ie. 100 000 vs. 100000
        return newstring ? parseInt(newstring, 10) : 0
      }
      case 'criteria': {
        return col.fields.map(field => {
          let value = getIn(newstring.report_filters, `${field.name}${field.verb ? `__${field.verb}` : ''}`)
          if (!value) { return null }
          if (field.modelname) {
            if (Array.isArray(value)) {
              value = getIn(newstring.report_filters, `meta.${field.name}${field.verb ? `__${field.verb}` : ''}`)?.map(o => {
                if (Array.isArray(field.optionlabel)) {
                  return field.optionlabel.map(fe => valueFormat(field.format, getIn(o, fe, ''), field)).join(field.labelseparator)
                }
                return valueFormat(field.format, getIn(o, field.optionlabel, ''), field)
              }).join(', ')
            } else {
              value = valueFormat(field.format, getIn(newstring.report_filters, `meta.${field.name}${field.verb ? `__${field.verb}` : ''}.${field.optionlabel}`, ''), field)
            }
          } else if (Array.isArray(value)) {
            value = value.map(o => valueFormat(field.format, getIn(o, field.optionlabel, ''), field)).join(field.labelseparator)
          } else {
            value = valueFormat(field.format, value, field)
          }
          return <React.Fragment key={`fe-${field.id || `${field.name}${field.verb ? `__${field.verb}` : ''}`}`}><strong>{field.label}:&nbsp;</strong><span>{value}</span>&nbsp;</React.Fragment>
        }).filter(v => v)
      }
      default:
        if (Array.isArray(newstring) && col.labelseparator) {
          return newstring.join(col.labelseparator)
        }
        return string
    }
  } catch (e) {
    log.error(e, format, string, col)
  }
  return string
}

/**
* getHeight - for elements with display:none
*/
export const getHeight = el => {
  const el_style = window.getComputedStyle(el)
  const el_display = el_style.display
  const el_max_height = el_style.maxHeight.replace('px', '').replace('%', '')
  let wanted_height = 0
  // if its not hidden we just return normal height
  if (el_display !== 'none' && el_max_height !== '0') { return el.scrollHeight }
  // the element is hidden so:
  // making the el block so we can meassure its height but still be hidden
  el.style.position = 'absolute'
  el.style.visibility = 'hidden'
  el.style.display = 'block'
  wanted_height = el.scrollHeight + 30
  // reverting to the original values
  el.removeAttribute('style')
  return wanted_height
}

export const setMaxHeights = () => {
  for (const item of document.getElementsByClassName('subnavitem')) {
    item.setAttribute('data-max-height', `${getHeight(item)}px`)
  }
}

/**
* toggleSlide mimics the jQuery version of slideDown and slideUp
* all in one function comparing the max-heigth to 0
**/
export const toggleSlide = el => {
  if (!el) { return }
  let el_max_height = 0
  if (el.getAttribute('data-max-height')) {
    if (el.style.maxHeight.replace('px', '').replace('%', '') === '0') {
      el.style.maxHeight = el.getAttribute('data-max-height')
    } else {
      el.style.maxHeight = '0'
    }
  } else {
    el_max_height = `${getHeight(el)}px`
    el.setAttribute('data-max-height', el_max_height)
    setTimeout(() => {
      if (el_max_height > 0) {
        el.classList.add('open')
      } else {
        el.classList.remove('open')
      }
      el.style.maxHeight = el_max_height
    }, 10)
  }
}

export const slideUp = el => {
  if (!el) { return }
  if (!el.getAttribute('data-max-height')) {
    el.setAttribute('data-max-height', `${getHeight(el)}px`)
  }
  setTimeout(() => {
    el.classList.remove('open')
    el.style.maxHeight = 0
  }, 10)
}

export const slideDown = el => {
  if (!el) { return }
  let el_max_height = 0
  if (el.getAttribute('data-max-height')) {
    el_max_height = el.getAttribute('data-max-height')
  } else {
    el_max_height = `${getHeight(el)}px`
    el.setAttribute('data-max-height', el_max_height)
  }
  // we use setTimeout to modify maxHeight later than display (to ensure we have the transition effect)
  setTimeout(() => {
    el.classList.add('open')
    el.style.maxHeight = el_max_height
  }, 10)
}

export const isEmpty = obj => obj.constructor === Object && Object.keys(obj).length === 0

export const groupBy = (array, prop, modifier = null) => array.reduce((grp, item) => {
  let val = item[prop]
  if (typeof modifier === 'function') {
    val = modifier(val, item)
  }
  grp[val] = grp[val] || []
  grp[val].push(item)
  return grp
}, {})

export const chunkArray = (array, size) => array.reduce((resultArray, item, index) => {
  const chunkIndex = Math.floor(index / size)
  if (!resultArray[chunkIndex]) { resultArray[chunkIndex] = [] } // start a new chunk
  resultArray[chunkIndex].push(item)
  return resultArray
}, [])


export const stripWhiteSpace = s => s.replace(/\s/g, '')

// Helper function to interpolate data based on a key lookup
const interpolateURLData = (key, data, lock = 'id') => {
  if (!data) { return null }
  let val = ''
  if (key.indexOf(',') !== -1) { // Key contains multiple keys
    val = key.split(',').map(k => {
      if (Array.isArray(data[k])) {
        return data[k].join(',')
      } else if (data[k]) {
        return data[k]
      } else if (data && getIn(data, key)) {
        return getIn(data, key)
      }
      return null
    }).filter(v => v).join(',')
  } else if (data.hasOwnProperty(key)) { // Key is in the modeldata
    if (Array.isArray(data[key])) { // Data contains multiple values for key
      val = data[key].join(',')
    } else { // Data maps to key directly as 1:1
      val = data[key] ? data[key] : '' // Cater for null values too
    }
    if (Array.isArray(val) && typeof val[0] === 'object') {
      val = val.map(i => i[lock])
    }
    if (Array.isArray(val)) { val = val.filter(v => v).join(',') }
  } else if (getIn(data, key)) { // user.agent.branches
    val = getIn(data, key)
    if (Array.isArray(val) && typeof val[0] === 'object') {
      val = val.map(i => i[lock])
    }
    if (Array.isArray(val)) { val = val.filter(v => v).join(',') }
  }
  return val
}

export const getSearchParam = prop => {
  const p = new URLSearchParams(window.location.search)
  return p.get(prop)
}

export const updateSearchParms = (parm, val) => {
  const nurl = new URL(window.location.href)
  const qs = new QueryBuilder(nurl.search, true)
  // Don't need the below - Querybuilder does the same and has support for __overlap
  // if (typeof parm === 'object') { // Generally advanced search
  //   nurl.searchParams.delete('term')
  //   Object.keys(parm).forEach(p => {
  //     const value = Array.isArray(parm[p]) ? parm[p].join(',') : parm[p]
  //     if (parm.hasOwnProperty(p)) { nurl.searchParams.set(p, value) } // Avoid prototypes
  //   })
  //   for (const k of nurl.searchParams.keys()) {
  //     if (!parm.hasOwnProperty(k)) { nurl.searchParams.delete(k) } // Object should contain all the params
  //   }
  // } else { // Simple param with value to add / replace in search
  //   if (parm !== 'offset') { nurl.searchParams.delete('offset') } // Remove offset on search change unless it is specified
  //   if (parm === 'order_by') { nurl.searchParams.delete('order_by_related') } // Mutually exclusive
  //   if (parm === 'order_by_related') { nurl.searchParams.delete('order_by') } // Mutually exclusive
  //   nurl.searchParams.set(parm, val)
  //   if (parm === 'offset' && val === 0) { nurl.searchParams.delete('offset') } // Offset zero not needed
  // }
  // nurl.searchParams.forEach((v, k) => { if (v === '' || v === 0) { nurl.searchParams.delete(k) } })
  if (typeof parm === 'object' && parm !== null) {
    Object.keys(parm).forEach(k => {
      if (parm[k] !== 0 && parm[k]) {
        qs.setParam(k, parm[k])
      } else {
        qs.removeParam(k)
      }
    })
  } else {
    qs.setParam(parm, val)
  }
  history.push({
    pathname: history.location.pathname,
    search: qs.url(false)
  })
}

export const parseClasses = (
  classes, // List of raw classes ie. ['text-center', ':form-value']
  context) => { // Context object from which to take values
  const parsed = []
  if (Array.isArray(classes)) {
    classes.forEach(c => {
      if (c.indexOf(':') === -1) { // no need to parse
        parsed.push(c)
      } else { // Need to parse from context
        const key = c.replace(/:/, '') // Remove colon
        let value = getIn(context, key) // Get the value in the context
        if (value) {
          value = value.replace(/\s/, '').toLowerCase()
          parsed.push(value)
        } // If set, assign the class from the value
      }
    })
    return parsed.join(' ')
  }
  return classes
}
export const parseURL = (
  schema, // Schema string to search for within data
  modeldata, // Model specific data
  agentdata, // Logged in agent data
  sitedata = false, // Agent's site data
  cache = null, // Site data for other models
  index = 0, // Index from which to take answer if an array
  field
) => { // Lock specifies item within schema key result to return as answer ie. portals.agency should return value of portal (defaults to id)
  if (!schema) { return null }
  /* Parse a URL or link. This will convert a schema string into
  * it's parts and interpolate any model or site data into detected variables
  * Uses helper function interpolateURLData to find relevant data by key.
  *
  * Example schemas:
  * /secure/projects?agent__id=:id&status=Active&listing_type=Commercial Estate
  * /secure/agents/:agent_4
  * id__in__not=:agent,agent_2,agent_4
  * :country, :province
  *
  * Example data:
  * { id: 123, name: 'Foo Branch'}
  * { [ { id: 123, name: 'Branch One } , { id: 456, name: 'Branch Two' } ] }
  */
  if ((typeof schema === 'string' || schema instanceof String) && schema.includes('<%')) {
    const compiled = template(schema, { imports: { valueFormat: valueFormat } })
    try {
      return compiled({ ...sitedata, ...agentdata, ...modeldata })
    } catch (e) {
      return null
    }
  }

  try {
    const re = RegExp(':([^:&,/]+)', 'gi')
    const keys = []
    let match
    const lock = field ? field.extraparamslock : undefined
    while ((match = re.exec(schema)) !== null) { keys.push(match.pop()) }
    let url = schema.replace(/\/$/, '') // Remove trailing slash
    if (keys.length === 0) {
      if (getIn(modeldata, schema)) { return getIn(modeldata, schema) }
    }
    // A key is something like agent,agent_2,agent_4
    keys.forEach(key => {
      key = key.trim()
      let arrayfield = false
      let val = false
      if (key.indexOf('{') !== -1) { // ArrayField
        arrayfield = true
        key = key.replace(/{|}/gi, '')
      }
      if (key.indexOf('|') !== -1) { // id__in__not=:agent_2|agent_3|agent_4&....
        val = key.split('|').map(k => interpolateURLData(k, modeldata)).filter(k => k).join(',')
        key = key.replace(RegExp(/\|/, 'gi'), '\\|')
      }
      if (!val) { // Not multi - attempt 1:1 against model data
        val = interpolateURLData(key, modeldata, lock)
      }
      if (!val && field && 'aidx' in field && 'aname' in field) { // FieldArray - try field data
        const fielddata = getIn(modeldata, `${field.aname}.${field.aidx}`)
        val = interpolateURLData(key, fielddata, lock)
      }
      if (!val && key.includes('[aidx].') && 'aidx' in field && 'aname' in field) { // FieldArray - try field data
        const akey = key.split('[aidx].').pop()
        const fielddata = getIn(modeldata, `${field.aname}.${field.aidx}`)
        val = interpolateURLData(akey, fielddata, lock)
      }
      if (!val) { // Take from user data
        val = interpolateURLData(key, agentdata, lock)
      }
      if (!val && sitedata) { // Take from site data
        val = interpolateURLData(key, sitedata, lock)
      }
      if (key === 'site' && !val) {
        val = window.localStorage.getItem('site')
      }
      if (!val && cache) { // Final fallback is to data is part of a different model
        if (key.indexOf('[') !== -1) { // :[teams]team.agents
          const re_k = RegExp(/\[([^:&,/]+)\]/, 'gi')
          let modelname = null
          const cache_match = re_k.exec(key)
          while (!modelname && cache_match.length) { modelname = cache_match.pop() }
          const new_key = key.replace(re_k, '')
          const form_key = new_key.split('.')[0] // Key for use in the cache keys from form data value
          const cache_key = new_key.split('.')[1] // Key for use in the cache item match
          const value = modeldata[form_key]
          let cache_data = null
          if (cache[modelname]) {
            cache_data = cache[modelname][value]
            if (!cache_data) { cache_data = cache[modelname][form_key] }
          }
          val = interpolateURLData(cache_key, cache_data, lock) || ''
        }
      }
      const bareval = val // Allows us to use naked value when a specific index is required ie. first branch
      if (arrayfield) { val = `{${val}}` }
      if (typeof bareval === 'string' && bareval.indexOf(',') !== -1 && index !== 0) { // Multiple values ie. array
        const re2 = arrayfield ? RegExp(`:{${(key).replace('[', '\\[').replace(']', '\\]')}}`, 'gi') : RegExp(`:${(key).replace('[', '\\[').replace(']', '\\]')}`, 'gi')
        url = url.replace(re2, `/${bareval.split(',')[index]}`) // Return URL from provided index
      } else { // A single value
        const re3 = arrayfield ? RegExp(`:{${(key).replace('[', '\\[').replace(']', '\\]')}}`, 'gi') : RegExp(`:${(key).replace('[', '\\[').replace(']', '\\]')}`, 'gi')
        url = url.replace(re3, val)
      }
    })
    if (url.startsWith('/')) {
      url = new URL(url, window.location)
      return `${url.pathname}${url.search}`
    }
    return url
  } catch (e) {
    log.error(e, { schema, modeldata, sitedata, cache, index })
  }
  return null
}

export const getStringColor = str => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  const h = hash % 360
  let s = Math.abs(Math.floor((h / 360) * 100))
  if (s < 40) {
    s += 40
  }
  if (s > 70) {
    s -= 25
  }
  let l = Math.abs(Math.floor((h / 360) * 100))
  if (l < 40) {
    l += 40
  }
  if (l > 70) {
    l -= 25
  }
  return `hsl(${h}, ${s}%, ${l}%)`
}

export const updateAdditionalParams = (params, fields) => {
  fields.forEach(f => {
    getIn(params, f, []).forEach(p => {
      params[p] = 1
    })
    delete params[f]
  })
  return params
}

export const getRandomColor = ind => {
  const colourList = [
    '#10294D',
    '#FC495D',
    '#70859E',
    '#92A7C0',
    '#B2C2D4',
    '#CED7E2',
    '#E5EBF1',
    '#34BFDE',
    '#88DEF1',
    '#FDA4AE',
    '#D7F4FA',

    '#BEC3C7',
    '#ECF0F1',
    '#1CAE5B',
    '#13B696',
    '#33495F',
    '#95A4A7',
    '#D45400',
    '#7E8C8D',
    '#C03924'
  ]
  if (ind <= colourList.length) {
    return colourList[ind]
  }
  const letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export const whichTransitionEvent = el => {
  const transitions = {
    transition: 'transitionend',
    OTransition: 'oTransitionEnd',
    MozTransition: 'transitionend',
    WebkitTransition: 'webkitTransitionEnd'
  }

  for (const t in transitions) {
    if (typeof el.style[t] !== 'undefined') {
      return transitions[t]
    }
  }
  return transitions[0]
}

export const whichTransitionStartEvent = el => {
  const transitions = {
    transition: 'transitionstart',
    OTransition: 'oTransitionStart',
    MozTransition: 'transitionstart',
    WebkitTransition: 'webkitTransitionStart'
  }

  for (const t in transitions) {
    if (typeof el.style[t] !== 'undefined') {
      return transitions[t]
    }
  }
  return transitions[0]
}

/* Copy object */
const Types = new Map()
Types.set(Array, v => {
  const l = v.length
  let i = 0
  const a = Array(l)
  for (i; i < l; i++) { a[i] = v[i] }
  return a
})

Types.set(Number, v => v * 1)
Types.set(String, v => String(v))
Types.set(Function, v => v)
Types.set(Boolean, v => !!v)

export const sortBy = (array, prop, comparitor) => {
  if (comparitor) {
    array.sort(comparitor)
    return merge([], array)
  }
  if (!prop) { return merge([], array) }
  array.sort((a, b) => {
    if (Array.isArray(prop)) {
      const aVal = prop.map(p => getIn(a, p)).filter(p => p).join(' ').toUpperCase()
      const bVal = prop.map(p => getIn(b, p)).filter(p => p).join(' ').toUpperCase()
      if (aVal < bVal) { return -1 }
      if (aVal > bVal) { return 1 } // names must be equal
      return 0
    }
    if (a && b) {
      if (isNaN(getIn(a, prop)) && isNaN(getIn(b, prop))) {
        let A
        let B
        try {
          A = getIn(a, prop, '').toUpperCase()
        } catch (e) {
          A = getIn(a, prop, '')
        }
        try {
          B = getIn(b, prop, '').toUpperCase()
        } catch (e) {
          B = getIn(b, prop, '')
        }
        if (A < B) { return -1 }
        if (A > B) { return 1 } // names must be equal
        return 0
      }
      return a[prop] - b[prop]
    }
    return 0
  })
  return merge([], array)
}

export const decimalPlaces = num => {
  const match = (`${num}`).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/)

  if (!match) { return 0 }

  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0)
    // Adjust for scientific notation.
    - (match[2] ? +match[2] : 0)
  )
}
// helper function to help us with reordering the result of dnd lists
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [ removed ] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

export const convertArrayToObject = (array, key) => {
  const initialValue = {}
  return array.reduce((obj, item, idx) => ({
    ...obj,
    [key ? item[key] : idx]: item
  }), initialValue)
}

export const buildOptionLabel = (data, res) => {
  /* Return an object for custom labelled AsyncSelects of the form
   * {value: 22181, label: "Ballito, Umhlali", head: "Ballito, Umhlali", sub: "KwaZulu Natal, South Africa"}
   * and is predominantly used in the selects although is also used in other places (like ActivityEvent)
   * for the display or related field labels.
   * data: Field config data from JSON config
   * res: Actual model data from microservices
  */
  const opt = { value: res.id, label: '', head: '', sub: '' }
  if (data.optionvalue) {
    opt.value = getIn(res, data.optionvalue)
  }
  if (data.labelformat) { // Custom label formatter
    if (Array.isArray(data.labelformat.head)) {
      opt.head = data.labelformat.head.map(k => {
        if (k.indexOf(':') !== -1) { return parseURL(k, res) }
        if (res[k] && typeof res[k] === 'string') { return res[k].trim() }
        return ([ ',', ' ', data.labelseparator ].indexOf(k) !== -1) ? k : null
      }).filter(a => a && a.trim() !== '')
      opt.head = opt.head.join(data.labelseparator || ' ')
    } else if (data.labelformat.head.indexOf(':') !== -1) {
      opt.head = parseURL(data.labelformat.head, res)
    }

    if (data.labelformat.sub) {
      if (Array.isArray(data.labelformat.sub)) {
        opt.sub = data.labelformat.sub.map(k => {
          if (k.indexOf(':') !== -1) { return parseURL(k, res) }
          if (res[k] && typeof res[k] === 'string') { return res[k].trim() }
          return ([ ',', ' ', data.labelseparator ].indexOf(k) !== -1) ? k : null
        }).filter(a => a && a.trim() !== '' && !RegExp(/^null\s?/, 'gi').exec(a))
        opt.sub = opt.sub.join(data.labelseparator || ' ')
      } else if (data.labelformat.sub.indexOf(':') !== -1) {
        opt.sub = parseURL(data.labelformat.sub, res)
      }
    }

    if (data.labelformat.tags) {
      if (Array.isArray(data.labelformat.tags)) {
        opt.tags = data.labelformat.tags.map(k => {
          if (k.indexOf(':') !== -1) { return parseURL(k, res) }
          if (res[k] && typeof res[k] === 'string') { return res[k].trim() }
          return ([ ',', ' ', data.labelseparator ].indexOf(k) !== -1) ? k : null
        }).filter(a => a && a.trim() !== '' && !RegExp(/^null\s?/, 'gi').exec(a))
      }
    }

    const parms = {
      cx: res.profile_picture_coord_x,
      cy: res.profile_picture_coord_y,
      cw: res.profile_picture_width,
      ch: res.profile_picture_height,
      w: res.profile_picture_width,
      h: res.profile_picture_height
    }
    if (res.meta && res.meta.image) { opt.img = buildLambdaURI(res.meta.image.file, parms) } // User photos
  }
  if (data.optionlabel) { // Create label with separator if specified
    if (Array.isArray(data.optionlabel)) {
      opt.label = data.optionlabel.map(k => {
        if (res[k]) { return res[k].trim() }
        return k
      }).join(data.labelseparator || ' ')
    } else {
      opt.label = getIn(res, data.optionlabel)
    }
  } else if (Array.isArray(data.searchkey)) { // Create label with separator if specified
    opt.label = data.searchkey.map(k => {
      if (res[k] && typeof res[k] === 'string') { return res[k].trim() }
      return k
    }).join(data.labelseparator || ' ')
  } else if (data.searchkey && getIn(res, data.searchkey)) {
    opt.label = getIn(res, data.searchkey)
  }
  if (opt.label) { opt.label = String(opt.label).trim() }
  return opt
}

/* A function to check a module against a set of active addons for a
*  specific view or resource.
*/
export function hasAddons(requiredAddons, addons) {
  if (!requiredAddons) { return true } // No perms required
  if (!addons) { return false } // No addons set in settings
  let res = false
  res = (requiredAddons.filter(perm => addons.includes(perm)).length)
  return !!res
}

/* A helper function for hasPermission which allows for looping over / getting in permission keys */
function hasOwnAssocPerms(permsRequired, userPermissions, values, agentid) {
  let perms = false // Default to forbidden
  if (permsRequired.filter(perm => userPermissions.includes(perm)).length && values.includes(agentid)) { // Does the agent have the perm?
    perms = values.includes(agentid)
  }
  return perms
}
/* A function to check permissions again a set of required permissions for a
*  specific view or resource. Additional parameters include the checking of a
*  specific context for individual object permissions (ie. _own).
*/
export function hasPermission(
  permissionsRequired,
  userPermissions,
  context = false,
  agentid = false,
  key = false,
  preferOwn = false
) {
  if (!permissionsRequired) { return true } // No perms required
  if (!userPermissions) { return false } // No perms required
  if (userPermissions.includes('is_prop_data_user') && permissionsRequired.includes('!is_prop_data_user')) { return false } // No super perms false
  if (userPermissions.includes('is_prop_data_user') && !permissionsRequired.includes('!is_prop_data_user')) { return true } // Super perms
  if (permissionsRequired.includes('is_prop_data_user')) { return false } // Supers only but not a super
  if (isEqual(permissionsRequired, [ '!is_prop_data_user' ]) && !userPermissions.includes('is_prop_data_user')) { return true }
  const notOwnPermsRequired = permissionsRequired.filter(p => !p.endsWith('_own') && !p.includes('_associated_agents_') && !p.startsWith('!'))
  let res = !preferOwn ? notOwnPermsRequired.filter(p => userPermissions.includes(p)).length : undefined // Non-own perms only
  const ownPermsRequired = permissionsRequired.filter(p => p.endsWith('_own') && !p.startsWith('!'))
  const assocPermsRequired = permissionsRequired.filter(p => p.includes('_associated_agents_') && !p.startsWith('!'))
  // If own perms are required and we still don't have perm then try test for own perms
  if (!preferOwn) {
    if (ownPermsRequired.length && context && agentid && key && !res) { // Check if the record is owned by the current agent
      res = Object.keys(key).map(field => {
        const perms = key[field]
        let values = getIn(context, field, []) || []
        if (values && !Array.isArray(values)) {
          values = [ values ]
        }
        return hasOwnAssocPerms(
          perms.filter(p => ownPermsRequired.includes(p)),
          userPermissions,
          values,
          agentid
        )
      }).some(k => k)
    } else if (ownPermsRequired.length && !res) { // Own perms only without a key or context ie. 1:1
      res = ownPermsRequired.filter(p => userPermissions.includes(p)).length
    }
  }
  if (res === undefined && preferOwn) {
    res = notOwnPermsRequired.filter(p => userPermissions.includes(p)).length // Non-own perms only
    if (!res) {
      if (ownPermsRequired && context && agentid && key && !res) { // Check if the record is owned by the current agent
        res = Object.keys(key).map(field => {
          const perms = key[field]
          let values = getIn(context, field, []) || []
          if (values && !Array.isArray(values)) {
            values = [ values ]
          }
          return hasOwnAssocPerms(
            perms.filter(p => ownPermsRequired.includes(p)),
            userPermissions,
            values,
            agentid
          )
        }).some(k => k)
      } else if (ownPermsRequired && !res) { // Own perms only without a key or context ie. 1:1
        res = ownPermsRequired.filter(p => userPermissions.includes(p)).length
      }
    }
  }

  // If associated agent perms are required and we still don't have perm then test for associated agent perms
  if (assocPermsRequired.length && context && agentid && key && !res) { // Check if the record is owned by the current agent
    res = Object.keys(key).map(field => {
      const perms = key[field]
      let values = getIn(context, field, []) || []
      if (values && !Array.isArray(values)) {
        values = [ values ]
      }
      return hasOwnAssocPerms(
        perms.filter(p => assocPermsRequired.includes(p)),
        userPermissions,
        values,
        agentid
      )
    }).some(k => k)
  } else if (assocPermsRequired.length && !res) { // Associated perms only without a key or context ie 1:1
    res = assocPermsRequired.filter(p => userPermissions.includes(p)).length
  }

  // Inverse permissions stipulate what permissions users are not allowed to have
  const inversePermsDenied = permissionsRequired.filter(p => p.startsWith('!') && p !== ('!is_prop_data_user')) // Finally check for inverse perms as these will deny if present
  if (inversePermsDenied.length && inversePermsDenied.filter(p => userPermissions.includes(p.substring(1))).length) {
    res = false
  } else if (inversePermsDenied.length) {
    res = true
  }

  return !!res
}

/*
 * Helper function to determine if a field should be applicable to the current selected context
 * , based on rules defined in the field config. ie. field.edit = Bool || Array
*/
export function isConditional(field, lookup, edit = false, form = null, user = null, cache = null) { // Need to use old school function here as we bind 'this'
  if (this && !form) { return edit } // Pass in a mock form if necessary (ie. residential singleton) or else pass in the formik form
  const { values, touched } = form
  const conditions = field[lookup]
  let permissions
  if (user?.permissions) {
    permissions = user.permissions
  } else if (this && this.props.user.permissions) {
    permissions = this.props.user.permissions
    user = this.props.user
  }
  if (field.permissions && edit) { // Users with no perms can still view the field
    if (permissions && !hasPermission(field.permissions, permissions)) { return false }
  }
  if (conditions) {
    if (Array.isArray(conditions)) {
      /*
       * Conditions is an array of rule objects. Each rule object can contain
       * multiple conditions. If all the conditions for a given rule are true,
       * then the response will be true, even if the additional rules are false.
       * Essentially: Rules are treated as OR statements, conditions are treated
       * as AND statements
      */
      const rules = conditions.map(rule => {
        const required = rule.length
        const passed = rule.map(condition => {
          let fname = condition.field
          const fcondition = condition.condition
          let fields, fkeys, modelname
          let valid_rule = false
          let val = getIn(values, fname, undefined)
          if (fcondition.key && 'aidx' in field && 'aname' in field && ![ 'settings', 'branch_settings', 'portals' ].includes(fcondition.type)) {
            fname = `${fcondition.key}.${field.aidx}.${fname}`
            val = getIn(values, fname, undefined)
          } else if (!val && 'aidx' in field && 'aname' in field && ![ 'settings', 'branch_settings', 'portals' ].includes(fcondition.type)) {
            fname = `${field.aname}.${field.aidx}.${fname}`
            val = getIn(values, fname, undefined)
          }
          const initialval = getIn(form, `initialValues.${fname}`, undefined)
          switch (fcondition.type) {
            case 'selected_field_equal': {
              const selected = getIn(user || this?.props.user, 'selected')
              const init = getIn(cache || this?.props.cache, `${selected[0]}.${fname}`)
              const equals = selected.map(v => {
                if (getIn(cache || this?.props.cache, `${v}.${fname}`) === init) { return true }
                return false
              })
              valid_rule = !equals.includes(false)
              break
            }
            case 'integration': {
              const integrations = getIn(user, 'agent.meta.integrations')
              if (integrations) {
                const integration = integrations.find(i => i.provider === fname)
                if (integration) {
                  if (fcondition.value === true) {
                    if (integration.enabled) { valid_rule = true }
                  } else if (fcondition.value === false) {
                    if (!integration.enabled) { valid_rule = true }
                  }
                } else { // No integration
                  // eslint-disable-next-line no-lonely-if
                  if (fcondition.value === false) { valid_rule = true }
                }
              } else { // No integration
                // eslint-disable-next-line no-lonely-if
                if (fcondition.value === false) { valid_rule = true }
              }
              break
            }
            case 'settings': {
              let site = getIn(this?.props.cache || cache, `settings.${getIn(user, 'agent.site.id')}`)
              if (!site && this && getIn(this.props, 'settings')) {
                site = getIn(this.props, 'settings')
              }
              if (site) {
                if (fcondition.value === true) {
                  if (site[fname]) { valid_rule = true }
                } else if (site[fname] === fcondition.value) {
                  valid_rule = true
                } else if (!fcondition.value && !site[fname]) { valid_rule = true }
              }
              break
            }
            case 'referral_branch_settings': {
              const branch = getIn(this?.props.cache || cache, `branches.${getIn(form.values, fcondition.key)}`)
              if (branch) {
                if (Array.isArray(branch[fname])) {
                  if (branch[fname].includes(user.agent.id) === fcondition.value) {
                    valid_rule = true // Now only applies to referrals managers and branch managers
                  }
                } else if (fcondition.value === true) {
                  if (branch[fname]) { valid_rule = true }
                } else if (branch[fname] === fcondition.value) {
                  valid_rule = true
                } else if (!fcondition.value && !branch[fname]) { valid_rule = true }
              }
              break
            }
            case 'portals': { // Check to see if specific portals are configured and active at global / site / branch level
              // First check to see if the portal is active on the model currently - if it is we must display
              if (this && this.props.model) {
                const portals = getIn(this.props.model, 'meta.portals')
                const portal = portals ? portals.find(p => p && getIn(p, 'meta.portal.name') === fcondition.value) : false
                if (portal && portal.active) {
                  valid_rule = true
                  break
                }
              }
              let site = getIn(this?.props.cache || cache, `settings.${getIn(user, 'agent.site.id')}`)
              if (!site && this && getIn(this.props, 'settings')) { site = getIn(this.props, 'settings') }
              if (site) {
                const global_portals = getIn(site, 'portals.global')
                const agency_portals = getIn(site, 'portals.agency')
                const branch_portals = getIn(site, 'portals.branch')
                const global_portal = global_portals && global_portals.find(gportal =>
                  gportal.name === fcondition.value && gportal.active
                )
                if (!global_portal) { break }
                const agency_portal = agency_portals && agency_portals.find(aportal =>
                  aportal.portal === global_portal.id && aportal.active
                )
                if (!agency_portal) { break }
                if (fname === 'site') {
                  valid_rule = true
                } else if (fname === 'branch') {
                  const branch_id = form ? getIn(form, 'values.branch') : getIn(this.props.model, 'branch')
                  if (branch_id) {
                    const branch_portal = branch_portals && branch_portals.find(bportal =>
                      branch_id === bportal.branch_id && bportal.portal === global_portal.id && bportal.active
                    )
                    if (branch_portal) { valid_rule = true }
                  }
                }
              }
              break
            }
            case 'model':
              if (!val && this && this.props.model) {
                val = this ? getIn(this.props.model, fname) : null
              }
              if (!val && fcondition.modelname && fname.startsWith('meta.')) {
                const cached_model = getIn(this?.props.cache || cache, `${fcondition.modelname}.${getIn(form.values, fname.split('.')[1])}`)
                val = this ? getIn({ meta: { [fname.split('.')[1]]: cached_model } }, fname) : null
              }
              if (val) {
                if (typeof fcondition.value === 'boolean') { // True / false match
                  if (fcondition.value && val) { valid_rule = true }
                  if (!fcondition.value && !val) { valid_rule = true }
                } else if (fcondition.value === val) { valid_rule = true } // Exact string match
              }
              break
            case 'meta':
              modelname = this ? this.props.config.fields.find(f => f.name === fname).modelname : null
              fields = fcondition.value
              fkeys = Object.keys(fields)
              if (this && !this.props.cache[modelname]) {
                valid_rule = false
                break
              }
              if (Array.isArray(val)) {
                const models = val.map(id => getIn(this?.props.cache || cache, `${modelname}.${id}`))
                if (models.length) {
                  while (fkeys.length) {
                    const f = fkeys.pop()
                    if (models.find(model => model && model[f] === fields[f])) {
                      valid_rule = true
                    }
                  }
                }
              } else {
                const model = getIn(this?.props.cache || cache, `${modelname}.${val}`)
                if (model) {
                  while (fkeys.length) {
                    const f = fkeys.pop()
                    if (model[field] === fields[f]) {
                      valid_rule = true
                    }
                  }
                }
              }
              break
            case 'permissions':
              if (hasPermission(
                fcondition.permissions,
                permissions,
                form ? form.values : false,
                user?.agent.id,
                fcondition.permission_key ? fcondition.permission_key : field.permission_key,
                fcondition.preferOwn
              )) { valid_rule = true }
              break
            case 'min':
              if (!val) { val = [] }
              if (Number.isInteger(val) && val >= fcondition.value) { valid_rule = true }
              if (val.length >= fcondition.value) { valid_rule = true }
              break
            case 'max':
              if (!val) { val = [] }
              if (Number.isInteger(val) && val <= fcondition.value) { valid_rule = true }
              if (val.length <= fcondition.value) { valid_rule = true }
              break
            case 'count':
              if (!val) { val = [] }
              if (Number.isInteger(val) && val === fcondition.value) { valid_rule = true }
              if (val.length === fcondition.value) { valid_rule = true }
              break
            case 'notcount':
              if (!val) { val = [] }
              if (val.length !== fcondition.value) { valid_rule = true }
              break
            case 'contains':
              if (Array.isArray(val)) {
                let vals
                if (fcondition.key) {
                  if (typeof field.aidx !== 'undefined' && val[field.aidx]) {// Field array value lookup by index
                    vals = Array(val[field.aidx][fcondition.key] === fcondition.value)
                  } else {
                    vals = val.map(v => fcondition.value === v[fcondition.key])
                  }
                } else {
                  vals = val.map(v => Array.isArray(fcondition.value) && fcondition.value.includes(v))
                }
                valid_rule = vals.some(v => v === true)
              } else {
                valid_rule = Array.isArray(fcondition.value) && fcondition.value.includes(val)
              }
              break
            case 'changed': { // Used for hiding or showing price reduced fields
              switch (fcondition.comparison) {
                case 'lt':
                  valid_rule = parseFloat(val) < parseFloat(initialval)
                  break
                case 'lte':
                  valid_rule = parseFloat(val) <= parseFloat(initialval)
                  break
                case 'gt':
                  valid_rule = parseFloat(val) > parseFloat(initialval)
                  break
                case 'gte':
                  valid_rule = parseFloat(val) >= parseFloat(initialval)
                  break
                case 'ne':
                  valid_rule = !isEqual(val, initialval)
                  break
                case 'eq':
                  valid_rule = isEqual(val, initialval)
                  break
                default:
                  break
              }
              if (!this) { valid_rule = true } // For modelview
              break
            }
            case 'exists':
              if (val) { valid_rule = true }
              break
            case 'notexists':
              if (!val) { valid_rule = true }
              break
            case 'initialvalue':
              if (initialval) {
                valid_rule = fcondition.value ? fcondition.value === initialval : true
              }
              break
            case 'touched':
              val = touched && touched[fname]
              if (val) { valid_rule = true }
              break
            case 'not':
              if (fcondition.value !== val) { valid_rule = true }
              break
            case 'isown': {
              let own = val === user?.agent.id
              if (Array.isArray(val)) {
                own = val.includes(user?.agent.id)
              }
              if (user?.permissions.includes('is_prop_data_user') && [ 'disabled', 'readonly' ].includes(lookup)) {
                own = !fcondition.value
              }
              if (fcondition.value === own) { valid_rule = true }
              break
            }
            case 'province':
              valid_rule = val ? fcondition.value === values._province : valid_rule
              break
            case 'suburb':
              valid_rule = val ? fcondition.value === values._suburb : valid_rule
              break
            case 'area':
              valid_rule = val ? fcondition.value === values._area : valid_rule
              break
            case 'country':
              valid_rule = val ? fcondition.value === values._country : valid_rule
              break
            default: // Default to value of the named condition field
              if (fcondition.value === val) { valid_rule = true }
              break
          }
          return valid_rule
        })
        // This gives us our AND condition
        return required === passed.filter(val => val === true).length
      })
      // This gives us our OR condition
      edit = rules.find(valid => valid === true) || false
    } else {
      edit = true
    }
  }
  return edit
}

export function composeOptionLabel(cache, value, optionlabel, labelseparator, optionvalue) {
  try {
    /* Used to compose the option label in all types of React Select components */
    let vals = []
    let options = []
    if (cache && Object.keys(cache).length && ![ null, undefined ].includes(value)) {
      // Pluck options from cache and compose array
      if (Array.isArray(value)) {
        if (optionvalue) {
          for (const v of value) {
            options = Array.isArray(cache) ? (
              cache.filter(c => c[optionvalue] === v)
            ) : (
              Object.keys(cache).map(k => cache[k]).filter(c => c[optionvalue] === v)
            )
          }
        } else {
          for (const v of value) {
            if (v in cache) {
              options.push(cache[v])
            }
          }
        }
      } else if (optionvalue) {
        options = Array.isArray(cache) ? (
          cache.filter(c => c[optionvalue] === value)
        ) : (
          Object.keys(cache).map(k => cache[k]).filter(c => c[optionvalue] === value)
        )
      } else if (value in cache) {
        options.push(cache[value])
      }

      // Loop over plucked options and construct option values and labels
      vals = options.map(o => {
        let label
        if (Array.isArray(optionlabel)) {
          label = optionlabel.map(l => o[l]).filter(p => p).join(labelseparator || ' ')
        } else {
          label = o[optionlabel]
        }
        return { value: optionvalue ? o[optionvalue] : o.id, label }
      })
    }
    if (vals.length === 1) {
      return vals[0]
    }
    return (vals.length || vals.value) ? vals : null
  } catch (e) {
    log.error(`Error composing options labels: ${e}`)
  }
  return value
}

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName ||
    WrappedComponent.name ||
    'Component'
}

/* Debounce a function until a certain period of inactivity has
* passed, then run the function. Used mostly for user inputs
* such as the Text, TextArea and TextNotes.
*/
export const debounce = (callback, delay) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => callback(...args), delay)
  }
}

export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

/* Throttle a function so that it only executes once per
* specified delay. Not used anywhere yet but is here as a
* possible future helper.
export function throttled(delay, fn) {
  let lastCall = 0;
  return function (...args) {
    const now = new Date.getTime()
    if (now - lastCall < delay) { return }
    lastCall = now
    return fn(...args)
  }
}
*/

export const componentToHex = c => {
  try {
    const hex = c.toString(16)
    return hex.length === 1 ? `0${hex}` : hex
  } catch (e) {
    return '00'
  }
}

export const rgbToHex = (rgb = '', convert = false) => {
  const rgba = rgb.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
  const r = parseInt(rgba[0], 10)
  const g = parseInt(rgba[1], 10)
  const b = parseInt(rgba[2], 10)
  if (convert) {
    return { r, g, b }
  }
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

export function hexToRgb(hex, convert = false) {
  const result = /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})$/i.exec(hex)
  if (result) {
    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)
    if (convert) {
      return { r, g, b }
    }
    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
  }
  return hex
}

export const getContrast = (color = '', colours = []) => {
  let current = ''
  if (color.indexOf('#') !== -1) {
    current = hexToRgb(color, true)
  } else {
    current = rgbToHex(color, true)
  }
  if (current) {
    current = (0.2126 * (current.r)) + (0.7152 * (current.g)) + (0.0722 * (current.b))
  }

  const ratios = colours.filter(c => c).map(c => {
    let value = ''
    if (c.indexOf('#') !== -1) {
      value = hexToRgb(c, true)
    } else {
      value = rgbToHex(c, true)
    }
    if (value) {
      return (0.2126 * (value.r)) + (0.7152 * (value.g)) + (0.0722 * (value.b))
    }
    return 0
  }).filter((c, cid) => {
    if (c !== current) {
      return true
    }
    delete colours[cid]
    return false
  }).filter(c => c)
  const highest = Math.max(ratios)
  const idx = ratios.indexOf(highest)
  return colours.filter(c => c)[idx]
}

export const getInvertedColor = (color = '', dark, light) => {
  const rgb = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
  let alpha = 1
  if (rgb.length === 4) {
    alpha = rgb.pop()
  }
  if (dark && dark.indexOf('#') === -1) {
    dark = rgbToHex(dark)
  }
  if (light && light.indexOf('#') === -1) {
    light = rgbToHex(light)
  }
  if (dark && light) {
    const inverted = invert.asRgbArray(rgb, { black: dark, white: light })
    inverted.push(alpha)
    return `rgba(${inverted.join(',')})`
  }
  return dark
}

export async function supportsWebp() {
  if (!window.createImageBitmap) { return false }

  const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA='
  const blob = await fetch(webpData).then(r => r.blob())
  return createImageBitmap(blob).then(() => true, () => false)
}

// /**
//  * getDefinitions
//  *
//  * Compares local configs to remote swagger definitions
//  * and updates the field from the service accordingly.
//  *
//  * WARNING: This should only be called as needed. (i.e. To add missing maxlen props)
//  * This funciton should not be used in production.
//  *
//  * @usage getDefinitions({
//  *   url: '/pdms-api/branches/swagger/?format=openapi',
//  *   modelname: 'branches',
//  *   msmodel: 'Branch',
//  *   localproperty: 'maxlen',
//  *   remoteproperty: 'maxLength'
//  * })
//  *
//  * @param {string}  url               The microservice swagger endpoint to request definittions from.
//  * @param {string}  modelname         The local modelname for the module you are checking
//  * @param {string}  msmodel           The remote model name on the Microservice
//  * @param {string}  fieldname         The field name to compare
//  * @param {string}  localproperty     The local field property to compare
//  * @param {string}  remoteproperty    The remote field property to compare
//  *
//  * @return {Array}  An array of fields with their updated props
//  */
// export const getDefinitions = async ({ url, modelname, msmodel, localproperty, remoteproperty }) => { // Keep this for when we need to compare remote services to local configs
//   const r = await request(url)
//   const swagger = JSON.parse(r)

//   if (swagger.definitions[msmodel]) {
//     const { properties } = swagger.definitions[msmodel]
//     const { fields } = allConfigs[modelname]
//     const modified = fields.map(field => {
//       if (properties[field] && properties[field][remoteproperty]) {
//         field[localproperty] = properties[field][remoteproperty]
//       }
//       return field
//     })
//     return modified
//   }
// }

export const uniqueArray = (array, prop, ignore_options = false) => {
  const result = []
  let map = new Map()
  for (const item of array) {
    let filter = prop
    // handle nested options
    if (filter && !getIn(item, filter) && !getIn(item, 'options') && !ignore_options) {
      filter = 'value'
    }
    if (typeof item === 'object' && prop && item.options && !ignore_options) {
      item.options = uniqueArray(item.options, prop)
      if (typeof item === 'object' && filter && !map.has(item[`group${item.label}`])) {
        map = map.set(`group${item.label}`, true)
        result.push(merge({}, item))
      }
    } else if (typeof item === 'object' && filter && !map.has(item[filter])) {
      map = map.set(item[filter], true) // set any value to Map
      result.push(merge({}, item)) // return a copy of the object
    } else if (typeof item !== 'object' && !map.has(item)) {
      map = map.set(item, true) // set any value to Map
      result.push(item)
    }
  }
  return result
}

export const getScrollParent = (element, includeHidden) => {
  let style = getComputedStyle(element)
  const excludeStaticParent = style.position === 'absolute'
  const overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/

  if (style.position === 'fixed') {
    return document.body
  }
  for (let parent = element; (parent = parent.parentElement);) {
    style = getComputedStyle(parent)
    if (excludeStaticParent && style.position === 'static') {
      continue
    }
    if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {return parent}
  }

  return document.body
}


export function getPaths(obj, prefix = []) {
  return obj ? Object.keys(obj).reduce((arr, key) => {
    const path = [ ...prefix, key ]
    const content = obj[key] && typeof obj[key] === 'object' ? getPaths(obj[key], path) : [ path ]
    return [ ...arr, ...content ]
  }, []) : []
}

export function getField(fields, key, parent, indexed) {
  let field
  fields.forEach(f => {
    if (f.name === key) {
      field = f
    }
    if (!field && f.fields) {
      field = getField(f.fields, key, f)
    }
  })
  if (field && parent) {
    field.parent = parent
  }
  if (field) {
    return field
  }
  if (!isNaN(key) && indexed) {
    return { label: parseInt(key, 10) + 1 }
  }
  return field
}

export function getFields(fields, key) {
  let found = []
  fields.forEach(f => {
    if (f.name === key) {
      found.push(f)
    }
    if (f.fields) {
      found = [ ...found, ...getFields(f.fields, key, f) ]
    }
  })
  return found
}

// Below is currently referenced for errors in ModelAdd, ModelEdit, ContactCreator and TagManager
// It handles the creator of the formik error and scrolling to the error
export const handleSubmitError = (e, actions = false, form = false) => {
  if (actions) { actions.setSubmitting(false) }
  try {
    let errors = {}
    const valid_paths = getPaths(e.raw).filter(path => getIn(e.raw, path))
    valid_paths.forEach(valid_path => {
      const field_path = [ ...valid_path ]
      // all errors should be arrays, we're not interested in the final key
      if (!isNaN(field_path[field_path.length - 1])) {
        field_path.pop()
      }
      errors = formikSetIn(errors, field_path.join('.'), getIn(e.raw, valid_path))
    })
    if (form) {
      let { touched } = form
      valid_paths.forEach(valid_path => {
        const field_path = [ ...valid_path ]
        // all errors should be arrays, we're not interested in the final key
        if (!isNaN(field_path[field_path.length - 1])) {
          field_path.pop()
        }
        touched = formikSetIn(touched, field_path.join('.'), true)
      })
      if (actions) { actions.setTouched(touched, false) }
      setTimeout(() => { // scroll to first error
        if (actions) { actions.setErrors(errors) } // apply the errors after the touched renders
        setTimeout(() => {
          let viewport = document.getElementsByClassName('view')[0]
          if (!viewport) {
            viewport = document.getElementsByClassName('wide-sidebar')[0] // Contact creator etc.
          }
          if (viewport) {
            const firstError = viewport.getElementsByClassName('error')[0] ? viewport.getElementsByClassName('error')[0].closest('.field') : false
            if (firstError) {
              const parent = getScrollParent(firstError)
              const box = firstError.getBoundingClientRect()
              const windowTop = parent.scrollTop + (box.top) - 155
              parent.scrollTo(0, windowTop)
            }
          }
        }, 300)
      }, 300)
    }
  } catch (f) {
    log.error(e)
    log.error(f)
  }
  if (actions) { actions.setStatus({ type: 'error', msg: 'Error' }) }
}

// Resolves a field error from a model
export const resolveError = (error, fields) => {
  try {
    if (fields) {
      // If there is an error string - we always want an object
      if (error.error && typeof error.error === 'string' && error.status !== 400) {
        if (error.status === 500) { return 'Server error' }
        if (error.status === 502) { return 'Bad gateway' }
        if (error.status === 504) { return 'Gateway timeout' }
        if (error.status === 503) { return 'Service unavailable' }
        if (error.status === 404) { return 'Not found' }
        return error.error
      }
      const valid_path = getPaths(error.raw).find(path => getIn(error.raw, path))
      const field_path = [ ...valid_path ]
      // all errors should be arrays, we're not interested in the final key
      if (!isNaN(field_path[field_path.length - 1])) {
        field_path.pop()
      }
      // get the list of fields that are part of this error
      const valid_fields = field_path.map(path => getField(fields, path, null, true)).filter(x => x)

      // return the error labels for the given path
      if (valid_fields.length && valid_path.length) {
        return `${valid_fields.map(f => f.label).join(' | ')}: ${getIn(error.raw, valid_path)}`
      } else if (valid_path.length && (valid_path.includes('detail') || valid_path.includes('non_field_errors'))) {
        return getIn(error.raw, valid_path)
      } else if (field_path.length && valid_path.length) {
        return `${field_path.map(f => title(f.replace(/[-_]+/gi, ' '))).join(' | ')}: ${getIn(error.raw, valid_path)}`
      }
      return 'Unresolvable error'
    }
    return 'Unresolvable error'
  } catch (e) {
    log.error(e)
    return false
  }
}

export const generateWebsiteLink = (model, modelname, settings) => // Use model data to generate website link if not present in meta
  new Promise((resolve, reject) => {
    const listing_type = slugify(model.listing_type)
    const property_type = slugify(model.property_type)
    let link = ''
    let eos3_portal = false
    const custom_template_field = `${modelname}_url_template`
    if (settings.get(custom_template_field)) {
      link = settings.get(custom_template_field)
      const vars = link.split(/{{(\w+|_)}}/)
      let unique_vars = [ ...new Set(vars) ]
      unique_vars = unique_vars.filter(m => m) // Remove empty vars
      unique_vars.forEach(v => {
        const key = v.replace(/({{|}})/g, '')
        const val = interpolateURLData(key, model)
        const re = new RegExp(`{{${v}}}`, 'g')
        link = link.replace(re, val)
      })
      resolve(link)
    } else if (model.location && !(model.meta && model.meta.location)) {
      db.suburbs.get({ id: model.location }).then(suburb => {
        if (!suburb) {
          reject('Suburb not found')
          return
        }
        db.areas.get({ id: suburb.area_id }).then(area => {
          if (!area) {
            reject('Area not found')
            return
          }
          if (settings.get('is_eos3') && model.meta.portals) { // Site is EOS3, return EOS3 portal config reference
            eos3_portal = model.meta.portals.filter(p => p).find(p => p.portal === 16) // eos3 portal is always 16
            if (eos3_portal && eos3_portal.reference) {
              if (model.model !== 'project') {
                link = `/results/${modelname}/${listing_type}/${area.area_slug}/${suburb.suburb_slug}/${property_type}/${eos3_portal.reference}/`
              } else {
                let subtype = 'estate'
                if (listing_type.indexOf('development') !== -1) {
                  subtype = 'new-development'
                }
                let type_subtype = 'residential'
                if (listing_type.indexOf('commercial') !== -1) {
                  type_subtype = 'commercial'
                }
                link = `/results/${subtype}/${type_subtype}/${area.area_slug}/${suburb.suburb_slug}/${eos3_portal.reference}/`
              }
            } else {
              link = false
            }
          } else if (model.model !== 'project') {
            link = `/results/${modelname}/${listing_type}/${area.area_slug}/${suburb.suburb_slug}/${property_type}/${model.id}/`
          } else {
            let subtype = 'estate'
            if (listing_type.indexOf('development') !== -1) {
              subtype = 'new-development'
            }
            let type_subtype = 'residential'
            if (listing_type.indexOf('commercial') !== -1) {
              type_subtype = 'commercial'
            }
            link = `/results/${subtype}/${type_subtype}/${area.area_slug}/${suburb.suburb_slug}/${model.id}/`
          }
          resolve(link)
        }).catch(e => {
          reject(e)
        })
      }).catch(e => {
        reject(e)
      })
    } else if (model.meta.location) {
      if (settings.get('is_eos3') && model.meta.portals) { // Site is EOS3, return EOS3 portal config reference
        eos3_portal = model.meta.portals.filter(p => p).find(p => p.portal === 16) // eos3 portal is always 16
        if (eos3_portal && eos3_portal.reference) {
          if (model.model !== 'project') {
            link = `/results/${modelname}/${listing_type}/${model.meta.location.area_slug}/${model.meta.location.suburb_slug}/${property_type}/${eos3_portal.reference}/`
          } else {
            let subtype = 'estate'
            if (listing_type.indexOf('development') !== -1) {
              subtype = 'new-development'
            }
            let type_subtype = 'residential'
            if (listing_type.indexOf('commercial') !== -1) {
              type_subtype = 'commercial'
            }
            link = `/results/${subtype}/${type_subtype}/${model.meta.location.area_slug}/${model.meta.location.suburb_slug}/${model.id}/`
          }
        } else {
          link = false
        }
      } else if (model.model !== 'project') {
        link = `/results/${modelname}/${listing_type}/${model.meta.location.area_slug}/${model.meta.location.suburb_slug}/${property_type}/${model.id}/`
      } else {
        let subtype = 'estate'
        if (listing_type.indexOf('development') !== -1) {
          subtype = 'new-development'
        }
        let type_subtype = 'residential'
        if (listing_type.indexOf('commercial') !== -1) {
          type_subtype = 'commercial'
        }
        link = `/results/${subtype}/${type_subtype}/${model.meta.location.area_slug}/${model.meta.location.suburb_slug}/${model.id}/`
      }
    }
    resolve(link)
  })


export const menuPlacement = function(place) {
  const old_place = this.placement
  if (place.target) { return }
  if (old_place !== place) {
    this.placement = place
  }
}

export const calculatePlacement = function(el) {
  const upperLimit = 300
  const lowerLimit = 180
  const list = el.querySelector('.react-select__menu-list')
  if (list) {
    list.style.maxHeight = '300px'
  }
  const bottom = el.getBoundingClientRect().bottom
  const top = el.getBoundingClientRect().top
  const body = document.body
  const wrapper = document.getElementById('wrapper')
  const content = document.querySelector('#wrapper > .content')
  const html = document.documentElement
  let height = Math.min(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
  if (content) {
    height = content.getBoundingClientRect().bottom
  }
  const boundry = height - bottom - 300
  if (wrapper && wrapper.classList.contains('touch')) {
    return 'auto'
  }
  if (boundry < upperLimit) { // Check for resize
    if (boundry < lowerLimit) { // Too small to resize, place on bottom
      if (list) {
        const newHeight = top - 120
        list.style.maxHeight = `${Math.min(newHeight, 300)}px`
      }
      return 'top'
    } // Resize list element
    return 'auto'
  }
  return 'auto'
}

export const overwriteMerge = (destinationArray, sourceArray) => sourceArray

export const mergeByProperty = (target, source, prop) => {
  if (!Array.isArray(prop)) { prop = [ prop ] }
  source.forEach(sourceElement => {
    const newElement = prop.map(p => target.find(targetElement => isEqual(sourceElement[p], targetElement[p])))
    if (newElement.some(e => !e)) {
      target.push(sourceElement)
    } else {
      Object.assign(newElement.find(n => n), sourceElement)
    }
  })
  return target
}

export const combineIdMerge = (destinationArray, sourceArray) => mergeByProperty(destinationArray, sourceArray, 'id')

export const isInViewport = (element, offset) => {
  const rect = element.getBoundingClientRect()
  const parent = element.offsetParent || document.documentElement
  const parent_rect = parent.getBoundingClientRect()
  const inview = (
    rect.top >= parent_rect.top &&
    rect.left >= parent_rect.left + offset &&
    rect.bottom <= parent_rect.bottom &&
    rect.right <= parent_rect.right - offset
  )
  return inview
}

export function loadLocations(csvfile) {
  return new Promise((resolve, reject) => request(csvfile).then(response =>
    Papa.parse(response.body, {
      // download: true,
      fastMode: true,
      header: true,
      dynamicTyping: true,
      error: e => reject(e),
      complete: async parsed => {
        let suburbs = [ ...parsed.data ]
        if (suburbs && suburbs.length) {
          suburbs = suburbs.map(s => {
            s.suburb = s.suburb.toString()
            s.suburb_slug = s.suburb_slug.toString()
            s.area_suburb = `${s.area}, ${s.suburb}`
            return s
          })

          const areas = groupBy(suburbs, 'area_id')
          const area_names = Object.keys(areas).map(aid => {
            const {
              // eslint-disable-next-line no-unused-vars
              suburb,
              // eslint-disable-next-line no-unused-vars
              suburb_slug,
              // eslint-disable-next-line no-unused-vars
              id,
              // eslint-disable-next-line no-unused-vars
              property_24_id,
              // eslint-disable-next-line no-unused-vars
              postal_code,
              // eslint-disable-next-line no-unused-vars
              area_id,
              ...props
            } = areas[aid][0]
            return { id: parseInt(aid, 10), ...props }
          })

          const provinces = groupBy(suburbs, 'province_id')
          const province_names = Object.keys(provinces).map(pid => {
            const {
            // eslint-disable-next-line no-unused-vars
              suburb,
              // eslint-disable-next-line no-unused-vars
              id,
              // eslint-disable-next-line no-unused-vars
              property_24_id,
              // eslint-disable-next-line no-unused-vars
              postal_code,
              // eslint-disable-next-line no-unused-vars
              area_id,
              // eslint-disable-next-line no-unused-vars
              area,
              // eslint-disable-next-line no-unused-vars
              area_slug,
              // eslint-disable-next-line no-unused-vars
              province_id,
              ...props
            } = provinces[pid][0]
            return { id: parseInt(pid, 10), ...props }
          })

          const countries = groupBy(suburbs, 'country_id')
          const country_names = Object.keys(countries).map(cid => (
            { id: parseInt(cid, 10), country: countries[cid][0].country })
          )

          try {
            await db.transaction('rw', db.suburbs, db.areas, db.provinces, db.countries, async () => {
              await db.suburbs.bulkPut(suburbs)

              await db.areas.bulkPut(area_names)

              await db.provinces.bulkPut(province_names)

              await db.countries.bulkPut(country_names)
            }).catch(e => reject(e))// Dexie Transaction
          } catch (e) {
            return reject(e)
          }
        } else {
          reject('no suburbs')
        }
        return resolve(process.env.REACT_APP_TEST)
      }
    }) // Papa
  ))
}

export function textToDate(text, previous = false) {
  if (!text) { return null }
  const curr = new Date() // get current date
  let value
  switch (text) {
    case 'YESTERDAY':
      value = {
        start: startOfYesterday(),
        end: endOfYesterday()
      }
      value.days = 1
      break
    case 'TOMORROW':
      value = {
        start: startOfTomorrow(),
        end: endOfTomorrow()
      }
      value.days = 1
      break
    case 'THIS_WEEK': {
      value = {
        start: startOfWeek(new Date()),
        end: endOfWeek(new Date())
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'THIS_WEEK_SO_FAR': {
      value = {
        start: startOfWeek(new Date()),
        end: new Date()
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'LAST_WEEK': {
      const first = sub(curr, { weeks: 1 })
      value = {
        start: startOfWeek(first),
        end: endOfWeek(first)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'NEXT_WEEK': {
      const first = add(curr, { weeks: 1 })
      value = {
        start: startOfWeek(first),
        end: endOfWeek(first)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'THIS_MONTH': {
      value = {
        start: startOfMonth(curr),
        end: endOfMonth(curr)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'THIS_MONTH_SO_FAR': {
      value = {
        start: startOfMonth(curr),
        end: curr
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'LAST_MONTH': {
      const first = new Date(curr.getFullYear(), curr.getMonth() - 1, 1)
      value = {
        start: startOfMonth(first),
        end: endOfMonth(first)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'NEXT_MONTH': {
      const first = add(curr, { months: 1 })
      value = {
        start: startOfMonth(first),
        end: endOfMonth(first)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'THIS_QUARTER': {
      const quarters = [ [ 0, 1, 2 ], [ 3, 4, 5 ], [ 6, 7, 8 ], [ 9, 10, 11 ] ]
      const this_quarter = quarters.find(quarter => quarter.includes(curr.getMonth()))
      const first = new Date(curr.getFullYear(), this_quarter[0], 1)
      const last = new Date(curr.getFullYear(), this_quarter[2] + 1, 0)
      value = {
        start: startOfMonth(first),
        end: endOfMonth(last)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'THIS_FISCAL_QUARTER': {
      const quarters = [ [ 2, 3, 4 ], [ 5, 6, 7 ], [ 8, 9, 10 ], [ 11, 0, 1 ] ]
      let this_quarter = quarters.find(quarter => quarter.includes(curr.getMonth()))
      if (this_quarter.includes(0) || this_quarter.includes(1)) {
        this_quarter = [ -1, 0, 1 ]
      }
      const first = new Date(curr.getFullYear(), this_quarter[0], 1)
      const last = new Date(curr.getFullYear(), this_quarter[2] + 1, 0)
      value = {
        start: startOfMonth(first),
        end: endOfMonth(last)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'THIS_QUARTER_SO_FAR': {
      const quarters = [ [ 0, 1, 2 ], [ 3, 4, 5 ], [ 6, 7, 8 ], [ 9, 10, 11 ] ]
      const this_quarter = quarters.find(quarter => quarter.includes(curr.getMonth()))
      const first = new Date(curr.getFullYear(), this_quarter[0], 1)
      value = {
        start: startOfMonth(first),
        end: curr
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'THIS_FISCAL_QUARTER_SO_FAR': {
      const quarters = [ [ 2, 3, 4 ], [ 5, 6, 7 ], [ 8, 9, 10 ], [ 11, 0, 1 ] ]
      let this_quarter = quarters.find(quarter => quarter.includes(curr.getMonth()))
      if (this_quarter.includes(0) || this_quarter.includes(1)) {
        this_quarter = [ -1, 0, 1 ]
      }
      const first = new Date(curr.getFullYear(), this_quarter[0], 1)
      value = {
        start: startOfMonth(first),
        end: curr
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'LAST_QUARTER': {
      const quarters = [ [ 0, 1, 2 ], [ 3, 4, 5 ], [ 6, 7, 8 ], [ 9, 10, 11 ] ]
      const this_quarter = quarters.find(quarter => quarter.includes(curr.getMonth()))
      const first = new Date(curr.getFullYear(), this_quarter[0] - 3, 1)
      const last = new Date(curr.getFullYear(), this_quarter[0] - 1, 0)
      value = {
        start: startOfMonth(first),
        end: endOfMonth(last)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'LAST_FISCAL_QUARTER': {
      const quarters = [ [ 2, 3, 4 ], [ 5, 6, 7 ], [ 8, 9, 10 ], [ 11, 0, 1 ] ]
      let this_quarter = quarters.find(quarter => quarter.includes(curr.getMonth()))
      if (this_quarter.includes(0) || this_quarter.includes(1)) {
        this_quarter = [ -1, 0, 1 ]
      }
      const first = new Date(curr.getFullYear(), this_quarter[0] - 3, 1)
      const last = new Date(curr.getFullYear(), this_quarter[0] - 1, 0)
      value = {
        start: startOfMonth(first),
        end: endOfMonth(last)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'NEXT_QUARTER': {
      const quarters = [ [ 0, 1, 2 ], [ 3, 4, 5 ], [ 6, 7, 8 ], [ 9, 10, 11 ] ]
      const this_quarter = quarters.find(quarter => quarter.includes(curr.getMonth()))
      const first = new Date(curr.getFullYear(), this_quarter[0] + 3, 1)
      const last = new Date(curr.getFullYear(), this_quarter[2] + 3, 0)
      value = {
        start: startOfMonth(first),
        end: endOfMonth(last)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'NEXT_FISCAL_QUARTER': {
      const quarters = [ [ 2, 3, 4 ], [ 5, 6, 7 ], [ 8, 9, 10 ], [ 11, 0, 1 ] ]
      let this_quarter = quarters.find(quarter => quarter.includes(curr.getMonth()))
      if (this_quarter.includes(0) || this_quarter.includes(1)) {
        this_quarter = [ 11, 12, 13 ]
      }
      const first = new Date(curr.getFullYear(), this_quarter[0] + 3, 1)
      const last = new Date(curr.getFullYear(), this_quarter[2] + 3, 0)
      value = {
        start: startOfMonth(first),
        end: endOfMonth(last)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'THIS_YEAR': {
      value = {
        start: startOfYear(curr),
        end: endOfYear(curr)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'THIS_FISCAL_YEAR': {
      let year = curr.getFullYear()
      if ([ 0, 1 ].includes(curr.getMonth())) {
        year -= 1
      }
      const first = new Date(year, 2, 1)
      const last = new Date(year + 1, 2, 0)
      value = {
        start: startOfMonth(first),
        end: endOfMonth(last)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'THIS_YEAR_SO_FAR': {
      value = {
        start: startOfYear(curr),
        end: curr
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'THIS_FISCAL_YEAR_SO_FAR': {
      let year = curr.getFullYear()
      if ([ 0, 1 ].includes(curr.getMonth())) {
        year -= 1
      }
      const first = new Date(year, 2, 1)
      value = {
        start: startOfMonth(first),
        end: curr
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'LAST_YEAR': {
      const first = new Date(curr.getFullYear() - 1, 0, 1)
      value = {
        start: startOfYear(first),
        end: endOfYear(first)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'LAST_FISCAL_YEAR': {
      let year = curr.getFullYear()
      if ([ 0, 1 ].includes(curr.getMonth())) {
        year -= 1
      }
      const first = new Date(year - 1, 2, 1)
      const last = new Date(year, 2, 0)
      value = {
        start: startOfMonth(first),
        end: endOfMonth(last)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'NEXT_YEAR': {
      const first = new Date(curr.getFullYear() + 1, 0, 1)
      value = {
        start: startOfYear(first),
        end: endOfYear(first)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'NEXT_FISCAL_YEAR': {
      let year = curr.getFullYear()
      if ([ 0, 1 ].includes(curr.getMonth())) {
        year += 1
      }
      const first = new Date(year + 1, 2, 1)
      const last = new Date(year + 2, 2, 0)
      value = {
        start: startOfMonth(first),
        end: endOfMonth(last)
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    }
    case 'NEXT_7_DAYS':
      value = {
        start: curr,
        end: add(curr, { days: 7 })
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    case 'NEXT_14_DAYS':
      value = {
        start: curr,
        end: add(curr, { days: 14 })
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    case 'NEXT_30_DAYS':
      value = {
        start: curr,
        end: add(curr, { days: 30 })
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    case 'NEXT_60_DAYS':
      value = {
        start: curr,
        end: add(curr, { days: 60 })
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    case 'NEXT_90_DAYS':
      value = {
        start: curr,
        end: add(curr, { days: 90 })
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    case 'NEXT_365_DAYS':
      value = {
        start: curr,
        end: add(curr, { days: 365 })
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    case 'LAST_7_DAYS':
      value = {
        start: sub(curr, { days: 7 }),
        end: curr
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    case 'LAST_14_DAYS':
      value = {
        start: sub(curr, { days: 14 }),
        end: curr
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    case 'LAST_30_DAYS':
      value = {
        start: sub(curr, { days: 30 }),
        end: curr
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    case 'LAST_60_DAYS':
      value = {
        start: sub(curr, { days: 60 }),
        end: curr
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    case 'LAST_90_DAYS':
      value = {
        start: sub(curr, { days: 90 }),
        end: curr
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    case 'LAST_365_DAYS':
      value = {
        start: sub(curr, { days: 365 }),
        end: curr
      }
      value.days = differenceInCalendarDays(value.end, value.start)
      break
    default: // TODAY
      value = {
        start: startOfDay(curr),
        end: curr
      }
      value.days = 1
      break
  }
  if (previous) {
    const { start, end } = value
    const diff = start - end
    value = {
      start: new Date(start.getTime() + diff),
      end: new Date(end.getTime() + diff)
    }
  }
  return value
}

const canvas = document.createElement('canvas')
const context = canvas.getContext('2d')
export function getTextWidth(text, font) {
  context.font = font || `${getComputedStyle(document.body).fontSize} ${getComputedStyle(document.body).fontFamily}`
  return context.measureText(text).width
}

export function useCustomCompareMemo(value) {
  const ref = useRef(value)

  if (!isEqual(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}
