import classNames from 'classnames'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'
import { NavLink } from 'react-router-dom'
import { getIn, FieldArray, Field, ErrorMessage } from 'formik'

import QueryBuilder from '../../QueryBuilder'
import { Button } from '../../../ui/Button'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import { history } from '../../../../store'
import ContactCreatorSidebar from '../../../../containers/ContactCreatorSidebar'
import ContactSearchSidebar from '../../../../containers/ContactSearchSidebar'
import { valueFormat, convertArrayToObject, hasPermission } from '../../../../utils'


class ContactLookup extends React.Component {
  /* This component is at the moment only used for showing conflicting
  * serviced locations in the branch model. It can be extended in the future
  * if the developer finds a use case, however for now this is the single
  * place it is used.
  */
  constructor(props) {
    super(props)

    let value = props.field.value
    if (props.multi && !value) {
      value = [ null ]
    }

    this.state = {
      term: '',
      searching: false,
      contacts: [ null ],
      selected: [],
      read_only: [],
      contact_id: null,
      autofill: {},
      suffix: '',
      value,
      init: false
    }

    this.lookupContact = this.lookupContact.bind(this)
    this.selectContact = this.selectContact.bind(this)
    this.unlinkContact = this.unlinkContact.bind(this)
    this.toggleCreator = this.toggleCreator.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)
    this.checkAutoFill = this.checkAutoFill.bind(this)
    this.AbortController = new AbortController()
    this._is_mounted = true
  }

  componentDidMount() {
    const { field, multi, fetchMany } = this.props
    const query = new QueryBuilder('?trigram_fields=first_name,last_name,email&status_in=Active,No-Consent,Non-Compliant')
    const queryparams = query.getAllArgs()
    if (field.value) {
      queryparams.id__in = multi ? field.value : [ field.value ]
      queryparams.id__in = queryparams.id__in.filter(v => ![ null, undefined ].includes(v))
      queryparams.meta_fields = [ 'branch' ]
      if (queryparams.id__in.length) {
        const values = {
          modelname: 'contacts',
          conflicts: true,
          params: queryparams,
          signal: this.AbortController.signal
        }
        new Promise((resolve, reject) => {
          fetchMany({ values, noloader: true, resolve, reject })
          this.setState({ searching: true })
        }).then(() => {
          if (this._is_mounted) {
            this.setState({ searching: false })
          }
        }).catch(e => {
          if (e.status !== 408) { console.error(e) }
          if (this._is_mounted) {
            this.setState({ searching: false })
          }
        })
      }
    } else {
      this.setState({ searching: false })
    }
    this.checkAutoFill()
  }

  componentDidUpdate(prevProps, prevState) {
    const { cache, field, form, multi, model, deedsfield, fetchMany } = this.props
    const { deeds_owner } = form.values
    this.root = document.getElementById('wrapper')
    if ((field.value && !isEqual(prevProps.field.value, field.value)) ||
      (cache && !isEqual(prevProps.cache, cache) ||
      (field.value && cache[field.value]))) {
      if (multi) {
        let val = Array.isArray(field.value) ? field.value : [ field.value ]
        val = val.filter(cid => Object.keys(cache).includes(String(cid))).map(cid => cache[cid])
        if (val.length && !isEqual(this.state.selected, val)) {
          this.setState({ selected: val.filter(s => s) })
        }
      } else if (cache[field.value] && !isEqual(this.state.selected, [ cache[field.value] ])) {
        this.setState({ selected: [ cache[field.value] ].filter(s => s) })
      }
      if (field.value && !this.state.searching) {
        const query = new QueryBuilder('?trigram_fields=first_name,last_name,email&status_in=Active,No-Consent,Non-Compliant')
        const queryparams = query.getAllArgs()
        let value = multi ? field.value : [ field.value ]
        const cached_values = cache ? Object.keys(cache).map(v => parseInt(v, 10)) : []
        value = value.filter(v => ![ null, undefined ].includes(v) && !cached_values.includes(parseInt(v, 10)))
        queryparams.id__in = value
        queryparams.meta_fields = [ 'branch' ]
        if (queryparams.id__in.length) {
          const values = {
            modelname: 'contacts',
            conflicts: true,
            params: queryparams,
            signal: this.AbortController.signal
          }
          new Promise((resolve, reject) => {
            fetchMany({ values, noloader: true, resolve, reject })
            this.setState({ searching: true })
          }).then(() => {
            if (this._is_mounted) {
              this.setState({ searching: false })
            }
          }).catch(e => {
            if (e.status !== 408) { console.error(e) }
            if (this._is_mounted) {
              this.setState({ searching: false })
            }
          })
        }
      }
    }

    let meta
    if (model && getIn(model.meta, field.name)) {
      if (multi) {
        meta = convertArrayToObject(model.meta[field.name].filter(c => c), 'id')
      } else {
        meta = model.meta[field.name]
      }
    }
    if (!this.state.selected.length && field.value && cache && !this.state.searching) {
      if (multi) {
        const val = Array.isArray(field.value) ? field.value : [ field.value ]
        if (val.length) {
          const selected = val.filter(cid => cid).map(cid => cache[cid] || cid)
          if (!isEqual(selected, this.state.selected)) {
            this.setState({ selected })
          }
        }
      } else if (cache[field.value]) {
        const selected = [ cache[field.value] ].filter(s => s)
        if (!isEqual(selected, this.state.selected)) {
          this.setState({ selected })
        }
      }
    }

    if ((!this.state.read_only.length || !isEqual(prevState.selected, this.props.selected)) && meta) {
      let value
      if (multi) {
        const val = Array.isArray(field.value) ? field.value : [ field.value ]
        if (val.length) {
          value = val.filter(cid => cid).map(cid => meta[cid])
        }
      } else if (meta) {
        value = [ meta ]
      }
      value = value ? value.filter(v => v && !cache[v.id]) : []
      if (!isEqual(this.state.read_only, value)) {
        this.setState({ read_only: value })
        if (value.length) {
          this.props.setIgnoreFields(field.name)
        } else { // Remove cause it's now in cache
          this.props.setIgnoreFields(field.name, true)
        }
      }
    }
    if (prevProps.form.values.deeds_owner !== deeds_owner && deedsfield) {
      this.setState({ term: deeds_owner })
    }
    if (this.state.value !== undefined && !isEqual(this.state.value, form.values[field.name])) {
      this.setState({ value: form.values[field.name] })
    }
    this.checkAutoFill()
  }

  componentWillUnmount() {
    this._is_mounted = false
    this.AbortController.abort()
  }

  checkAutoFill() {
    const { form, field, deedsfield } = this.props
    let contact_types = false
    let suffix = this.props.suffix || 'Contact'
    const selected_contact = this.state.selected.length ? this.state.selected.find(
      c => c.id === this.state.contact_id
    ) : null
    const params = { contact_id: this.state.selected.length ? this.state.selected[0].id : null }
    switch (field.name) {
      case 'owners':
        if (form && form.values) {
          if (form.values.listing_type === 'For Sale') {
            contact_types = [ 'Seller' ]
            suffix = 'Seller'
          }
          if (form.values.listing_type === 'To Let') {
            contact_types = [ 'Landlord' ]
            suffix = 'Landlord'
          }
        }
        break
      case 'tenant':
        contact_types = [ 'Tenant' ]
        suffix = 'Tenant'
        break
      case 'contact':
        contact_types = [ 'Contact' ]
        suffix = 'Contact'
        if (this.state.selected.length && this.state.contact_id && !isEqual(params.contact_id, this.state.contact_id)) {
          params.contact_id = this.state.selected[0].id
          const search = new URLSearchParams(params).toString()
          history.push(`${history.location.pathname}?${search}`)
          this.setState({ contact_id: params.contact_id })
        }
        break
      default:
        contact_types = false
    }
    let autofill = {
      contact_types
    }
    if (form && form.values) {
      if (form.values.agent) {
        autofill.introduction_agent = form.values.agent
      }
      if (form.values.branch) {
        autofill.branch = form.values.branch
      }
      if (form.values.sold_status_date && form.values.status === 'Sold') {
        autofill.house_anniversary = form.values.sold_status_date
      }
      autofill.associated_agents = [ 'agent_2', 'agent_3', 'agent_4' ].map(f => {
        if (Object.keys(form.values).includes(f)) {
          return form.values[f]
        }
        return null
      }).filter(f => f)
      if (form.values.deeds_owner && form.values.deeds_id && field.name === deedsfield) {
        if (form.values.deeds_id.indexOf('/') !== -1) {
          autofill.entity_name = form.values.deeds_owner
          autofill.term = autofill.entity_name
          autofill.entity_number = form.values.deeds_id
          autofill.entity_type = 'Closed Corporation'
        } else {
          autofill.first_name = form.values.deeds_owner
          autofill.term = autofill.first_name
          autofill.id_number = form.values.deeds_id
          autofill.entity_type = 'Natural Person'
        }
      }
      if (form.values.contact_lookup_autofill) {
        Object.keys(form.values.contact_lookup_autofill).forEach(k => {
          autofill[k] = form.values.contact_lookup_autofill[k]
        })
      }
    }

    if (selected_contact) {
      autofill = { ...selected_contact }
      // Need to add contained fields below
      if (selected_contact.meta.consent) {
        autofill.legal_basis = selected_contact.meta.consent.legal_basis
      } else {
        autofill.legal_basis = 'Legitimate interest - other'
      }
    }
    const contactParams = new URLSearchParams(window.location.search)
    if (contactParams.get('contact_id')) {
      autofill.contact = Number.parseInt(contactParams.get('contact_id'), 10)
      if (this.state.selected.length) {
        autofill.term = this.state.selected[0].email
      }
    }
    if (!isEqual(autofill, this.state.autofill) && this._is_mounted) {
      this.setState({ autofill })
    }
    if (!isEqual(suffix, this.state.suffix) && this._is_mounted) {
      this.setState({ suffix })
    }
  }

  lookupContact() {
    const { fetchMany } = this.props
    const query = new QueryBuilder('?trigram_fields=first_name,last_name,email&status_in=Active,No-Consent,Non-Compliant')
    const queryparams = query.getAllArgs()
    queryparams.meta_fields = [ 'branch' ]
    const values = {
      modelname: 'contacts',
      conflicts: true,
      term: this.el.value,
      params: queryparams,
      signal: this.AbortController.signal
    }
    new Promise((resolve, reject) => {
      fetchMany({ values, resolve, reject })
      this.setState({ searching: true, contacts: [] })
    }).then(r => {
      if (this._is_mounted) {
        this.setState({ searching: false, contacts: r })
      }
    }).catch(e => {
      if (e.status !== 408) { console.error(e) }
      if (this._is_mounted) {
        this.setState({ searching: false })
      }
    })
  }

  unlinkContact(cid) {
    const { field, multi, form } = this.props
    let val = field.value
    let { selected } = this.state
    if (multi) {
      val = val.filter(c => c !== cid)
      if (!val.length) {
        val = [ null ]
      }
    } else {
      val = null
    }
    selected = selected.filter(c => getIn(c, 'id', c) !== cid)
    this.setState({ selected, contacts: [] }, () => {
      form.setFieldValue(field.name, val).then(() => {
        form.setFieldTouched(field.name)
      })
    })
  }

  selectContact(idx, contact) {
    const { field, multi, cache, form } = this.props
    let val = field.value
    if (!val) { val = multi ? [] : null }
    const { selected } = this.state
    if (typeof contact === 'number') {
      contact = { ...cache[contact] }
    }
    if (multi) {
      if (field.value && field.value.includes(contact.id)) { return }
      val[idx] = contact.id
    } else { // Non-multi ie. spouse
      if (val === contact.id) { return }
      val = contact.id
    }
    selected[idx] = contact
    this.setState({ selected, contacts: [], term: '' }, () => {
      form.setFieldValue(field.name, val).then(() => {
        form.setFieldTouched(field.name)
      })
    })
  }

  toggleCreator() {
    this.props.toggleWideSidebar(`show-contact-creator-${this.props.field.name}`)
  }

  toggleSearch() {
    this.props.toggleWideSidebar(`show-contact-search-${this.props.field.name}`)
  }

  toggleEdit(contact_id) {
    this.props.toggleWideSidebar(`show-contact-edit-${this.props.field.name}`)
    this.setState(() => ({ contact_id, suffix: 'Edit' }))
  }

  render() {
    const { id, multi, settings, field, form, error, user, ...props } = this.props
    const { name, value } = field
    const no_add = (multi && value) ? value.includes(null) : false
    let contact = (!multi && value) ? this.state.selected.find(c => c.id === parseInt(value, 10)) : null
    let spouse
    if (contact) {
      spouse = getIn(contact, 'meta.spouse')
      spouse = `${getIn(spouse, 'first_name', '')} ${getIn(spouse, 'last_name', '')}`.trim()
    }
    let read_only = false
    const redact = [ 'ae' ].includes(settings.region)
    if (!contact) {
      contact = this.state.read_only.find(c => c.id === value)
      read_only = true
    }
    return (
      <div id={id} className={`form-group contact-lookup ${props.classes ? props.classes : ''}`}>
        {multi ? (
          <FieldArray
            name={name}
            // eslint-disable-next-line no-unused-vars
            render={({ push, remove }) => (
              <div className="input-group">
                {value && Array.isArray(value) && value.map((f, idx) => {
                  let currentContact = this.state.selected.find(c => c.id === f)
                  let currentReadOnly = false
                  if (!currentContact) {
                    currentContact = this.state.read_only.find(c => c.id === f)
                    currentReadOnly = true
                  }
                  let currentSpouse = getIn(currentContact, 'meta.currentSpouse')
                  if (currentSpouse) {
                    currentSpouse = `${getIn(currentSpouse, 'first_name')} ${getIn(currentSpouse, 'last_name')}`
                  }
                  if (!currentContact) {
                    return (
                      <div className={classNames(this.props.childcols, 'form-contact-card', 'form-contact-card-empty', { 'col-lg-6': !this.props.childcols })} key={`fset-${idx}` }>
                        <Field component="input" type="hidden" name={`${field.name}.${idx}`} value={!f ? '' : f} />
                        {/* Show selected sellers */}
                        <h5>{idx > 0 ? `${valueFormat('humanize', idx + 1)} ` : ''}{this.state.suffix}</h5>
                        <div className="arrayaction">
                          { (hasPermission([ 'contacts_add' ], user.permissions) && props.createnew) &&
                          <Button
                            type="button"
                            id={`${field.name}-contact-lookup-create-btn`}
                            tabIndex="-1"
                            onClick={() => {
                              this.setState({ contact_id: null }, () => {
                                this.toggleCreator()
                              })
                            }}
                            className="btn btn-grey"
                          >Add New Contact</Button>
                          }
                          { props.createnew &&
                          <Button
                            type="button"
                            id={`${field.name}-contact-lookup-search-btn`}
                            tabIndex="-1"
                            onClick={this.toggleSearch}
                            className="btn btn-subtle"
                          >Search Contacts</Button>
                          }
                          <WideSidebar sidebar={`show-contact-creator-${field.name}`}>
                            <ContactCreatorSidebar
                              label={props.label}
                              field={field}
                              key={`ccreator-${field.name}`}
                              toggleLookup={this.toggleCreator}
                              autofill={this.state.autofill}
                              selectContact={r => {
                                this.selectContact(idx, r)
                              }}
                              siblingform={form}
                              settings={settings}
                              match={props.match}
                            />
                          </WideSidebar>
                          <WideSidebar sidebar={`show-contact-search-${field.name}`}>
                            <ContactSearchSidebar
                              label={props.label}
                              user={this.props.user}
                              field={field}
                              key={`csearch-${field.name}`}
                              autofill={this.state.autofill}
                              toggleLookup={this.toggleCreator}
                              selectContact={r => {
                                this.selectContact(idx, r)
                              }}
                              params={props.params}
                              siblingform={form}
                              settings={settings}
                              match={props.match}
                            />
                          </WideSidebar>
                        </div>
                      </div>
                    )
                  }
                  return (
                    <div className={classNames(this.props.childcols, 'form-contact-card', { 'col-lg-6': !this.props.childcols })} key={`fcontact-${idx}` }>
                      {/* Show selected sellers */}
                      <h5>{idx > 0 ? `${valueFormat('humanize', idx + 1)} ` : ''}{this.state.suffix}</h5>
                      <Field component="input" type="hidden" name={`${field.name}.${idx}`} value={!f ? '' : f} />
                      <div className="meta-group">
                        <div className="meta-detail col-lg-12">
                          <div className="heavy">Status</div><div className="col">{(redact && currentReadOnly) ? valueFormat('redact', getIn(currentContact, 'status', '')) : currentContact.status}</div>
                        </div>
                        <div className="meta-detail col-lg-12">
                          <div className="heavy">First Name</div><div className="col">{(redact && currentReadOnly) ? valueFormat('redact', getIn(currentContact, 'first_name', '')) : currentContact.first_name}</div>
                        </div>
                        <div className="meta-detail col-lg-12">
                          <div className="heavy">Last Name</div><div className="col">{(redact && currentReadOnly) ? valueFormat('redact', getIn(currentContact, 'last_name', '')) : currentContact.last_name}</div>
                        </div>
                        <div className="meta-detail col-lg-12">
                          <div className="heavy">Email Address</div><div className="col">{(redact && currentReadOnly) ? valueFormat('redact', getIn(currentContact, 'email', '')) : valueFormat('mailto', currentContact.email)}</div>
                        </div>
                        <div className="meta-detail col-lg-12">
                          <div className="heavy">Spouse</div><div className="col">{(redact && currentReadOnly) ? valueFormat('redact', currentSpouse) : currentSpouse}</div>
                        </div>
                      </div>
                      {!currentReadOnly ? (
                        <div className="arrayaction">
                          <Button type="button" className="btn btn-grey" onClick={() => {
                            this.unlinkContact(currentContact.id)
                          }}>Unlink {this.state.suffix}</Button>
                          <Button component={NavLink} target="_blank" className="btn btn-subtle" to={`/secure/contacts/${currentContact.id}`} title="View Contact">
                              View {this.state.suffix}
                          </Button>
                          { (hasPermission([ 'contacts_update', 'contacts_update_own' ], user.permissions) && props.createnew) &&
                          <Button
                            type="button"
                            id={`${field.name}-contact-lookup-edit-btn`}
                            tabIndex="-1"
                            onClick={() => this.toggleEdit(currentContact.id)}
                            className="btn btn-subtle"
                            value={currentContact.id}
                          >Edit {this.state.suffix}</Button>
                          }
                          <WideSidebar sidebar={`show-contact-edit-${field.name}`}>
                            <ContactCreatorSidebar
                              label={props.label}
                              field={field}
                              key={`cedit-${field.name}`}
                              autofill={this.state.autofill}
                              toggleLookup={this.toggleEdit}
                              selectContact={r => {
                                this.selectContact(idx, r)
                              }}
                              siblingform={form}
                              settings={settings}
                              match={props.match}
                            />
                          </WideSidebar>
                        </div>
                      ) : null}
                    </div>
                  )
                }) }
                <div className="arrayaction">
                  {!no_add && value && Array.isArray(value) ? (
                    <Button
                      icon="#icon16-Plus"
                      type="button"
                      className="btn btn-grey btn-icon-16 btn-icon-left"
                      onClick={() => {
                        form.setFieldValue(field.name, [ ...value, null ]).then(() => {
                          form.setFieldTouched(field.name)
                        })
                      }}
                    >
                      Add another {this.state.suffix}
                    </Button>
                  ) : null}
                  {!no_add && !value ? (
                    <Button
                      icon="#icon16-Plus"
                      type="button"
                      className="btn btn-grey btn-icon-16 btn-icon-left"
                      onClick={() => {
                        form.setFieldValue(field.name, [ null ]).then(() => {
                          form.setFieldTouched(field.name)
                        })
                      }}
                    >
                        Add a {this.state.suffix}
                    </Button>
                  ) : null}
                </div>
              </div>
            ) }
          />
        ) : ( // Not multi
          <div className={classNames(this.props.childcols, 'form-contact-card', { 'form-contact-card-empty': (!value || !contact), 'col-lg-6': !this.props.childcols })}>
            { // Fix error not displaying for Customer Experience fields (fake array field)
              // eslint-disable-next-line no-nested-ternary
              getIn(form.errors, name) ? (
                <ErrorMessage name={name} render={msg => <div className="error">{msg.replace(name, props.label)}</div> } />
              ) : (
                error && error[name] && form.touched[name] ? (
                  <div className="error">{error[name]}</div>
                ) : null
              )
            }
            {/* Show selected sellers */}
            <h5 className="col-lg-12">{this.state.suffix}</h5>
            <Field component="input" type="hidden" innerRef={el => (this.contact = el)} name={`${field.name}`} value={!value ? '' : value} />
            {(value && contact) ? (
              <Fragment>
                <div className="meta-group">
                  <div className="meta-detail col-lg-12">
                    <div className="heavy">Status</div><div className="col">{(redact && read_only) ? valueFormat('redact', getIn(contact, 'status', '')) : getIn(contact, 'status')}</div>
                  </div>
                  <div className="meta-detail col-lg-12">
                    <div className="heavy">First Name</div><div className="col">{(redact && read_only) ? valueFormat('redact', getIn(contact, 'first_name', '')) : getIn(contact, 'first_name')}</div>
                  </div>
                  <div className="meta-detail col-lg-12">
                    <div className="heavy">Last Name</div><div className="col">{(redact && read_only) ? valueFormat('redact', getIn(contact, 'last_name', '')) : getIn(contact, 'last_name')}</div>
                  </div>
                  <div className="meta-detail col-lg-12">
                    <div className="heavy">Email Address</div><div className="col">{(redact && read_only) ? valueFormat('redact', getIn(contact, 'email', '')) : valueFormat('mailto', getIn(contact, 'email'))}</div>
                  </div>
                  <div className="meta-detail col-lg-12">
                    <div className="heavy">Spouse</div><div className="col">{(redact && read_only) ? valueFormat('redact', spouse) : spouse}</div>
                  </div>
                </div>
                {!read_only ? (
                  <div className="arrayaction">
                    <Button type="button" className="btn btn-grey" onClick={() => {
                      this.unlinkContact(contact.id)
                    }}>Unlink {this.state.suffix}</Button>
                    <Button component={NavLink} target="_blank" className="btn btn-subtle" to={`/secure/contacts/${contact.id}`} title="View Contact">
                      View {this.state.suffix}
                    </Button>
                    { (hasPermission([ 'contacts_update', 'contacts_update_own' ], user.permissions) && props.createnew) &&
                    <Button
                      type="button"
                      id={`${field.name}-contact-lookup-edit-btn`}
                      tabIndex="-1"
                      onClick={() => this.toggleEdit(contact.id)}
                      className="btn btn-subtle"
                      value={contact.id}
                    >Edit {this.state.suffix}</Button>
                    }
                    <WideSidebar sidebar={`show-contact-edit-${field.name}`}>
                      <ContactCreatorSidebar
                        label={props.label}
                        field={field}
                        key={`cedit-${field.name}`}
                        autofill={this.state.autofill}
                        toggleLookup={this.toggleEdit}
                        selectContact={r => {
                          this.selectContact(0, r)
                        }}
                        siblingform={form}
                        settings={settings}
                        match={props.match}
                      />
                    </WideSidebar>
                  </div>
                ) : null}
              </Fragment>
            ) : (
              <div className="arrayaction">
                { (hasPermission([ 'contacts_add' ], user.permissions) && props.createnew) &&
                <Button
                  type="button"
                  id={`${field.name}-contact-lookup-create-btn`}
                  tabIndex="-1"
                  onClick={this.toggleCreator}
                  className="btn btn-grey"
                >Add New Contact</Button>
                }
                <Button
                  type="button"
                  id={`${field.name}-contact-lookup-search-btn`}
                  tabIndex="-1"
                  onClick={this.toggleSearch}
                  className="btn btn-subtle"
                >Search Contacts</Button>
                <WideSidebar sidebar={`show-contact-creator-${field.name}`}>
                  <ContactCreatorSidebar
                    label={props.label}
                    field={field}
                    user={this.props.user}
                    key={`ccreator-${field.name}`}
                    autofill={this.state.autofill}
                    toggleLookup={this.toggleCreator}
                    selectContact={r => {
                      this.selectContact(null, r)
                    }}
                    settings={settings}
                    match={props.match}
                    siblingform={form}
                  />
                </WideSidebar>
                <WideSidebar sidebar={`show-contact-search-${field.name}`}>
                  <ContactSearchSidebar
                    label={props.label}
                    field={field}
                    user={this.props.user}
                    key={`csearch-${field.name}`}
                    autofill={this.state.autofill}
                    toggleLookup={this.toggleCreator}
                    selectContact={r => {
                      this.selectContact(null, r)
                    }}
                    settings={settings}
                    siblingform={form}
                    match={props.match}
                  />
                </WideSidebar>
              </div>
            )
            }
          </div>
        )}
      </div>
    )
  }
}

ContactLookup.propTypes = {
  id: PropTypes.string.isRequired,
  toggleWideSidebar: PropTypes.func.isRequired,
  fetchOne: PropTypes.func.isRequired,
  fetchMany: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  childcols: PropTypes.string,
  suffix: PropTypes.string,
  form: PropTypes.object.isRequired,
  deeds_owner: PropTypes.string,
  params: PropTypes.string,
  deedsfield: PropTypes.string,
  className: PropTypes.string,
  create: PropTypes.bool,
  multi: PropTypes.bool,
  cache: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  model: PropTypes.object,
  match: PropTypes.object,
  selected: PropTypes.array,
  config: PropTypes.object,
  settings: PropTypes.object,
  setIgnoreFields: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.object,
  user: PropTypes.object
}


export default ContactLookup
