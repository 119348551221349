/* eslint-disable new-cap */
import { differenceInCalendarDays } from 'date-fns'
import { getIn } from 'formik'
import { Map } from 'immutable'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany } from '../../actions'
import { CONFIG, MINUSER } from '../../selectors'
import { title, sortBy, textToDate, valueFormat } from '../../utils'
import Card from '../common/Card'
import SimpleTable from '../common/simpletable/SimpleTable'
import InlineBranchSelect from './inputs/InlineBranchSelect'
import InlineAgentSelect from './inputs/InlineAgentSelect'
import InlineListingTypeSelect from './inputs/InlineListingTypeSelect'
import withDelay from './withDelay'


const period = 'NEXT_30_DAYS'
const { end: defaultEnd } = textToDate(period)

const ExpiringListingsWidget = ({ actions, configs, user }) => {
  const [ branchId, setBranchId ] = useState('')
  const [ agentId, setAgentId ] = useState(user.agent.id || '')
  const [ listingModel, setListingModel ] = useState('')
  const [ { end: limit } ] = useState({ end: valueFormat('shortdate', defaultEnd.toString()) })
  const [ { modelName, listingType }, setParams ] = useState({ modelName: null, listingType: null })

  const abortController = useRef(new AbortController())

  useEffect(() => () => {
    abortController.current.abort()
  }, [])

  return (
    <Card
      id="expiring-listings-widget"
      classes="grid-col-1"
      bodyclass="stats-card no-top-padding expiring-listings-widget"
      background
      header={
        <>
          <h3>Expiring Mandates & Leases</h3>
          <div className="details-section-buttons min-flex tablemeta">
            <InlineBranchSelect
              user={user}
              actions={actions}
              selectedValue={branchId}
              onChange={e => {
                setBranchId(e.value)
                setAgentId('')
              }}
            />
            <InlineAgentSelect
              user={user}
              actions={actions}
              branchId={branchId}
              selectedValue={agentId}
              onChange={e => {
                setAgentId(e.value)
              }}
            />
            <InlineListingTypeSelect
              user={user}
              selectedValue={listingModel}
              showAllOption
              onChange={e => {
                setListingModel(e.value)
                let newModelName = e.value
                let newListingType = null
                try {
                  const re = /(?<modelname>.*)_(?<listing_type>for_sale|to_let)/g
                  const groups = re.exec(newModelName)?.groups
                  newModelName = groups?.modelname
                  newListingType = title(groups?.listing_type.replace('_', ' '))
                } catch (er) {
                  console.error(er)
                }
                setParams({ modelName: newModelName, listingType: newListingType })
              }}
            />
          </div>
        </>
      }
      body={
        <SimpleTable
          paginated
          config={configs.residential}
          action={({ params, resolve, reject }) => {
            const promises = []

            if (!params.listing_type || params.listing_type === 'For Sale') {
              promises.push(new Promise((res, rej) => actions.fetchMany({
                noloader: true,
                values: {
                  modelname: 'residential',
                  endpoint: {
                    read: '/mashup/api/v1/listings/expiring-listings'
                  },
                  fields: [ 'id', 'web_ref', 'agent', 'mandate_end_date', 'mandate_type', 'model', 'property_type', 'listing_type' ],
                  meta_fields: [ 'agent' ],
                  conflict: true,
                  order_by: '-mandate_end_date',
                  signal: abortController.current.signal,
                  params: {
                    ...params,
                    status__in: 'Active,Pending',
                    listing_type: 'For Sale',
                    date_to: limit
                  }
                },
                resolve: res,
                reject: rej
              })).catch(e => {
                if (e.status !== 408) {
                  console.error(e)
                }
              }))
            }

            if (!params.listing_type || params.listing_type === 'To Let') {
              promises.push(new Promise((res, rej) => actions.fetchMany({
                noloader: true,
                values: {
                  modelname: 'residential',
                  endpoint: {
                    read: '/mashup/api/v1/listings/expiring-listings'
                  },
                  fields: [
                    'id',
                    'web_ref',
                    'agent',
                    'tenant_lease_ends',
                    'mandate_type',
                    'model',
                    'property_type',
                    'listing_type'
                  ],
                  meta_fields: [ 'agent' ],
                  conflict: true,
                  order_by: '-tenant_lease_ends',
                  signal: abortController.current.signal,
                  params: {
                    ...params,
                    status__in: 'Active,Pending,Rented',
                    listing_type: 'To Let',
                    date_to: limit
                  }
                },
                resolve: res,
                reject: rej
              })).catch(e => {
                if (e.status !== 408) {
                  console.error(e)
                }
              }))
            }
            Promise.allSettled(promises).then(results => {
              let all_data = []
              results.forEach(result => {
                if (!getIn(result, 'value')) { return }
                all_data = [ ...all_data, ...result.value.options.map(o => {
                  if (o.listing_type === 'For Sale') {
                    o.expiry_date = new Date(getIn(o, 'mandate_end_date'))
                  } else {
                    o.expiry_date = new Date(getIn(o, 'tenant_lease_ends'))
                  }
                  return o
                }) ]
              })
              resolve({ options: sortBy(all_data, 'expiry_date'), hasMore: false })
            }).catch(e => {
              reject(e)
            })
          }}
          params={{
            model: modelName,
            listing_type: listingType,
            branch: branchId,
            agents__in: agentId,
            meta_fields: [ 'agent' ],
            fields: [
              'id',
              'web_ref',
              'agent',
              'mandate_end_date',
              'tenant_lease_ends',
              'mandate_type',
              'model',
              'property_type',
              'listing_type'
            ],
            limit: 100
          }}
          parser={data => {
            data.options = data.options.map(o => {
              if (o.model === 'project') {
                o.property_types = []
              }
              const days_left = differenceInCalendarDays(new Date(o.expiry_date), new Date())
              let score = 1
              if (days_left <= 0) {
                score = 3
              } else if (days_left <= 14) {
                score = 2
              }
              o.score = score
              o.days_left = days_left
              return o
            })
            return data
          }}
          header={[
            {
              label: <svg viewBox="0 0 32 32" className="btmstar"><use href="/images/glyphs.svg#glyph-Star" /></svg>,
              name: 'score',
              orderable: false,
              format: 'score'
            },
            {
              label: 'Expiry Date',
              name: 'expiry_date',
              orderable: false,
              format: 'date'
            },
            {
              label: 'Web Ref',
              name: 'web_ref',
              orderable: false,
              link: '/secure/:model/:id'
            },
            {
              label: 'Type',
              name: 'mandate_type',
              orderable: false
            },
            {
              label: 'Agent',
              name: 'agent',
              modelname: 'agents',
              optionlabel: [ 'first_name', 'last_name' ],
              labelseparator: ' ',
              order_by: 'agent__first_name',
              link: '/secure/:site/users/:agent',
              orderable: false
            }
          ]}
          user={user}
        />
      }
    />
  )
}


ExpiringListingsWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  configs: PropTypes.object
}

const mapStateToProps = state => {
  const user = MINUSER(state)
  const residential = CONFIG(state, 'residential')
  const commercial = CONFIG(state, 'commercial')
  const projects = CONFIG(state, 'projects')
  const holiday = CONFIG(state, 'holiday')
  return ({
    user,
    configs: Map({
      residential,
      commercial,
      projects,
      holiday
    })
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(withDelay(ExpiringListingsWidget)))
