/* eslint-disable no-unused-vars */
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { getIn } from 'formik'
import { NavLink } from 'react-router-dom'

import log from '../../../logging'
import { Button } from '../../ui/Button'
import { valueFormat, parseURL, generateWebsiteLink } from '../../../utils'
import ContextDropdown from './ContextDropdown'


class ContextButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      dropdownStyle: {
        display: 'none',
        position: 'absolute'
      }
    }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.deleteModel = this.deleteModel.bind(this)
    this.scrollSubMenu = this.scrollSubMenu.bind(this)
    this.generateMenuLink = this.generateMenuLink.bind(this)
    this.actions = {
      ...props.actions,
      toggleMenu: this.toggleMenu,
      deleteModel: this.deleteModel
    }
    this.scrolling = null
  }

  componentDidMount() {
    if (!this.props.dropdownHover && this.el) { // Calculate position of dropdown on click
      this.setState({
        dropdownStyle: this.props.actions.dropdownPositionCallback(this.el)
      })
    }
  }

  componentDidUpdate() {
    if (!this.props.dropdownHover && this.el) { // Calculate position of dropdown on click
      if (this.state.open) {
        window.addEventListener('scroll', this.scrollSubMenu, true)
      } else {
        window.removeEventListener('scroll', this.scrollSubMenu)
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollSubMenu, true)
    window.removeEventListener('scroll', this.scrollSubMenu)
    clearTimeout(this.scrolling)
  }

  generateMenuLink(element, children, dropdownHover) {
    const { id } = this.props.match.params
    const { form, user, actions, cache, model, portals, modelname, website_url, app, selected } = this.props
    let { modelid } = this.props
    let link = element.link
    let visible = false
    let m = getIn(cache, modelid)
    if (!m && selected.length) { m = cache[getIn(user, selected[0])] }
    visible = this.props.isButtonVisible(element)
    if (visible) {
      if (element.link) {
        if (element.portal) { // This relates to a portal link
          if (m.meta.portals && portals) { // Ensure selected model portal and global portals exist
            const portalidx = Object.keys(portals).find(pk => portals[pk].meta.portal.slug === element.portal)
            if (!portalidx) { return null }
            const portal = m.meta.portals.find(p => p.portal === portals[portalidx].portal)
            if (!portal) { return null }
            if (element.portal === 'hubspot') {
              const portal_config = user.agent.site.portals?.branch?.find(
                p => p.branch_id === m.meta.branch.id && p.portal === 25
              )
              if (portal_config) {
                portal.reference = false
                if (portal_config.credentials && portal_config.credentials.includes(':')) {
                  portal.reference = portal_config.credentials.split(':')[0]
                } else {
                  portal.reference = portal_config.reference
                }
              }
            }
            link = valueFormat(element.format, portal.reference, {
              portal: portal.meta.portal.slug,
              reference: portal.reference,
              location: m.meta.location ? m.meta.location : m.email,
              listing_type: m.listing_type
            })
          }
        } else if (this.props.action === 'list') { // Add the default list params
          const search = new URLSearchParams(getIn(this.props, 'model.params', getIn(this.props, 'config.params', ''))).toString()
          link = `${element.link}?${search}`
        } else if (element.scope === 'selected') {
          link = parseURL(element.link, { selected, site: user.agent.site.id }) // List with selection
        } else if (id) {
          link = parseURL(element.link, cache[id])
        } else if (modelid && !element.linkto) { // Right clicked on a table row for example
          link = parseURL(element.link, cache[modelid])
        } else if (selected.length) {
          link = parseURL(element.link, cache[selected[0]], user)
        }
        if (element.linkto) { // User for opening new tabs like view on website etc.
          switch (element.linkto) {
            case 'site':
              if ((!link || link === 'null') && element.link === ':meta.url.website') {
                if (m && !m.display_on_website) { return null } // Listing is not set to display
                if (!this.state.generatedLink) {
                  link = false
                  generateWebsiteLink(m, modelname, getIn(user, [ 'agent', 'site' ])).then(l => {
                    if (l) { this.setState({ generatedLink: l }) }
                  }).catch(e => log.error(e))
                } else {
                  link = valueFormat('url', `${website_url}${this.state.generatedLink}`)
                }
              } else {
                if (getIn(cache[modelid], element.link.substring(1))) { // Cater for branch, agent, team microsites as well as main website
                  link = getIn(cache[modelid], element.link.substring(1)) // Remove the colon from the link key and retrieve the value
                }
                if (link === 'null') {link = null}
                link = link && link.replace('./', '')
                if (link && !link.startsWith('http')) {
                  link = valueFormat('url', `${website_url}${link}`)
                }
              }

              break
            case 'facebook':
            case 'twitter':
            case 'linkedin':
            case 'whatsapp':
            case 'pinterest':
              link = link && link.replace('./', '')
              break
            case 'reports': {
              let service
              if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'e2e') {
                service = app.reports.stage
              } else if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENV === 'production') {
                service = app.reports.live
              } else {
                service = app.reports.dev
              }
              link = `${service}${link}`
              break
            }
            default:
              break
          }
          if (link) {
            return (
              <Button
                component="a"
                className={this.getButtonClasses()}
                rel="noopener noreferrer"
                target="_blank"
                href={link}
                icon={element.icon}
                onMouseOver={e => {
                  if (dropdownHover && this.state.open) { this.handleClose(e) }
                  return e
                }}
              >{children}</Button>
            )
          }
          return null // Return nothing if no link
        }

        return ( // Otherwise return React Router Link
          <Button component={NavLink}
            target={element.target}
            to={parseURL(link, { site: user.agent.site.id })}
            className={this.getButtonClasses()}
            icon={element.icon}
            onMouseOver={e => {
              if (dropdownHover && this.state.open) { this.handleClose(e) }
              return e
            }}
          >{children}</Button>
        )
      } else if (element.action) { // Reports, exports etc.
        const action = actions[element.action]
        if (!modelid && id) {
          modelid = id
        } else if (!modelid && selected.length) {
          modelid = selected[0]
        }
        return (
          <Button component='div'
            type="button"
            onClick={e => {
              if (action) {
                action({
                  redirect: e.currentTarget.getAttribute('data-redirect'),
                  modelname,
                  id: modelid,
                  params: model.params,
                  args: element.args,
                  label: children,
                  site: user.agent.site.domain,
                  form: form,
                  fields: element.fields
                })
              }
            }}
            onMouseOver={e => {
              if (dropdownHover && this.state.open) { this.handleClose(e) }
              return e
            }}
            className={this.getButtonClasses()}
            icon={element.icon}
          >{children}</Button>
        )
      }
    }
    return null
  }

  deleteModel(values) {
    return new Promise((resolve, reject) => {
      this.props.actions.deleteModel({ values, resolve, reject })
    })
  }

  handleOpen() { // Handles Open on mouseover event
    this.setState({
      open: true,
      dropdownStyle: this.props.actions.dropdownPositionCallback(this.el)
    })
  }

  handleClose() { // Handles Close on mouselease event
    this.setState({
      open: false,
      dropdownStyle: this.props.actions.dropdownPositionCallback(this.el)
    })
  }

  toggleMenu() { // Handles toggle on click event
    this.setState({
      open: !this.state.open,
      dropdownStyle: this.props.actions.dropdownPositionCallback(this.el)
    })
  }

  scrollSubMenu() { // Handles toggle on click event
    this.scrolling = setTimeout(() => {
      if (this.el) {
        this.setState({
          dropdownStyle: this.props.actions.dropdownPositionCallback(this.el)
        })
      }
    }, 100)
  }

  getButtonClasses () {
    return classNames('action', 'navitem', 'btn', { 'btn-icon-16': this.props.button.icon, 'btn-icon-left': this.props.button.icon }, this.props.className, this.props.button.className)
  }

  renderDropdownButton() {
    const { button, className, match, form, user, cache, model, portals, modelname,
      stickyProps, dropdownHover, isButtonVisible, modelid, website_url, selected } = this.props
    const { actions } = this

    return (
      <div
        className={`action has-submenu ${className || ''}`}
        onMouseOver={(e => {
          if (dropdownHover) { this.handleOpen() } // Only fire if this is a hoverDropdown
          return e
        })}
        onMouseLeave={(e => {
          if (dropdownHover) { this.handleClose() } // Only fire if this is a hoverDropdown
          return e
        })}
      >
        <Button
          icon={button.icon}
          component='div'
          forwardRef={el => { this.el = el }}
          className={`${this.getButtonClasses()}${this.state.open ? ' active' : ''}`}
          onClick={e => {
            if (this.el.classList.contains('disabled')) { return }
            if (button.action && actions[button.action]) {
              actions[button.action]({ e, args: button.args, form })
            }
            if (button.menu) { actions.toggleMenu(e) }
          }}
          data-redirect={button.redirect}
        >
          <span className="label">{this.props.children}</span>
          {!(this.props.button.className && this.props.button.className.includes('btn-round btn-red')) ? (
            <div
              className={`navicon icon submenu-icon${this.state.open ? ' active' : ''}`}
            >
              <svg viewBox="0 0 24 24"><use href="/images/icons-24.svg#icon24-ChevronDown" /></svg>
            </div>
          ) : null}
        </Button>
        {this.state.open &&
          <ContextDropdown
            match={match}
            model={model}
            modelname={modelname}
            modelid={modelid}
            form={form}
            cache={cache}
            portals={portals}
            user={user}
            website_url={website_url}
            actions={actions}
            styles={stickyProps}
            selected={selected}
            menu={button.menu}
            dropdownHover={dropdownHover}
            handleClose={this.handleClose}
            dropdownStyles={this.state.dropdownStyle}
            className="action-submenu"
            isButtonVisible={isButtonVisible}
          />
        }
      </div>
    )
  }

  renderLinkButton() {
    const { button, link, dropdownHover, children } = this.props
    return this.generateMenuLink(button, children, dropdownHover)
  }

  renderButton() {
    const { button, form, dropdownHover, children, filters,
      match, modellist, user, model, disabled, selected } = this.props
    const { actions } = this
    return (
      /* Pass in the action event for the redirection schema,
      * any arguments from config as well as the
      * button label in order to populate the notifaction and alerts.
      */
      <Button
        component="div"
        icon={button.icon}
        className={this.getButtonClasses()}
        onClick={ e => {
          if (actions[button.action] && !disabled) {
            actions[button.action]({
              redirect: e.currentTarget.getAttribute('data-redirect'),
              modelname: match.params.model,
              id: match.params.id,
              args: button.args,
              label: button.label,
              modellist: modellist,
              params: filters ? { ...getIn(model, 'params', {}), ...filters } : getIn(model, 'params', {}),
              selected: selected.length ? selected : [ this.props.modelid ],
              form
            })
          }
        }}
        onMouseOver={e => {
          if (dropdownHover && this.state.open) { this.handleClose(e) }
          return e
        }}
        data-redirect={button.redirect}
      >
        <span className="label">{children}</span>
      </Button>
    )
  }

  render() {
    const { button } = this.props
    if (button.menu) {
      return this.renderDropdownButton()
    } else if (button.link) {
      return this.renderLinkButton()
    }
    return this.renderButton()
  }
}

ContextButton.propTypes = {
  clickHandler: PropTypes.func,
  icon: PropTypes.string,
  link: PropTypes.string,
  modelname: PropTypes.string,
  menu: PropTypes.object,
  match: PropTypes.object,
  modelid: PropTypes.number,
  modellist: PropTypes.bool,
  website_url: PropTypes.string,
  action: PropTypes.string,
  user: PropTypes.object,
  actions: PropTypes.object,
  button: PropTypes.object,
  form: PropTypes.object,
  dropdownHover: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  filters: PropTypes.object,
  model: PropTypes.object,
  disabled: PropTypes.bool,
  redirect: PropTypes.string,
  className: PropTypes.string,
  cache: PropTypes.object,
  portals: PropTypes.object,
  app: PropTypes.object,
  stickyProps: PropTypes.object,
  isButtonVisible: PropTypes.func,
  selected: PropTypes.array
}

export default ContextButton
