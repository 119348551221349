import PropTypes from 'prop-types'
import React from 'react'
import ReactPlayer from 'react-player/lazy'
import { NavHashLink as NavLink } from 'react-router-hash-link'

import log from '../../logging'
import { hasPermission, isConditional, valueFormat } from '../../utils'
import Activity from '../common/Activity'
import Card from '../common/Card'
import DetailsList from '../common/DetailsList'
import Leads from '../common/lead-interactions/Leads'
import ListingDashboard from '../common/ListingDashboard'
import Alerts from '../common/ListingsAlerts'
import Contacts from '../common/ListingsContacts'
import Matches from '../common/ListingsMatches'
import MediaGallery from '../common/MediaGallery'
import Notes from '../common/notes/Notes'
import Publish from '../common/Publish'
import Syndication from '../common/Syndication'
import HorizontalTabs from '../common/tabs/HorizontalTabs'
import Tab from '../common/tabs/Tab'
import ViewingFeedback from '../common/ViewingFeedback'


class Project extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      match_count: 0,
      loading_matches: true,
      lead_count: 0,
      loading_leads: true,
      alert_count: 0,
      loading_alerts: true
    }
    this.isConditional = isConditional.bind(this)
  }

  componentDidMount() {
    if (hasPermission(this.props.routeConfig.view.tabs.matches.permissions, this.props.user.permissions)) {
      new Promise((resolve, reject) => this.props.actions.fetchMatches({ offset: 0, limit: 100, meta_fields: 'lead,contact' }, resolve, reject)).then(r => {
        this.setState({ match_count: r.count, loading_matches: false })
      }).catch(e => [
        log.error(e)
      ])
      new Promise((resolve, reject) => this.props.actions.fetchMany({
        values: {
          modelname: 'leads',
          params: {
            limit: 100,
            project: this.props.model.id,
            active: true
          },
          modellist: true
        },
        resolve,
        reject
      })).then(r => {
        this.setState({ loading_leads: false, lead_count: r.count })
      }).catch(e => [
        log.error(e)
      ])
    } else {
      this.setState({ loading_leads: false })
    }

    if (hasPermission(this.props.routeConfig.view.tabs.contacts.permissions, this.props.user.permissions)) {
      new Promise((resolve, reject) => this.props.actions.fetchAlerts(
        {
          limit: 100,
          project: this.props.model.id,
          status: 'success',
          meta_fields: [ 'contact', 'profile', 'agent' ]
        },
        resolve,
        reject
      )).then(r => {
        this.setState({ loading_alerts: false, alert_count: r.count })
      }).catch(e => [
        log.error(e)
      ])
    } else {
      this.setState({ loading_alerts: false })
    }
  }

  render() {
    const {
      model,
      modelid,
      config,
      configs,
      cache,
      match,
      user,
      location,
      actions,
      routeConfig,
      addons,
      app
    } = this.props

    return (
      <HorizontalTabs
        config={routeConfig}
        location={location}
        match={match}
        model={model}
        defaultTab="details"
        user={{ permissions: user.permissions, agent: user.agent }}
      >
        <Tab tab="dashboard" label="Dashboard">
          <ListingDashboard {...this.props} />
        </Tab>

        <Tab tab="details" label="Details" noScroll>
          <DetailsList {...this.props} />
        </Tab>

        <Tab tab="media" label="Media">
          <>
            <MediaGallery
              media={[
                {
                  items: model.meta.listing_images,
                  mediatype: 'image',
                  fieldname: 'listing_images',
                  plural: 'images',
                  label: 'Images'
                }, {
                  items: model.meta.progress_tracker,
                  mediatype: 'image',
                  fieldname: 'progress_tracker',
                  plural: 'progress tracker',
                  label: 'Progress Tracker'
                }, {
                  items: model.meta.floor_plans,
                  mediatype: 'image',
                  fieldname: 'floor_plans',
                  plural: 'floor plans',
                  label: 'Floor Plans'
                }, {
                  items: model.meta.header_images,
                  mediatype: 'image',
                  fieldname: 'header_images',
                  plural: 'header images',
                  label: 'Header Images'
                }, {
                  items: [ model.meta.logo ],
                  mediatype: 'image',
                  fieldname: 'logo',
                  plural: 'logos',
                  label: 'Logo'
                }, {
                  items: model.meta.documents,
                  mediatype: 'documents',
                  fieldname: 'documents',
                  plural: 'website documents',
                  label: 'Website Documents'
                }, {
                  items: model.meta.private_documents,
                  mediatype: 'documents',
                  fieldname: 'private_documents',
                  plural: 'documents',
                  label: 'Private Documents'
                }
              ]}
              actions={{
                downloadImages: actions.downloadImages,
                isConditional: this.isConditional
              }}
              config={config}
              user={user}
              model={model}
              modelname={config.modelname}
            />
            <Card
              background
              header={
                <h3>Multimedia</h3>
              }
              body={() => {
                const field = config.fields.find(f => f.name === 'video_id')
                let can_edit = true
                const mockform = {
                  touched: { [field.name]: true },
                  values: { [field.name]: model ? model[field.name] : null }
                }
                if (field.show && Array.isArray(field.show)) {
                  field.show.map(s => s.map(condition => (
                    mockform.values[condition.field] = model[condition.field])
                  ))
                  if (!this.isConditional(field, 'show', false, mockform)) { can_edit = false }
                }
                return (model.video_id || model.virtual_tour || model.eyespy360 || model.matterport_id) ? (
                  <>
                    {model.video_id && model.video_streaming_platform === 'YouTube' &&
                      <React.Fragment>
                        <h4 className="section-underline">Youtube</h4>
                        <div className='player-container'>
                          <div className='player-wrapper'>
                            <ReactPlayer
                              className='react-player'
                              url={`https://www.youtube.com/watch?v=${model.video_id}`}
                              controls
                              width='100%'
                              height='100%'
                              config={{
                                youtube: {
                                  playerVars: {
                                    showinfo: 0,
                                    modestbranding: 1,
                                    rel: 0
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    }
                    {model.video_id && model.video_streaming_platform === 'Vimeo' &&
                      <React.Fragment>
                        <h4 className="section-underline">Vimeo</h4>
                        <div className='player-container'>
                          <div className='player-wrapper'>
                            <ReactPlayer
                              className='react-player'
                              url={`https://www.vimeo.com/${model.video_id}`}
                              controls
                              width='100%'
                              height='100%'
                              config={{
                                vimeo: {
                                  playerVars: {
                                    responsive: true
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    }
                    {model.virtual_tour &&
                      <React.Fragment>
                        <h4 className="section-underline">Virtual Tour</h4>
                        <div className='player-container'>
                          {valueFormat('link', model.virtual_tour, { target: '_blank' })}
                        </div>
                      </React.Fragment>
                    }
                    {model.eyespy360 &&
                      <React.Fragment>
                        <h4 className="section-underline">EyeSpy360</h4>
                        <div className='player-container'>
                          {valueFormat('link', model.eyespy360, { target: '_blank' })}
                        </div>
                      </React.Fragment>
                    }
                    {model.matterport_id &&
                      <React.Fragment>
                        <h4 className="section-underline">Matterport</h4>
                        <div className='player-container'>
                          {valueFormat('link', `https://my.matterport.com/show/?m=${model.matterport_id}`, { target: '_blank' })}
                        </div>
                      </React.Fragment>
                    }
                  </>
                ) : (
                  <div className="mediatype">
                    <div>
                      There are no videos or virtual tours for this {config.singular}.&nbsp;
                      {can_edit &&
                        <NavLink
                          className="has-link"
                          to={`/secure/${model.site}/${config.modelname}/${model.id}/edit#field-video_id`}
                          scroll={el => setTimeout(() => el.scrollIntoView({ behavior: 'smooth', block: 'center' }), 3000) }
                        >Add some now.
                        </NavLink>
                      }
                    </div>
                  </div>
                )
              }}
            />
          </>
        </Tab>

        <Tab tab="publish" label="Publish">
          <Publish
            model={model}
            cache={cache.globalportals}
            portals={cache.settings[user.agent.site.id].portals}
          />
        </Tab>

        <Tab tab="syndication" label="Syndication">
          <Syndication
            model={model}
            servicename={config.servicename}
            modelname={config.modelname}
            portals={cache.portals}
            settings={cache.settings[user.agent.site.id]}
            permissions={user.permissions}
            agentid={user.agent.id}
            actions={actions}
            user={user}
          />
        </Tab>

        <Tab tab="matches" label="Matches">
          <Matches
            user={user}
            model={model}
            cache={cache}
            config={config}
            configs={configs}
            actions={actions}
          />
        </Tab>

        <Tab tab="alerts-sent" label="Alerts Sent">
          <Alerts
            configs={configs}
            actions={actions}
            modelid={modelid}
            modelname={config.modelname}
            user={user}
          />
        </Tab>

        <Tab tab="contacts" label="Contacts">
          <Contacts
            model={model}
            config={config}
            cache={cache}
            fetchOne={actions.fetchOne}
            user={user}
          />
        </Tab>

        <Tab tab="leads" label="Leads">
          <Leads
            user={user}
            modelid={modelid}
            cache={cache}
            match={match}
            model={model}
            config={config}
            configs={configs}
            actions={actions}
          />
        </Tab>

        <Tab tab="viewing-feedback" label="Viewing Feedback">
          <ViewingFeedback
            user={user}
            model={model}
            cache={cache}
            config={config}
            configs={configs}
            actions={actions}
            addons={addons}
            app={app}
          />
        </Tab>

        <Tab tab="notes" label="Notes / Actions">
          <Notes
            config={config}
            model={model}
            user={user}
            match={match}
            actions={actions}
            cache={cache}
          />
        </Tab>

        <Tab tab="activity" label="Activity">
          <Activity
            model={model}
            cache={cache}
            config={config}
            events={model.activity}
            user={user}
            settings={{ currency: cache.settings[user.agent.site.id].default_currency }}
            agents={cache && cache.agents ? cache.agents : {}}
            fetchActivity={actions.fetchActivity}
            fetchOne={actions.fetchOne}
            fetchMany={actions.fetchMany}
          />
        </Tab>

      </HorizontalTabs>
    )
  }
}

Project.propTypes = {
  model: PropTypes.object,
  modelid: PropTypes.number,
  selected: PropTypes.array,
  config: PropTypes.object,
  configs: PropTypes.object,
  routeConfig: PropTypes.object,
  cache: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object,
  location: PropTypes.object,
  addons: PropTypes.array,
  actions: PropTypes.object,
  app: PropTypes.object
}

export default Project
