import React, { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import InlineSelect from '../../common/forms/inputs/InlineSelect'
import { hasPermission } from '../../../utils'


const all_listings = { value: '', label: 'All Listing Types' }
const residential_for_sale = { value: 'residential_for_sale', label: 'Residential For Sale' }
const residential_to_let = { value: 'residential_to_let', label: 'Residential To Let' }
const commercial_for_sale = { value: 'commercial_for_sale', label: 'Commercial For Sale' }
const commercial_to_let = { value: 'commercial_to_let', label: 'Commercial To Let' }
const projects = { value: 'projects', label: 'Project Listings' }
const holiday = { value: 'holiday', label: 'Holiday Listings' }

const calculatePerms = ({ user, showAllOption, exclude, onChange }) => {
  const [ listingData, setListingData ] = useState({ defaultModel: null, listingOptions: [] })
  useEffect(() => {
    const listing_options = showAllOption ? [ all_listings ] : []
    let default_model
    if (hasPermission([
      'listings_residential_for_sale_view',
      'listings_residential_for_sale_view_own'
    ], user.permissions, null, user.agent.id) && !exclude.includes(residential_for_sale.value)) {
      listing_options.push(residential_for_sale)
      if (!default_model) {
        default_model = residential_for_sale
      }
    }
    if (hasPermission([
      'listings_residential_to_let_view',
      'listings_residential_to_let_view_own'
    ], user.permissions, null, user.agent.id) && !exclude.includes(residential_to_let.value)) {
      listing_options.push(residential_to_let)
      if (!default_model) {
        default_model = residential_to_let
      }
    }
    if (hasPermission([
      'listings_commercial_for_sale_view',
      'listings_commercial_for_sale_view_own'
    ], user.permissions, null, user.agent.id) && !exclude.includes(commercial_for_sale.value)) {
      listing_options.push(commercial_for_sale)
      if (!default_model) {
        default_model = commercial_for_sale
      }
    }
    if (hasPermission([
      'listings_commercial_to_let_view',
      'listings_commercial_to_let_view_own'
    ], user.permissions, null, user.agent.id) && !exclude.includes(commercial_to_let.value)) {
      listing_options.push(commercial_to_let)
      if (!default_model) {
        default_model = commercial_to_let
      }
    }
    if (hasPermission([
      'listings_projects_view',
      'listings_projects_view_own'
    ], user.permissions, null, user.agent.id) && !exclude.includes(projects.value)) {
      listing_options.push(projects)
      if (!default_model) {
        default_model = projects
      }
    }
    if (hasPermission([
      'listings_holiday_view',
      'listings_holiday_view_own'
    ], user.permissions, null, user.agent.id) && !exclude.includes(holiday.value)) {
      listing_options.push(holiday)
      if (!default_model) {
        default_model = holiday
      }
    }
    setListingData({ defaultModel: default_model, listingOptions: listing_options })
    onChange({ value: showAllOption ? all_listings.value : default_model?.value })
  }, [])
  return listingData
}

const InlineListingTypeSelect = ({ user, selectedValue, onChange, showAllOption, exclude }) => {
  const { defaultModel, listingOptions } = calculatePerms({ user, showAllOption, exclude: exclude || [], onChange })
  if (listingOptions.length >= 1) {
    return (
      <div className="filter-listing_model">
        <InlineSelect
          id="listing_model"
          name="listing_model"
          className="inline-select"
          classNamePrefix="inline"
          options={listingOptions}
          defaultValue={showAllOption ? all_listings : defaultModel}
          selectedValue={selectedValue || showAllOption ? all_listings.value : defaultModel.value}
          onChange={onChange}
        />
      </div>
    )
  }
  return null
}

InlineListingTypeSelect.propTypes = {
  user: PropTypes.object,
  selectedValue: PropTypes.any,
  showAllOption: PropTypes.bool,
  exclude: PropTypes.array,
  onChange: PropTypes.func
}

export default InlineListingTypeSelect
