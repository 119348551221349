import { getIn } from 'formik'
import React, { useEffect, useState } from 'react'
import { Switch, Redirect } from 'react-router'
import PropTypes from 'prop-types'

import PrivateRoute from '../PrivateRoute'
import BrochureGenerator from '../BrochureGenerator'
import HTMLPreview from '../HTMLPreview'
import CodePreview from '../CodePreview'
import NavigationManager from '../NavigationManager'
import { withCustomRouter } from '../../components/withCustomRouter'
import Integrations from '../Integrations'
import ReportList from '../../components/reports/ReportList'
import ReportTable from '../../components/reports/ReportTable'
import PrintPage from './PrintPage'
import ModelList from './ModelList'
import ModelView from './ModelView'
import ModelAdd from './ModelAdd'
import ModelEdit from './ModelEdit'
import ModelBulkEdit from './ModelBulkEdit'
import ModelReport from './ModelReport'


export const components = {
  BrochureGenerator: withCustomRouter(BrochureGenerator),
  HTMLPreview: withCustomRouter(HTMLPreview),
  CodePreview: withCustomRouter(CodePreview),
  ModelList: withCustomRouter(ModelList),
  ModelView: withCustomRouter(ModelView),
  ModelAdd: withCustomRouter(ModelAdd),
  ModelEdit: withCustomRouter(ModelEdit),
  ModelBulkEdit: withCustomRouter(ModelBulkEdit),
  ModelReport: withCustomRouter(ModelReport),
  ReportList: withCustomRouter(ReportList),
  ReportTable: withCustomRouter(ReportTable),
  NavigationManager: withCustomRouter(NavigationManager),
  PrintPage: withCustomRouter(PrintPage),
  Redirect: withCustomRouter(Redirect),
  Integrations: withCustomRouter(Integrations)
}


const ModelRoute = props => {
  const pathname = { ...props.location }
  const [ currentPath, setCurrentPath ] = useState(props.location.pathname)

  useEffect(() => {
    if (pathname !== currentPath && window.Appcues) {
      setCurrentPath(pathname)
      window.Appcues.page()
    }
    return () => {}
  }, [ pathname ])

  const { computedMatch, path } = props
  let { routeConfig } = props
  const { log } = computedMatch.params
  if (log) {
    routeConfig = getIn(routeConfig, log)
  }
  let tabs = false
  let edit_tabs = false
  let add_tabs = false
  if (routeConfig.view && routeConfig.view.tabs) { tabs = Object.keys(routeConfig.view.tabs) }
  if (routeConfig.edit && routeConfig.edit.tabs) { edit_tabs = Object.keys(routeConfig.edit.tabs) }
  if (routeConfig.add && routeConfig.add.tabs) { add_tabs = Object.keys(routeConfig.add.tabs) }
  return (
    /* Note:
      * The below switch order is important as it will match the first route found!
      * If your new route is not working this is likely the cause.
      */
    <Switch>
      {routeConfig.list &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.list}
          exact
          path={path}
          component={components[getIn(routeConfig, 'list.component')] || ModelList}
        />
      }
      {(
        routeConfig.residential
        || routeConfig.commercial
        || routeConfig.holiday
        || routeConfig.agents || routeConfig.branches
      ) ? (
          <PrivateRoute
            {...{ ...props, routeConfig }}
            model={routeConfig[props.computedMatch.params.model]}
            exact
            path={path}
            component={ModelList}
          />
        ) : null}
      {routeConfig.add && routeConfig.add.tabs &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.add}
          path={`${path}/:action(add)/:tab(${add_tabs.map(tab => tab).join('|')})?`}
          component={components[getIn(routeConfig, 'add.component')] || ModelAdd}
        />
      }
      {routeConfig.add &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.add}
          exact
          path={`${path}/:action(add)`}
          component={components[getIn(routeConfig, 'add.component')] || ModelAdd}
        />
      }
      {routeConfig.reports &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.reports}
          exact
          path={`${path}/:action(reports)`}
          component={components[getIn(routeConfig, 'reports.component')] || ModelReport}
        />
      }
      {routeConfig.reports &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.reports}
          exact
          path={`${path}/:action(reports)/:report/:id/:arg1?/:arg2?`}
          component={components[getIn(routeConfig, 'reports.component_single')] || ModelReport}
        />
      }
      {routeConfig.reports && routeConfig.reports.tabs &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.reports}
          exact
          path={`${path}/:action(reports)/:tab`}
          component={components[getIn(routeConfig, 'reports.component')] || ModelReport}
        />
      }
      {routeConfig.print &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.print}
          exact
          path={`${path}/:id/:action(print)`}
          component={PrintPage}
        />
      }
      {routeConfig.signature &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.signature}
          exact
          path={`${path}/:id/:action(signature)`}
          component={HTMLPreview}
        />
      }
      {routeConfig.brochure &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.brochure}
          exact
          path={`${path}/:id/:action(brochure)`}
          component={BrochureGenerator}
        />
      }
      {routeConfig.letter &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.letter}
          exact
          path={`${path}/:id/:action(letter)`}
          component={BrochureGenerator}
        />
      }
      {routeConfig['seller-feedback'] &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig['seller-feedback']}
          exact
          path={`${path}/:id/:action(seller-feedback)`}
          component={HTMLPreview}
        />
      }
      {routeConfig['landlord-feedback'] &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig['landlord-feedback']}
          exact
          path={`${path}/:id/:action(landlord-feedback)`}
          component={HTMLPreview}
        />
      }
      {routeConfig.configure &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.configure}
          exact
          path={`${path}/:action(configure)`}
          component={HTMLPreview}
        />
      }
      {routeConfig.brochure &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.brochure}
          exact
          path={`${path}/:action(brochure)`}
          component={BrochureGenerator}
        />
      }
      {routeConfig.view && tabs && routeConfig.view.types &&
        tabs.filter(tab => Object.keys(routeConfig.view.types).includes(tab))
          .map(tab => (
            <PrivateRoute
              key={`rt-${tab}`} {...{ ...props, routeConfig }}
              model={routeConfig.view}
              exact
              path={`${path}/:id/:tab(${tab})${routeConfig.view.types[tab].length ? `/:profiletype(${routeConfig.view.types[tab].join('|')})` : ''}`}
              component={ModelView}
            />
          ))
      }
      {routeConfig.view && tabs && routeConfig.view.tabs &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.view}
          path={`${path}/:id/:tab(${tabs.map(tab => tab).join('|')})/:record_id(\\d+)?`}
          component={ModelView}
        />
      }
      {routeConfig.edit && routeConfig.edit.tabs &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.edit}
          path={`${path}/:id/:action(edit)/:tab(${edit_tabs.map(tab => tab).join('|')})/:record_id(\\d+)?`}
          component={components[getIn(routeConfig, 'edit.component')] || ModelEdit}
        />
      }
      {routeConfig.edit &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.edit}
          exact
          path={`${path}/:id/:action(edit)`}
          component={components[getIn(routeConfig, 'edit.component')] || ModelEdit}
        />
      }
      {routeConfig.duplicate &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.duplicate}
          exact
          path={`${path}/:id/:action(duplicate)`}
          component={components[getIn(routeConfig, 'duplicate.component')] || ModelAdd}
        />
      }
      {routeConfig.valuation &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.valuation}
          exact
          path={`${path}/:id/:action(valuation)`}
          component={ModelAdd}
        />
      }
      {routeConfig.bulkedit &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.bulkedit}
          exact
          path={`${path}/:action(bulkedit)`}
          component={components[getIn(routeConfig, 'bulkedit.component')] || ModelBulkEdit}
        />
      }
      {routeConfig.view &&
        <PrivateRoute
          {...{ ...props, routeConfig }}
          model={routeConfig.view}
          exact
          path={`${path}/:id`}
          component={components[getIn(routeConfig, 'view.component')] || ModelView}
        />
      }
    </Switch>
  )
}

ModelRoute.propTypes = {
  routeConfig: PropTypes.object,
  location: PropTypes.object,
  computedMatch: PropTypes.object,
  path: PropTypes.string
}

export default ModelRoute

