/* eslint-disable no-unused-vars */
import isEqual from 'react-fast-compare'
import merge from 'deepmerge'
import { Formik, getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'

import { handleSubmitError, useCustomCompareMemo } from '../utils'
import { templates } from '../validate/templates'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'
import { Button } from './ui/Button'
import { Scrollbar } from './ui/Scrollbars'


const TemplateEditSidebar = props => {
  const { template, config, actions, list, match, cache } = props
  const { fields, modelname, endpoint, fieldgroups, singular } = config
  const [ initVals, setInitVals ] = useState({})
  const form = useRef()

  useEffect(() => {
    const newInitvals = {}
    if (template) {
      fields.forEach(field => {
        newInitvals[field.name] = getIn(template, field.name)
        if (field.name === 'fields') {
          newInitvals[field.name] = JSON.stringify(newInitvals[field.name]) || '[]'
        }
      })
      setInitVals(newInitvals)
    }
    return () => {
      actions.toggleWideSidebar()
    }
  }, [ useCustomCompareMemo(template) ])

  const handleSubmit = (values, formikBag) => {
    formikBag.setSubmitting(true)
    const clean = { ...values }
    Object.keys(values).forEach(k => {
      const field = fields.find(f => f.name === k)
      if (field && field.input === 'Float') { clean[k] = clean[k] ? parseFloat(clean[k]).toFixed(1) : clean[k] }
      if (field && field.name === 'fields') {
        if (getIn(clean, k)) {
          clean[k] = JSON.parse(getIn(clean, k))
        } else {
          clean[k] = []
        }
      }
      if (clean[k] === null) {
        delete clean[k]
      }
    })
    if (clean.id) {
      return new Promise((resolve, reject) => {
        actions.updateModel({
          values: {
            modelname,
            endpoint,
            ...clean
          }, resolve, reject })
      }).then(r => {
        actions.notifyUser({ title: 'Success!', body: `Your ${singular} was updated.`, type: 'success' })
        formikBag.setSubmitting(false)
        formikBag.setTouched({})
        formikBag.resetForm()
        list.current.refreshPage()
        actions.toggleWideSidebar()
      }).catch(e => {
        handleSubmitError(e, formikBag, form.current)
      })
    }
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-console
      console.log('submitting')
      return actions.createModel({
        values: {
          modelname,
          endpoint,
          ...clean
        }, resolve, reject })
    }).then(r => {
      // eslint-disable-next-line no-console
      console.log(r)
      actions.notifyUser({ title: 'Success!', body: `Your ${singular} was created.`, type: 'success' })
      formikBag.setSubmitting(false)
      formikBag.setTouched({})
      formikBag.resetForm()
      list.current.refreshPage()
      actions.toggleWideSidebar()
    }).catch(e => {
      console.error(e)
      handleSubmitError(e, formikBag, form.current)
    })
  }

  return (
    <div id="template-creator-sidebar" className="wide-sidebar template-creator-sidebar">
      <div className="wide-sidebar-container">
        <Formik
          initialValues={initVals}
          validateOnChange={false}
          validateOnBlur={true}
          onSubmit={handleSubmit}
          enableReinitialize
          validationSchema={templates}
        >{ formik => {
            form.current = formik
            return (
              <CustomForm
                render={() => (
                  <div className="wide-sidebar-pane">
                    <div className="wide-sidebar-heading">
                      <h4>{initVals?.id ? 'Edit' : 'Add'} Template</h4>
                      <Button
                        type="button"
                        icon="#icon24-X-Large"
                        className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                        onClick={() => { actions.toggleWideSidebar() }}
                      />
                    </div>
                    <Scrollbar
                      style={{ height: 'calc(100vh - 218px)' }}
                      renderView={({ style, ...rest }) => <div {...rest} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                    >
                      <div className="wide-sidebar-content">
                        { Object.keys(fieldgroups).map((group, gidx) => (
                          <FieldGroup
                            key={`fs-${gidx}`}
                            groupname={group}
                            group={fieldgroups[group]}
                            gidx={gidx}
                            classes={fieldgroups[group].classes}
                            fields={fields.filter(field => field.group === group).map(f => {
                              const newfield = merge({}, f)
                              if (newfield.createnew) { newfield.createnew = false }
                              if (newfield.twin) { newfield.readonly = true }
                              return newfield
                            })}
                            cache={cache}
                            modelname={modelname}
                            match={match}
                            columns
                            render={({ renderFieldGroup, hidden }) => {
                              if (hidden) { return null }
                              return (
                                <fieldset className="editgroup">
                                  <h5>{group}</h5>
                                  {renderFieldGroup(group)}
                                </fieldset>
                              )
                            }}
                          />
                        ))}
                        <div className="wide-sidebar-footer">
                          <Button
                            type="button" // This cannot be submit otherwise sibling form is submitted
                            onClick={formik.submitForm}
                            disabled={formik.isSubmitting}
                            className="btn btn-primary"
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    </Scrollbar>
                  </div>
                )}
              />
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

TemplateEditSidebar.propTypes = {
  config: PropTypes.object,
  field: PropTypes.object,
  siblingform: PropTypes.object,
  actions: PropTypes.object,
  autofill: PropTypes.object,
  match: PropTypes.object,
  template: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object,
  settings: PropTypes.object,
  list: PropTypes.object,
  sidebar: PropTypes.string,
  selectContact: PropTypes.func,
  toggleLookup: PropTypes.func
}

export default TemplateEditSidebar
