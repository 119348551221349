import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable'


import Loader from '../Loader'
import EmptyRow from '../datatable/EmptyRow'
import Row from '../datatable/Row'


const Body = props => {
  const { data, config, columns, tableconfig, user, portals, contextMenu, doubleClick, loading, match,
    generateClassNames, selected, selectable, selectOne, updateModel, currency, region, sortable, rowActions } = props

  const items = useRef([])
  const [ minconfig ] = useState({
    modelname: config.modelname,
    endpoint: config.endpoint,
    doubleclick: config.doubleclick,
    fields: tableconfig ? tableconfig : config.fields
  })
  const [ minuser ] = useState({ agent: { id: user.agent.id }, permissions: user.permissions })

  useEffect(() => {
    items.current = data && data.length ? data.map(i => i.id) : []
  }, [ data ])

  const outer = []
  if (data && data.length) {
    data.forEach((row, ridx) => { // Loop over model items
      const tr = <Row
        key={ridx}
        ridx={ridx}
        index={ridx}
        row={row}
        config={minconfig}
        portals={portals}
        tableconfig={tableconfig}
        user={minuser}
        generateClassNames={generateClassNames}
        selected={selected}
        selectable={selectable}
        selectOne={selectOne}
        updateModel={({ values, resolve, reject }) => {
          if (values) {
            updateModel({ values, resolve, reject, data, callback: props.refreshPage })
          }
        }}
        match={match}
        currency={currency}
        region={region}
        contextMenu={contextMenu}
        doubleClick={doubleClick}
        sortable={sortable}
        rowActions={rowActions}
      />
      outer.push(tr)
    })
  } else if (loading) {
    outer.push(
      <tr key='loader-row'>
        <td colSpan={columns.length + 1} align="center">
          <div className="tablecell"><Loader inline /></div>
        </td>
      </tr>
    )
  } else {
    outer.push(
      React.createElement(EmptyRow, {
        modelname: config.modelname,
        columns,
        plural: config.plural,
        key: 'empty-row-key'
      })
    )
  }

  if (sortable) {
    const onDragEnd = event => {
      const oldIndex = event.active.data.current.sortable.index
      const newIndex = event.over.data.current.sortable.index
      props.dragEnded({
        oldIndex,
        newIndex,
        data,
        callback: props.refreshPage
      })
    }

    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates
      })
    )

    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        accessibility={{
          container: document.body
        }}
        onDragEnd={onDragEnd}
      >
        <tbody>
          <SortableContext
            strategy={verticalListSortingStrategy}
            items={items.current}
          >
            {outer}
          </SortableContext>
        </tbody>
      </DndContext>
    )
  }

  return (
    <tbody>
      {outer}
    </tbody>
  )
}

Body.propTypes = {
  data: PropTypes.array,
  selected: PropTypes.array,
  config: PropTypes.object,
  tableconfig: PropTypes.array,
  columns: PropTypes.array,
  cache: PropTypes.object,
  portals: PropTypes.object,
  model: PropTypes.object,
  selectOne: PropTypes.func,
  updateModel: PropTypes.func,
  user: PropTypes.object,
  sortable: PropTypes.bool,
  generateClassNames: PropTypes.func,
  refreshPage: PropTypes.func,
  selectable: PropTypes.bool,
  redirectSchema: PropTypes.func,
  dragEnded: PropTypes.func,
  currency: PropTypes.string,
  rowActions: PropTypes.func,
  contextMenu: PropTypes.bool,
  doubleClick: PropTypes.bool,
  loading: PropTypes.bool,
  region: PropTypes.string,
  match: PropTypes.object
}

export default Body

// Body.whyDidYouRender = true
