/* eslint-disable new-cap */
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useRef, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Formik, FieldArray, getIn, setIn } from 'formik'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import merge from 'deepmerge'

import FieldComponent from '../FieldComponent'
import { Button } from '../../../ui/Button'
import { SETTINGS } from '../../../../selectors'
import { valueFormat, useCustomCompareMemo, groupBy, uniqueArray, hasPermission } from '../../../../utils'
import CustomForm from '../CustomForm'
import useWatched from './useWatched'


const has_value = d => {
  let v = 0
  if (d.branch && !d.role.includes('Branch')) {
    v = 1
  }
  if (d.team && !d.role.includes('Team')) {
    v = 1
  }
  if (d.agent && !d.branch && !d.role.includes('Agent')) {
    v = 1
  }
  return v === 0
}

const setInFormat = (destination, idx, value) => {
  Object.keys(value).forEach(k => {
    if ([
      'gross_amount',
      'percentage',
      'nett_amount',
      'share_amount',
      'share_percentage'
    ].includes(k)) {
      value = setIn(value, k, valueFormat('decimal_3', value[k]))
    }
  })
  return setIn(destination, idx, value)
}

const CommissionTableInput = props => {
  const { user } = props

  // eslint-disable-next-line no-unused-vars
  const [ canEdit, setCanEdit ] = useState(hasPermission([ 'deals_update' ], user.permissions))
  const isSeller = hasPermission([ 'deals_update_own' ], user.permissions) && (props.form.values.selling_agents.includes(user.agent.id) || props.form.values.agent === user.agent.id)
  // eslint-disable-next-line no-unused-vars
  const [ isSuper, setIsSuper ] = useState(hasPermission([ 'deals_update_super' ], user.permissions))
  // eslint-disable-next-line no-unused-vars
  const [ isDealFinal, setIsDealFinal ] = useState([ 'Final', 'Closed', 'Cancelled', 'Deleted' ].includes(props.form.values.status))

  const { name, value } = props.field
  const template = useRef({})
  const [ editing, setEditing ] = useState([])
  const [ initVals, setInitVals ] = useState({
    modelname: 'deals',
    id: props.form.values.id,
    commission: {
      ...getIn(props.form.values, 'commission', {}),
      franchise_gross_commission: getIn(props.form.values, 'commission.franchise_gross_commission', 0)
    },
    ...setIn({}, props.field.name, value ? value.map((v, vid) => ({ ...v, idx: vid })) : [])
  })

  useEffect(() => {
    props.form.setFieldValue(props.field.name, getIn(initVals, props.field.name), false)
  }, [ useCustomCompareMemo(initVals) ])

  let selling_branch = getIn(getIn(props.form.values, 'commission.relationships', []).find(b => b && b.role === 'Selling Franchise'), 'branch')
  if (!selling_branch) {
    selling_branch = getIn(props.form.values, 'branch', [])
  }

  const { currency, cache } = props

  const recalculateInputs = ({ values, vat, changed }) => {
    let new_value = []
    // sort records by type
    new_value = merge({}, values).sort((a, b) => (([
      'Paid by Seller',
      'External Deductions',
      'Off the top Deductions',
      'Internal Deductions',
      'Commission Split'
    ].indexOf(a.commission_type)) - (
      [
        'Paid by Seller',
        'External Deductions',
        'Off the top Deductions',
        'Internal Deductions',
        'Commission Split'
      ].indexOf(b.commission_type))
    ))

    let groups = groupBy(new_value, 'commission_type')

    let commission_percentage = getIn(groups, 'Paid by Seller', []).reduce((total, t) => (total + parseFloat(t.percentage || 0)), 0.0)
    if (isNaN(commission_percentage)) {
      commission_percentage = parseFloat(getIn(props.form.initialValues, 'meta.listing.mandate_commission_percentage'))
    }

    let nett_commission = getIn(groups, 'Paid by Seller', []).reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)
    if (isNaN(nett_commission)) {
      nett_commission = (getIn(props.form.initialValues, 'sale_price') * (commission_percentage / 100.0))
    }

    // Recalculate Nett Commission Paid By Seller
    new_value.forEach((v, vid) => {
      if (v.commission_type === 'Paid by Seller') {
        if (!v.input_type) {
          v.input_type = 'percentage'
        }
        const { apply_vat, nett_amount, percentage, input_type } = v
        if (input_type === 'amount') {
          if (v.gross_amount) {
            v.nett_amount = v.gross_amount
            if (apply_vat) {
              v.nett_amount /= (1 + (vat / 100))
            }
          }
          if (v.nett_amount) {
            v.percentage = Math.min((v.nett_amount / getIn(props.form.initialValues, 'sale_price')) * 100, 100.0)
          }
        } else {
          if (percentage) {
            v.gross_amount = getIn(props.form.initialValues, 'sale_price') * (percentage / 100)
          } else if (nett_amount) {
            v.percentage = Math.min((v.nett_amount / getIn(props.form.initialValues, 'sale_price')) * 100, 100.0)
          }
          if (v.gross_amount) {
            v.nett_amount = v.gross_amount
            if (apply_vat) {
              v.nett_amount /= (1 + (vat / 100))
            }
          }
        }
      }
      v.idx = vid
      new_value = setInFormat(new_value, vid, v)
    })
    new_value.forEach((v, vid) => {
      if (v.commission_type === 'External Deductions') {
        if (!v.input_type) {
          v.input_type = 'percentage'
        }
        groups = groupBy(new_value, 'commission_type')
        nett_commission = getIn(groups, 'Paid by Seller', []).reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)
        const { apply_vat, percentage, input_type } = v
        if (input_type === 'amount') {
          if (v.nett_amount) {
            v.gross_amount = v.nett_amount
            if (apply_vat) {
              v.gross_amount *= (1 + (vat / 100))
            }
            v.percentage = Math.min((v.nett_amount / nett_commission) * 100, 100.0)
          }
        } else {
          if (percentage) {
            v.nett_amount = nett_commission * percentage / 100
          }
          if (v.nett_amount) {
            v.gross_amount = v.nett_amount
            if (apply_vat) {
              v.gross_amount = v.nett_amount * (1 + (vat / 100))
            }
          }
        }
        v.idx = vid
        new_value = setInFormat(new_value, vid, v)
      }
    })

    // Recalculate non-seller franchise Off the top Deductions
    new_value.forEach((v, vid) => {
      if (v.commission_type === 'Off the top Deductions' && v.role === 'Referral Franchise') {
        groups = groupBy(new_value, 'commission_type')
        nett_commission = getIn(groups, 'Paid by Seller', []).reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)
        const external_deductions = getIn(groups, 'External Deductions', []).reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)
        const off_the_top_deductions = getIn(groups, 'Off the top Deductions', []).filter(t => t.role === 'Selling Franchise').reduce((total, t) => {
          if (!t.nett_amount && t.percentage) {
            return total + ((nett_commission - external_deductions) * t.percentage / 100)
          }
          return (total + parseFloat(t.nett_amount || 0))
        }, 0.0)
        if (!v.branch) {
          const selected_franchise = getIn(props.cache, `franchises.${v.franchise}`)
          const branch = getIn(groups, 'Internal Deductions', []).find(b => b.branch && b.role === 'Referral Branch' && selected_franchise.branches.includes(b.branch))
          v.branch = branch.branch
        }
        const { apply_vat, nett_amount, percentage, input_type } = v
        if (input_type === 'amount') {
          if (nett_amount) {
            v.gross_amount = v.nett_amount
            if (apply_vat) {
              v.gross_amount *= (1 + (vat / 100))
            }
            v.percentage = Math.min(v.nett_amount / off_the_top_deductions * 100, 100.0)
          }
        } else {
          if (percentage) {
            v.nett_amount = off_the_top_deductions * v.percentage / 100
          }
          if (v.nett_amount) {
            v.gross_amount = v.nett_amount
            if (apply_vat) {
              v.gross_amount *= (1 + (vat / 100))
            }
          }
        }
        v.idx = vid
        new_value = setInFormat(new_value, vid, v)
      }
    })
    new_value.forEach((v, vid) => {
      if (v.commission_type === 'Off the top Deductions' && v.role !== 'Referral Franchise') {
        groups = groupBy(new_value, 'commission_type')
        nett_commission = getIn(groups, 'Paid by Seller', []).reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)
        const external_deductions = getIn(groups, 'External Deductions', []).reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)
        const off_the_top_deductions = getIn(groups, 'Off the top Deductions', []).filter(t => t.role === 'Referral Franchise').reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)
        if (v.role === 'Selling Franchise' && v.branch !== props.form.values.branch) {
          v.branch = props.form.values.branch
        }
        const { nett_amount, percentage, input_type } = v
        if (input_type === 'amount') {
          if (nett_amount) {
            if (v.branch === selling_branch && v.role === 'Selling Franchise') {
              v.gross_amount = v.nett_amount - off_the_top_deductions
              if (v.apply_vat) {
                v.gross_amount *= (1 + (vat / 100))
              }
            } else if (v.apply_vat) {
              v.gross_amount = v.nett_amount * (1 + (vat / 100))
            } else {
              v.gross_amount = v.nett_amount
            }
            v.percentage = Math.min((v.nett_amount / nett_commission) * 100, 100.0)
          }
        } else {
          if (percentage) {
            v.nett_amount = (nett_commission - external_deductions) * (v.percentage / 100)
          }
          if (v.nett_amount) {
            if (v.branch === selling_branch && v.role === 'Selling Franchise') {
              v.gross_amount = v.nett_amount - off_the_top_deductions
              if (v.apply_vat) {
                v.gross_amount *= (1 + (vat / 100))
              }
            } else if (v.apply_vat) {
              v.gross_amount = v.nett_amount * (1 + (vat / 100))
            } else {
              v.gross_amount = v.nett_amount
            }
          }
        }
        v.idx = vid
        new_value = setInFormat(new_value, vid, v)
      }
    })
    // Recalculate internal agent splits
    new_value.forEach((v, vid) => {
      if (v.commission_type === 'Internal Deductions' && v.branch && v.agent) {
        if (!v.input_type) {
          v.input_type = 'percentage'
        }
        groups = groupBy(new_value, 'commission_type')
        nett_commission = getIn(groups, 'Paid by Seller', []).reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)
        const branch_franchise = getIn(groups, 'Off the top Deductions', []).find(b => b.branch === v.branch)
        const branch_record = getIn(groups, 'Internal Deductions', []).find(b => b.branch === v.branch && !b.agent)
        const branch_amount = (
          parseFloat(branch_record?.nett_amount) || 0
        ) - (
          parseFloat(branch_franchise?.nett_amount) || 0
        )
        const { apply_vat, nett_amount, input_type, deduct_franchise_fee } = v
        if ((!v.share_amount && v.share_percentage) || changed?.name.includes('share_percentage') || changed?.name.includes('deduct_franchise_fee') || changed?.aidx === branch_record.idx || !changed) {
          if (!deduct_franchise_fee) {
            v.share_amount = parseFloat((branch_record?.nett_amount) || 0) * parseFloat(v.share_percentage) / 100
          } else {
            v.share_amount = branch_amount * v.share_percentage / 100
          }
        }
        if ((v.share_amount && !v.share_percentage) || changed?.name.includes('share_amount') || changed?.aidx === branch_record.idx || !changed) {
          if (!deduct_franchise_fee) {
            v.share_percentage = Math.min(parseFloat(v.share_amount) /
              parseFloat((branch_record?.nett_amount) || 0) * 100, 100.0)
          } else {
            v.share_percentage = Math.min(parseFloat(v.share_amount) / (
              parseFloat((branch_record?.nett_amount) || 0) - parseFloat((branch_franchise?.nett_amount || 0))
            ) * 100, 100.0)
          }
        }
        if (input_type === 'amount') {
          if (nett_amount) {
            v.gross_amount = v.nett_amount
            if (apply_vat) {
              v.gross_amount *= (1 + (vat / 100))
            }
            v.percentage = Math.min((v.nett_amount / (branch_record?.nett_amount) || 0) * 100, 100.0)
          }
        } else {
          // eslint-disable-next-line max-len
          v.nett_amount = (v.share_amount) * (v.percentage / 100)
          v.gross_amount = v.nett_amount
          if (apply_vat) {
            v.gross_amount *= (1 + (vat / 100))
          }
        }
        v.idx = vid
        new_value = setInFormat(new_value, vid, v)
      }
    })
    // Recalculate branch + other internal splits
    new_value.forEach((v, vid) => {
      if (v.commission_type === 'Internal Deductions' && ((v.branch && !v.agent) || (!v.branch && !v.agent))) {
        groups = groupBy(new_value, 'commission_type')
        nett_commission = getIn(groups, 'Paid by Seller', []).reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)
        const external_deductions = getIn(groups, 'External Deductions', []).reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)
        const branch_franchise = getIn(groups, 'Off the top Deductions', []).find(b => b.branch === v.branch)
        const agent_deductions = getIn(groups, 'Internal Deductions', []).filter(a => a.branch === v.branch && a.agent).reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)

        const nett_comm = (nett_commission - external_deductions)
        const { apply_vat, nett_amount, percentage, input_type, deduct_franchise_fee } = v

        if (input_type === 'amount') {
          if (nett_amount) {
            v.gross_amount = (v.nett_amount - agent_deductions)
            if (apply_vat) {
              v.gross_amount *= (1 + (vat / 100))
            }
            if (deduct_franchise_fee) {
              v.gross_amount -= branch_franchise?.nett_amount || 0
            }
            v.percentage = Math.min((v.nett_amount / nett_comm) * 100, 100.0)
          }
        } else {
          if (percentage) {
            v.nett_amount = nett_comm * v.percentage / 100
          }
          if (v.nett_amount) {
            v.gross_amount = (v.nett_amount - agent_deductions)
            if (apply_vat) {
              v.gross_amount *= (1 + (vat / 100))
            }
          }
          if (deduct_franchise_fee) {
            v.gross_amount -= branch_franchise?.nett_amount || 0
          }
        }
        if (!v.input_type) {
          v.input_type = 'percentage'
        }
        new_value = setInFormat(new_value, vid, v)
      }
    })
    // Recalculate agent comm splits
    new_value.forEach((v, vid) => {
      if (v.commission_type === 'Commission Split' && v.branch && v.agent) {
        if (!v.input_type) {
          v.input_type = 'percentage'
        }
        groups = groupBy(new_value, 'commission_type')
        const nett_commission_group = getIn(groups, 'Paid by Seller', [])
        const external_deductions_group = getIn(groups, 'External Deductions', [])

        const branch_franchise = getIn(groups, 'Off the top Deductions', []).find(b => b.branch === v.branch && b.role.includes('Franchise'))

        const external_deductions = external_deductions_group
          .reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)
        const ott_deductions = getIn(groups, 'Off the top Deductions', [])
          .reduce((total, t) => {
            if (t.franchise && t.branch !== selling_branch) {
              return total - parseFloat(t.nett_amount || 0)
            }
            return (total + parseFloat(t.nett_amount || 0))
          }, 0.0)
        const nett_comm_total = nett_commission_group
          .reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)

        const internal_deductions = getIn(groups, 'Internal Deductions', []).filter(has_value).map(t => ({
          nett_amount: (nett_comm_total - external_deductions) * parseFloat(t.percentage) / 100
        })).reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)

        const nett_comm = nett_comm_total - external_deductions - ott_deductions - internal_deductions

        const { apply_vat, nett_amount, input_type, deduct_franchise_fee } = v

        if ((!v.share_amount && v.share_percentage) || changed?.name.includes('share_percentage') || changed?.name.includes('deduct_franchise_fee') || !changed) {
          if (!deduct_franchise_fee) {
            v.share_amount = (nett_comm + parseFloat(branch_franchise?.gross_amount || 0)) * v.share_percentage / 100.0
          } else {
            v.share_amount = (nett_comm) * v.share_percentage / 100
          }
        }
        if ((v.share_amount && !v.share_percentage) || changed?.name.includes('share_amount') || !changed) {
          if (!deduct_franchise_fee) {
            v.share_percentage = Math.min(parseFloat(v.share_amount) / nett_comm * 100, 100.0)
          } else {
            v.share_percentage = Math.min(parseFloat(v.share_amount) /
              (nett_comm - parseFloat(branch_franchise?.nett_amount || 0)) * 100, 100.0)
          }
        }
        if (input_type === 'amount') {
          if (nett_amount) {
            if (apply_vat) {
              v.gross_amount = v.nett_amount * (1 + (vat / 100))
            } else {
              v.gross_amount = v.nett_amount
            }
            v.percentage = Math.min((v.nett_amount / v.share_amount) * 100, 100.0)
          }
        } else {
          // eslint-disable-next-line max-len
          v.nett_amount = (v.share_amount) * (v.percentage / 100)
          v.gross_amount = v.nett_amount
          if (apply_vat) {
            v.gross_amount *= (1 + (vat / 100))
          }
        }
        new_value = setInFormat(new_value, vid, v)
      }
    })
    // Recalculate agent comm splits
    new_value.forEach((v, vid) => {
      if (v.commission_type === 'Commission Split' && v.branch && !v.agent) {
        if (!v.input_type) {
          v.input_type = 'percentage'
        }
        groups = groupBy(new_value, 'commission_type')
        nett_commission = getIn(groups, 'Paid by Seller', []).reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)
        const nett_total_deductions = (
          getIn(groups, 'External Deductions', []).reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0) +
          getIn(groups, 'Off the top Deductions', [])
            .reduce((total, t) => {
              if (t.franchise && t.branch !== selling_branch) {
                return total - parseFloat(t.nett_amount || 0)
              }
              return (total + parseFloat(t.nett_amount || 0))
            }, 0.0) +
          getIn(groups, 'Internal Deductions', []).filter(has_value).reduce((total, t) => total + parseFloat(t.nett_amount || 0), 0.0)
        )
        const total_agent_deductions = getIn(groups, 'Commission Split', []).filter(t => t.agent).reduce((total, t) => (total + parseFloat(t.nett_amount || 0)), 0.0)
        v.nett_amount = nett_commission - nett_total_deductions - total_agent_deductions
        v.gross_amount = v.nett_amount
        if (v.apply_vat) {
          v.gross_amount *= (1 + (vat / 100))
        }
        new_value = setInFormat(new_value, vid, v)
      }
    })
    return new_value
  }

  useEffect(() => {
    const sellers = getIn(props, 'form.values.sellers', []) || []
    const buyers = getIn(props, 'form.values.sellers', []) || []
    const selling_agents = getIn(props, 'form.values.selling_agents', [])
    const referral_contacts = [ ...sellers, ...buyers ]
    const vat = parseFloat(getIn(props.form.initialValues, 'commission.vat', '15.0'))

    if (props.field.value) {
      const franchise_ids = props.field.value.filter(d => d.franchise).map(d => d.franchise)
      const branch_ids = props.field.value.filter(d => d.branch).map(d => d.branch)
      const agent_ids = props.field.value.filter(d => d.agent).map(d => d.agent)
      const team_ids = props.field.value.filter(d => d.team).map(d => d.team)

      props.actions.fetchMany({
        values: {
          modelname: 'franchises',
          params: {
            id__in: franchise_ids
          }
        }
      })
      props.actions.fetchMany({
        values: {
          modelname: 'branches',
          params: {
            id__in: branch_ids
          }
        }
      })
      props.actions.fetchMany({
        values: {
          modelname: 'agents',
          params: {
            id__in: agent_ids
          }
        }
      })
      props.actions.fetchMany({
        values: {
          modelname: 'teams',
          params: {
            id__in: team_ids
          }
        }
      })
    }

    const fetch_franchise = new Promise((resolve, reject) => props.actions.fetchMany({
      values: {
        modelname: 'franchises',
        select: true,
        params: {
          branches__in: props.form.values.branch,
          franchise_fee_payable: true
        }
      },
      resolve,
      reject
    }))

    const fetch_referrals = new Promise((resolve, reject) => props.actions.fetchMany({
      values: {
        modelname: 'referrals',
        select: true,
        params: {
          expiry_date__gte: valueFormat('shortdate', getIn(props, 'form.values.created', [])),
          created_contact__in: referral_contacts
        }
      },
      resolve,
      reject
    }))

    const fetch_agents = new Promise((resolve, reject) => props.actions.fetchMany({
      values: {
        modelname: 'agents',
        select: true,
        params: {
          id__in: selling_agents
        }
      },
      resolve,
      reject
    }))

    Promise.allSettled([ fetch_franchise, fetch_referrals, fetch_agents ]).then(results => {
      const franchise_options = getIn(results, '0.value.options', [])
      const referral_options = getIn(results, '1.value.options', [])
      const sales_agents = getIn(results, '2.value.options', [])
      let off_the_top = []
      const referrals = []
      let new_value = []
      if (!props.field.value || !props.field.value.length) {
        const seller_values = sellers.map((sid, sidx) => {
          const percentage = parseFloat(getIn(props.form.initialValues, 'meta.listing.mandate_commission_percentage'))
          const gross_amount = percentage ? getIn(props.form.initialValues, 'sale_price') * (percentage / 100.0) : 0
          const nett_amount = gross_amount / (1 + (vat / 100))
          if (!sidx) {
            return {
              commission_type: 'Paid by Seller',
              role: 'Seller',
              percentage: valueFormat('decimal', percentage),
              nett_amount: valueFormat('decimal', nett_amount),
              apply_vat: true,
              gross_amount: valueFormat('decimal', gross_amount),
              contact: sid
            }
          }
          return { commission_type: 'Paid by Seller', role: 'Seller', contact: sid }
        })
        if (franchise_options.length) {
          const new_franchise = franchise_options[0]
          const percentage = getIn(new_franchise, 'gross_commission')
          const grseller = seller_values.reduce((total, v) => (total + parseFloat(v.nett_amount || 0)), 0.0)
          const nett_amount = percentage ? (grseller) * (parseFloat(percentage) / 100.0) : 0
          const gross_amount = nett_amount + (nett_amount * (vat / 100))

          off_the_top = [ {
            commission_type: 'Off the top Deductions',
            role: 'Selling Franchise',
            branch: props.form.values.branch,
            percentage: valueFormat('decimal', percentage),
            nett_amount: valueFormat('decimal', nett_amount),
            apply_vat: true,
            gross_amount: valueFormat('decimal', gross_amount),
            franchise: new_franchise.id
          } ]
          if (!initVals.commission.franchise_gross_commission && new_franchise.gross_commission) {
            initVals.commission.franchise_gross_commission = new_franchise.gross_commission
            props.form.setFieldValue('commission.franchise_gross_commission', new_franchise.gross_commission)
          }
        }
        if (referral_options.length) {
          referral_contacts.map(cid => {
            const referral = referral_options.find(ref => ref.created_contact === cid)
            if (referral) {
              referrals.push({
                commission_type: 'Internal Deductions',
                role: 'Referral Branch',
                branch: referral.sending_branch
              })
              referrals.push({
                commission_type: 'Internal Deductions',
                role: 'Referral Agent',
                branch: referral.sending_branch, agent: referral.sending_agent
              })
            }
            return null
          }).filter(v => v)
        }

        new_value = [
          ...seller_values,
          ...off_the_top,
          ...referrals
        ].map((v, vix) => {
          v.idx = vix
          return v
        })

        const groups = groupBy(new_value, 'commission_type')
        const nett_commission = getIn(groups, 'Paid by Seller', []).reduce((total, v) => (total + parseFloat(v.nett_amount || 0)), 0.0)
        const nett_total_deductions = (
          getIn(groups, 'External Deductions', []).reduce((total, v) => (total + parseFloat(v.nett_amount || 0)), 0.0) +
          getIn(groups, 'Off the top Deductions', []).reduce((total, v) => {
            if (v.franchise && v.branch !== selling_branch) {
              return 0.0
            }
            return (total + parseFloat(v.nett_amount || 0))
          }, 0.0) +
          getIn(groups, 'Internal Deductions', []).filter(has_value).reduce((total, v) => (total + parseFloat(v.nett_amount || 0)), 0.0)

        )
        const nett_commission_remaining = nett_commission - nett_total_deductions

        let remaining_commission = 100.0
        const commission_agents = selling_agents.map(aid => {
          const agent = sales_agents.find(a => a.id === aid)
          const percentage = agent.commission_split ? parseFloat(agent.commission_split) / selling_agents.length : 0
          const nett_amount = nett_commission_remaining * (percentage / 100)
          let gross_amount = nett_amount
          if (agent.deduct_franchise_fee) {
            gross_amount -= (gross_amount * (initVals.commission.franchise_gross_commission / 100))
          }
          gross_amount += (gross_amount * (vat / 100))
          remaining_commission -= percentage
          return {
            agent: aid,
            branch: selling_branch,
            commission_type: 'Commission Split',
            role: 'Selling Agent',
            deduct_franchise_fee: agent.deduct_franchise_fee,
            percentage: valueFormat('decimal', percentage),
            apply_vat: true,
            nett_amount: valueFormat('decimal', nett_amount),
            gross_amount: valueFormat('decimal', gross_amount)
          }
        })
        const remaining_nett = nett_commission_remaining * (remaining_commission / 100)
        let remaining_gross = remaining_nett - (
          remaining_nett * ((initVals.commission.franchise_gross_commission / 100))
        )
        remaining_gross += (remaining_gross * (vat / 100))
        const commission_branch = [ {
          branch: selling_branch,
          commission_type: 'Commission Split',
          role: 'Selling Branch',
          deduct_franchise_fee: true,
          percentage: valueFormat('decimal', remaining_commission),
          apply_vat: true,
          nett_amount: valueFormat('decimal', remaining_nett),
          gross_amount: valueFormat('decimal', remaining_gross)
        } ]
        new_value = [
          ...seller_values,
          ...off_the_top,
          ...referrals,
          ...commission_branch,
          ...commission_agents
        ].map((v, vix) => {
          v.idx = vix
          return v
        })

        setEditing(new_value)
      } else {
        if (franchise_options.length) {
          const new_franchise = franchise_options[0]
          if (!initVals.commission.franchise_gross_commission && new_franchise.gross_commission) {
            initVals.commission.franchise_gross_commission = new_franchise.gross_commission
            props.form.setFieldValue('commission.franchise_gross_commission', new_franchise.gross_commission)
          }
        }
        new_value = recalculateInputs({ values: value, vat })
      }
      props.form.setFieldValue(name, new_value).then(() => {
        setInitVals(setIn(initVals, props.field.name, new_value))
      })
    })
  }, [])

  const renderArrayFields = (idx, form, watched) => {
    const { errors, values } = form
    const outer = Array.isArray(watched[idx]) ? watched[idx].filter(field => {
      if (props.show_required) { return field._edit === 'true' && field._required === 'true' }
      if (props.required) { return field._edit === 'true' && field._required === 'true' }
      return field._edit === 'true'
    }) : null
    if (outer) {
      const outer_hidden = outer.filter(field => field.input === 'Hidden').map((field, fidx) => (
        <FieldComponent {...props} key={`fc-${props.field.name}-${idx}-${fidx}`} field={field} errors={errors} />
      ))
      const outer_visible = outer.filter(field => field.input !== 'Hidden').map((field, fidx) => {
        if (field.name.indexOf('.price_per') > -1) {
          field.label = `Price Per ${valueFormat('unit', values.floor_size_measurement_type)}`
        }
        if (props.input === 'TranslatableTextArea') {
          if (field.name.indexOf('language') > -1 && field.input === 'Select') {
            const already_selected = getIn(props.form.values, props.field.name, []).map(v => v.language)
            const options = props.settings.meta.allowed_translations
              .map(lang => ({ value: lang.code, label: lang.name, isDisabled: already_selected.includes(lang.code) }))
            field.options = options
          }
          if (field.name.indexOf('content') > -1 && field.input === 'TextArea') {
            const language = getIn(props.form.values, field.name.replace('.content', '.language'), {})
            if (language) {
              const option = props.settings.meta.allowed_translations
                .find(lang => language === lang.code)
              field.label = field.label.replace('Translated', option.name)
              field.lang = option.code
              if (option.rtl) {
                field.rtl = true
              }
            }
          }
        }
        if (field.name.includes('.percentage') || field.name.includes('.nett_amount') || field.name.includes('.gross_amount')) {
          const name_parts = field.name.split('.')
          const input_type = name_parts.pop().replace('nett_', '').replace('gross_', '')
          name_parts.push('input_type')
          const button_name = name_parts.join('.')
          const commission_type = getIn(values, button_name.replace('.input_type', '.commission_type'))
          if (commission_type === 'Paid by Seller') {
            if (!field.name.includes('.nett_amount')) {
              field.suffix = (
                <Button
                  type="button"
                  title={`Base calculations off ${input_type}`}
                  className="btn btn-none btn-icon btn-icon-only btn-icon-16 input-group-addon"
                  icon={getIn(values, button_name) === input_type ? '#icon16-Lock' : '#icon16-Unlock'}
                  onClick={() => {
                    form.setFieldValue(button_name, input_type)
                  }}
                />
              )
            }
          } else if (!field.name.includes('.gross_amount')) {
            field.suffix = (
              <Button
                type="button"
                title={`Base calculations off ${input_type}`}
                className="btn btn-none btn-icon btn-icon-only btn-icon-16 input-group-addon"
                icon={getIn(values, button_name) === input_type ? '#icon16-Lock' : '#icon16-Unlock'}
                onClick={() => {
                  form.setFieldValue(button_name, input_type)
                }}
              />
            )
          }
        }
        return (
          <td
            key={`field-${props.field.name}-${idx}-${fidx}`}
            className={classNames('field', { error: (field.name in errors), required: field._required === 'true' }, field.cols ? `col-${field.cols}` : '')}
          >
            <div className="tablecell">
              <FieldComponent {...props} form={form} key={`fc-${props.field.name}-${idx}-${fidx}`} field={field} errors={errors} />
            </div>
          </td>
        )
      })
      return { outer_hidden, outer_visible }
    }
    return { outer_hidden: null, outer_visible: null }
  }

  useEffect(() => {
    props.fields.forEach(field => { template.current[field.name] = getIn(field, 'defaultvalue', null) })
    if (!props.field.value || (Array.isArray(props.field.value) && !props.field.value.length)) {
      if (props.required) {
        // If the field is required, show fields by default
        props.form.setFieldValue(props.field.name, [ template.current ], false)
      }
    }
  }, [])
  return (
    <Formik
      initialValues={initVals}
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={true}
      onSubmit={(values, actions) => {
        const valid = merge({}, values)
        const new_value = getIn(valid, name)
          .filter(v => !editing.map(x => x.idx).includes(v.idx))
        let update = setIn({
          modelname: 'deals',
          id: props.form.values.id
        }, name, new_value)
        update = setIn(update, 'commission.franchise_gross_commission', props.form.values.commission.franchise_gross_commission)
        return new Promise((resolve, reject) => {
          props.actions.updateModel({ values: update, autosaved: false, resolve, reject })
        }).then(() => {
          actions.setSubmitting(false)
        }).catch(e => {
          console.error(e)
        })
      }}
    >{formik => {
        const watched = useWatched({
          ...props,
          field: {
            ...props.field,
            value: getIn(formik.values, 'commission.relationships')
          },
          form: formik
        }, { fields: props.fields }, true)
        return (
          <CustomForm
            id="content"
            component="div"
            onChange={changes => {
              if (Object.keys(formik.touched).length > 0) { // Only fire when touched
                changes.forEach(changedfield => {
                  if (
                    getIn(formik.touched, changedfield) &&
                  ![ undefined ].includes(getIn(formik.values, changedfield))
                  ) {
                    watched.map(fields => fields.find(f => {
                      if (f.name === changedfield && f.onchange) {
                        return f
                      }
                      if (changedfield.match(/\.\d+\./gi)) {
                        const parts = changedfield.split(/\.\d+\./gi)
                        if (f.name === parts[0] && f.fields) {
                          const field = f.fields.find(fe => fe.name === parts[1])
                          if (field) {
                            return {
                              ...field,
                              name: changedfield
                            }
                          }
                        }
                      }
                      return null
                    })).filter(cb => cb).forEach(cb => {
                      const vat = parseFloat(getIn(props.form.initialValues, 'commission.vat', '15.0'))
                      const new_value = recalculateInputs({ values: getIn(formik.values, 'commission.relationships'), vat, changed: cb })
                      formik.setFieldValue(name, new_value)
                    })
                  }
                })
              }
            }}
            render={() => (
              <FieldArray
                name={name}
                render={({ insert, remove }) => {
                  const groups = getIn(formik, `values.${name}`) ? groupBy(getIn(formik, `values.${name}`), 'commission_type') : []
                  const nett_commission = getIn(groups, 'Paid by Seller', []).reduce((total, v) => (total + parseFloat(v.nett_amount || 0)), 0.0)
                  const external_deductions = getIn(groups, 'External Deductions', []).reduce((total, v) => (total + parseFloat(v.nett_amount || 0)), 0.0)
                  const ott_deductions = getIn(groups, 'Off the top Deductions', []).reduce((total, v) => {
                    if (v.franchise && v.branch !== selling_branch) {
                      return total - parseFloat(v.nett_amount || 0)
                    }
                    return (total + parseFloat(v.nett_amount || 0))
                  }, 0.0)
                  const internal_deductions = getIn(groups, 'Internal Deductions', []).filter(has_value).reduce((total, v) => total + parseFloat(v.nett_amount || 0), 0.0)
                  const nett_total_deductions = (
                    external_deductions +
                  ott_deductions +
                  internal_deductions
                  )
                  const group_commission = nett_commission - getIn(groups, 'External Deductions', []).reduce((total, v) => (total + parseFloat(v.nett_amount || 0)), 0.0)
                  const total_splits = valueFormat('currency', (
                    getIn(groups, 'Commission Split', []).reduce((total, v) => (total + parseFloat(v.nett_amount || 0)), 0.0)
                  ), { currency })
                  const nett_commission_remaining = valueFormat('currency', (nett_commission - nett_total_deductions), { currency })

                  return (
                    <div className="datatablewrap">
                      <table className="datatable commission-slip-table">
                        <tbody>
                          <tr>
                            <th colSpan={2}><div className="heading-wrapper">Sales Commission Paid by Seller</div></th>
                            <th><div className="heading-wrapper">Role</div></th>
                            <th colSpan={2}><div className="heading-wrapper" /></th>
                            <th className="text-center"><div className="heading-wrapper">%</div></th>
                            <th className="text-right"><div className="heading-wrapper">Nett Total</div></th>
                            <th className="text-center"><div className="heading-wrapper">VAT</div></th>
                            <th className="text-right"><div className="heading-wrapper">Gross Total</div></th>
                            <th className="column-actions text-right">
                              <div className="heading-wrapper">
                                {((canEdit || isSeller) && !isDealFinal) || isSuper ? (
                                  <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Plus" onClick={() => {
                                    const group = getIn(groups, 'Paid by Seller', [])
                                    let idx = 0
                                    if (group) {
                                      idx = group[group.length - 1]?.idx + 1 || 0
                                    }
                                    const vat = parseFloat(getIn(props.form.initialValues, 'commission.vat', '15.0'))
                                    const percentage = parseFloat(getIn(props.form.initialValues, 'meta.listing.mandate_commission_percentage')) / value.length + 1
                                    const nett_amount = percentage ? getIn(props.form.initialValues, 'sale_price') * (percentage / 100.0) : 0
                                    const gross_amount = nett_amount + (nett_amount * (vat / 100))
                                    const new_val = {
                                      commission_type: 'Paid by Seller',
                                      role: 'Seller',
                                      input_type: 'percentage',
                                      editing: true,
                                      idx,
                                      percentage: valueFormat('decimal', percentage),
                                      nett_amount: valueFormat('decimal', nett_amount),
                                      gross_amount: valueFormat('decimal', gross_amount)
                                    }
                                    insert(idx, new_val)
                                  }} />
                                ) : null }
                              </div>
                            </th>
                          </tr>
                          {getIn(formik, `values.${name}`).map((val, idx) => {
                            if (val.commission_type !== 'Paid by Seller') {
                              return null
                            }
                            const { outer_hidden, outer_visible } = renderArrayFields(idx, formik, watched)

                            let title = val.name
                            if (val.contact) {
                              title = [ getIn(cache, `contacts.${val.contact}.first_name`), getIn(cache, `contacts.${val.contact}.last_name`) ].filter(v => v).join(' ')
                            }
                            return val.editing ? (
                              <tr key={`pbs-${idx}`}>
                                {outer_visible}
                                <td className="column-actions text-right">
                                  <div className="tablecell">
                                    {outer_hidden}
                                    <Button type="button" className="btn btn-small btn-green" onClick={() => {
                                      const new_editing = editing.filter(x => x.idx !== idx)
                                      setEditing(new_editing)
                                      const vat = parseFloat(getIn(props.form.initialValues, 'commission.vat', '15.0'))
                                      const new_value = recalculateInputs({ values: getIn(formik.values, 'commission.relationships'), vat })
                                      new_value[idx].editing = false
                                      formik.setFieldValue(name, new_value).then(() => {
                                        setInitVals(setIn(initVals, props.field.name, new_value))
                                      })
                                    }}>Update</Button>
                                    <Button type="button" className="btn btn-small btn-red" onClick={() => {
                                      const initial = editing.find(x => x.idx === idx)
                                      const new_editing = editing.filter(x => x.idx !== idx)
                                      const vat = parseFloat(getIn(props.form.initialValues, 'commission.vat', '15.0'))
                                      if (initial) {
                                        formik.setFieldValue(`commission.relationships.${idx}`, initial).then(() => {
                                          recalculateInputs({ values: getIn(formik.values, 'commission.relationships'), vat })
                                        })
                                      } else {
                                        remove(idx)
                                      }
                                      setEditing(new_editing)
                                    }}>Cancel</Button>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <tr key={`pbs-${idx}`}>
                                <td><div className="tablecell">{title}</div></td>
                                <td><div className="tablecell">{val.deduct_franchise_fee}</div></td>
                                <td><div className="tablecell">{val.role}</div></td>
                                <td colSpan={2}></td>
                                <td className="text-center"><div className="tablecell">{valueFormat('number', val.percentage)}</div></td>
                                <td className="text-right"><div className="tablecell">{valueFormat('currency', val.nett_amount, { currency })}</div></td>
                                <td className="text-center"><div className="tablecell">{val.apply_vat ? valueFormat('yesno', val.apply_vat || false) : '-'}</div></td>
                                <td className="text-right"><div className="tablecell">{valueFormat('currency', val.gross_amount, { currency })}</div></td>
                                <td className="column-actions text-right">
                                  <div className="tablecell">
                                    {((canEdit || isSeller) && !isDealFinal) || isSuper ? (
                                      <>
                                        <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Edit" onClick={() => {
                                          formik.setFieldValue(`commission.relationships.${idx}.editing`, true)
                                          setEditing([ ...editing, val ])
                                        }} />
                                        <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Bin" onClick={() => {
                                          const current = getIn(formik.values, name)
                                          const new_values = current
                                            .filter((c, cix) => cix !== idx).map((c, cid) => ({ ...c, idx: cid }))
                                          setInitVals(setIn(initVals, name, new_values))
                                          remove(idx)
                                        }} />
                                      </>
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                          <tr>
                            <th colSpan={2}><div className="heading-wrapper">External Deductions</div></th>
                            <th><div className="heading-wrapper">Role</div></th>
                            <th colSpan={2}><div className="heading-wrapper" /></th>
                            <th className="text-center"><div className="heading-wrapper">%</div></th>
                            <th className="text-right"><div className="heading-wrapper">Nett Total</div></th>
                            <th className="text-center"><div className="heading-wrapper">VAT</div></th>
                            <th className="text-right"><div className="heading-wrapper">Gross Total</div></th>
                            <th className="column-actions text-right">
                              <div className="heading-wrapper">
                                {((canEdit || isSeller) && !isDealFinal) || isSuper ? (
                                  <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Plus" onClick={() => {
                                    const group = getIn(groups, 'External Deductions', [])
                                    let idx = 0
                                    if (group) {
                                      idx = group[group.length - 1]?.idx + 1 || 0
                                    }
                                    const new_val = {
                                      commission_type: 'External Deductions',
                                      role: 'Referral Branch',
                                      idx,
                                      editing: true,
                                      input_type: 'percentage',
                                      percentage: 0,
                                      nett_amount: 0,
                                      gross_amount: 0
                                    }
                                    insert(idx, new_val)
                                  }} />
                                ) : null}
                              </div>
                            </th>
                          </tr>
                          {getIn(formik, `values.${name}`).map((val, idx) => {
                            if (val.commission_type !== 'External Deductions') {
                              return null
                            }
                            const { outer_hidden, outer_visible } = renderArrayFields(idx, formik, watched)
                            const title = val.name
                            return val.editing ? (
                              <tr key={`pbs-${idx}`}>
                                {outer_visible}
                                <td className="column-actions text-right">
                                  <div className="tablecell">
                                    {outer_hidden}
                                    <Button type="button" className="btn btn-small btn-green" onClick={() => {
                                      const new_editing = editing.filter(x => x.idx !== idx)
                                      setEditing(new_editing)
                                      const vat = parseFloat(getIn(props.form.initialValues, 'commission.vat', '15.0'))
                                      const new_value = recalculateInputs({ values: getIn(formik.values, 'commission.relationships'), vat })
                                      new_value[idx].editing = false
                                      formik.setFieldValue(name, new_value).then(() => {
                                        setInitVals(setIn(initVals, props.field.name, new_value))
                                      })
                                    }}>Update</Button>
                                    <Button type="button" className="btn btn-small btn-red" onClick={() => {
                                      const initial = editing.find(x => x.idx === idx)
                                      const new_editing = editing.filter(x => x.idx !== idx)
                                      const vat = parseFloat(getIn(props.form.initialValues, 'commission.vat', '15.0'))
                                      if (initial) {
                                        formik.setFieldValue(`commission.relationships.${idx}`, initial).then(() => {
                                          recalculateInputs({ values: getIn(formik.values, 'commission.relationships'), vat })
                                        })
                                      } else {
                                        remove(idx)
                                      }
                                      setEditing(new_editing)
                                    }}>Cancel</Button>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <tr key={`pbs-${idx}`}>
                                <td><div className="tablecell">{title}</div></td>
                                <td><div className="tablecell">{val.deduct_franchise_fee}</div></td>
                                <td><div className="tablecell">{val.role}</div></td>
                                <td colSpan={2}></td>
                                <td className="text-center"><div className="tablecell">{valueFormat('number', val.percentage)}</div></td>
                                <td className="text-right"><div className="tablecell">{valueFormat('currency', val.nett_amount, { currency })}</div></td>
                                <td className="text-center"><div className="tablecell">{val.apply_vat ? valueFormat('yesno', val.apply_vat || false) : '-'}</div></td>
                                <td className="text-right"><div className="tablecell">{valueFormat('currency', val.gross_amount, { currency })}</div></td>
                                <td className="column-actions text-right">
                                  <div className="tablecell">
                                    {((canEdit || isSeller) && !isDealFinal) || isSuper ? (
                                      <>
                                        <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Edit" onClick={() => {
                                          formik.setFieldValue(`commission.relationships.${idx}.editing`, true)
                                          setEditing([ ...editing, val ])
                                        }} />
                                        <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Bin" onClick={() => {
                                          const current = getIn(formik.values, name)
                                          const new_values = current
                                            .filter((c, cix) => cix !== idx).map((c, cid) => ({ ...c, idx: cid }))
                                          setInitVals(setIn(initVals, name, new_values))
                                          remove(idx)
                                        }} />
                                      </>
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                          <tr className="primary-bg">
                            <th colSpan={2}><div className="heading-wrapper">Total Group Commission</div></th>
                            <th></th>
                            <th colSpan={2}></th>
                            <th className="text-center"><div className="tablecell">-</div></th>
                            <th className="text-right"><div className="heading-wrapper">{valueFormat('currency', group_commission, { currency })}</div></th>
                            <th className="text-center"><div className="tablecell">-</div></th>
                            <th className="text-right"><div className="tablecell">-</div></th>
                            <th></th>
                          </tr>
                          <tr>
                            <th colSpan={2}><div className="heading-wrapper">Off-the-top Deductions</div></th>
                            <th><div className="heading-wrapper">Role</div></th>
                            <th colSpan={2}><div className="heading-wrapper" /></th>
                            <th className="text-center"><div className="heading-wrapper">%</div></th>
                            <th className="text-right"><div className="heading-wrapper">Nett Total</div></th>
                            <th className="text-center"><div className="heading-wrapper">VAT</div></th>
                            <th className="text-right"><div className="heading-wrapper">Gross Total</div></th>
                            <th className="column-actions text-right">
                              <div className="heading-wrapper">
                                {((canEdit || isSeller) && !isDealFinal) || isSuper ? (
                                  <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Plus" onClick={() => {
                                    const group = getIn(groups, 'Off the top Deductions', [])
                                    let idx = 0
                                    if (group) {
                                      idx = group[group.length - 1]?.idx + 1 || 0
                                    }
                                    const new_val = {
                                      commission_type: 'Off the top Deductions',
                                      role: 'Other',
                                      idx,
                                      editing: true,
                                      input_type: 'percentage',
                                      percentage: 0,
                                      nett_amount: 0,
                                      gross_amount: 0
                                    }
                                    insert(idx, new_val)
                                  }} />
                                ) : null}
                              </div>
                            </th>
                          </tr>
                          {getIn(formik, `values.${name}`).map((val, idx) => {
                            if (val.commission_type !== 'Off the top Deductions') {
                              return null
                            }
                            const { outer_hidden, outer_visible } = renderArrayFields(idx, formik, watched)

                            let title = val.name
                            if (val.franchise) {
                              title = getIn(cache, `franchises.${val.franchise}.name`)
                            }

                            let percentage = valueFormat('number', val.percentage)
                            if (val.role === 'Referral Franchise') {
                              percentage = `(${percentage})`
                            }

                            return val.editing ? (
                              <tr key={`pbs-${idx}`}>
                                {outer_visible}
                                <td className="column-actions text-right">
                                  <div className="tablecell">
                                    {outer_hidden}
                                    <Button type="button" className="btn btn-small btn-green" onClick={() => {
                                      const new_editing = editing.filter(x => x.idx !== idx)
                                      setEditing(new_editing)
                                      const vat = parseFloat(getIn(props.form.initialValues, 'commission.vat', '15.0'))
                                      const new_value = recalculateInputs({ values: getIn(formik.values, 'commission.relationships'), vat })
                                      new_value[idx].editing = false
                                      formik.setFieldValue(name, new_value).then(() => {
                                        setInitVals(setIn(initVals, props.field.name, new_value))
                                      })
                                    }}>Update</Button>
                                    <Button type="button" className="btn btn-small btn-red" onClick={() => {
                                      const initial = editing.find(x => x.idx === idx)
                                      const new_editing = editing.filter(x => x.idx !== idx)
                                      const vat = parseFloat(getIn(props.form.initialValues, 'commission.vat', '15.0'))
                                      if (initial) {
                                        formik.setFieldValue(`commission.relationships.${idx}`, initial).then(() => {
                                          recalculateInputs({ values: getIn(formik.values, 'commission.relationships'), vat })
                                        })
                                      } else {
                                        remove(idx)
                                      }
                                      setEditing(new_editing)
                                    }}>Cancel</Button>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <tr key={`pbs-${idx}`}>
                                <td><div className="tablecell">{title}</div></td>
                                <td><div className="tablecell">{val.deduct_franchise_fee}</div></td>
                                <td><div className="tablecell">{val.role}</div></td>
                                <td colSpan={2}></td>
                                <td className="text-center"><div className="tablecell">{percentage}</div></td>
                                <td className="text-right"><div className="tablecell">{valueFormat('currency', val.nett_amount, { currency })}</div></td>
                                <td className="text-center"><div className="tablecell">{val.apply_vat ? valueFormat('yesno', val.apply_vat || false) : '-'}</div></td>
                                <td className="text-right"><div className="tablecell">{valueFormat('currency', val.gross_amount, { currency })}</div></td>
                                <td className="column-actions text-right">
                                  <div className="tablecell">
                                    {((canEdit || isSeller) && !isDealFinal) || isSuper ? (
                                      <>
                                        <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Edit" onClick={() => {
                                          formik.setFieldValue(`commission.relationships.${idx}.editing`, true)
                                          setEditing([ ...editing, val ])
                                        }} />
                                        <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Bin" onClick={() => {
                                          const current = getIn(formik.values, name)
                                          const new_values = current
                                            .filter((c, cix) => cix !== idx).map((c, cid) => ({ ...c, idx: cid }))
                                          setInitVals(setIn(initVals, name, new_values))
                                          remove(idx)
                                        }} />
                                      </>
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                          <tr>
                            <th colSpan={2}><div className="heading-wrapper">Internal Referral Deductions</div></th>
                            <th><div className="heading-wrapper">Role</div></th>
                            <th className="text-center"><div className="heading-wrapper">Share %</div></th>
                            <th className="text-right"><div className="heading-wrapper">Share Total</div></th>
                            <th className="text-center"><div className="heading-wrapper">%</div></th>
                            <th className="text-right"><div className="heading-wrapper">Nett Total</div></th>
                            <th className="text-center"><div className="heading-wrapper">VAT</div></th>
                            <th className="text-right"><div className="heading-wrapper">Gross Total</div></th>
                            <th className="column-actions text-right">
                              <div className="heading-wrapper">
                                {((canEdit || isSeller) && !isDealFinal) || isSuper ? (
                                  <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Plus" onClick={() => {
                                    const group = getIn(groups, 'Internal Deductions', [])
                                    let idx = 0
                                    if (group) {
                                      idx = group[group.length - 1]?.idx + 1 || 0
                                    }
                                    const new_val = {
                                      commission_type: 'Internal Deductions',
                                      role: 'Referral Branch',
                                      deduct_franchise_fee: true,
                                      idx,
                                      editing: true,
                                      input_type: 'percentage',
                                      percentage: 0,
                                      nett_amount: 0,
                                      gross_amount: 0
                                    }
                                    insert(idx, new_val)
                                  }} />
                                ) : null}
                              </div>
                            </th>
                          </tr>
                          {getIn(formik, `values.${name}`).map((val, idx) => {
                            if (val.commission_type !== 'Internal Deductions') {
                              return null
                            }
                            const { outer_hidden, outer_visible } = renderArrayFields(idx, formik, watched)

                            let title = val.name
                            if (val.agent) {
                              title = `${[ getIn(cache, `agents.${val.agent}.first_name`), getIn(cache, `agents.${val.agent}.last_name`) ].filter(v => v).join(' ')}${val.branch ? ` (${getIn(cache, `branches.${val.branch}.name`)})` : ''}`
                            } else if (val.team) {
                              title = `${getIn(cache, `teams.${val.team}.name`)}${val.branch ? ` (${getIn(cache, `branches.${val.branch}.name`)})` : ''}`
                            } else if (val.branch) {
                              title = getIn(cache, `branches.${val.branch}.name`)
                            }

                            return val.editing ? (
                              <tr key={`pbs-${idx}`} className={classNames({ sub: (val.branch && !val.role.includes('Branch')) })}>
                                {outer_visible}
                                <td className="column-actions text-right">
                                  <div className="tablecell">
                                    {outer_hidden}
                                    {(val.role.includes('Branch') && val.nett_amount) ? (
                                      <Button type="button" className="btn btn-small btn-blue" onClick={() => {
                                        const next_idx = idx + 1
                                        const new_vals = getIn(formik, `values.${props.field.name}`, [])
                                        new_vals.splice(next_idx, 0, { commission_type: 'Internal Deductions', role: val.role.replace('Branch', 'Agent'), idx: next_idx, branch: val.branch })
                                        formik.setFieldValue(props.field.name, new_vals
                                          .map((d, didx) => ({ ...d, idx: didx })))
                                        setEditing(uniqueArray([ ...editing, { commission_type: 'Internal Deductions', role: val.role.replace('Branch', 'Agent'), idx: next_idx, branch: val.branch } ], 'idx'))
                                      }}>Split</Button>
                                    ) : null}
                                    <Button type="button" className="btn btn-small btn-green" onClick={() => {
                                      const new_editing = editing.filter(x => x.idx !== idx)
                                      setEditing(new_editing)
                                      const vat = parseFloat(getIn(props.form.initialValues, 'commission.vat', '15.0'))
                                      const new_value = recalculateInputs({ values: getIn(formik.values, 'commission.relationships'), vat })
                                      new_value[idx].editing = false
                                      formik.setFieldValue(name, new_value).then(() => {
                                        setInitVals(setIn(initVals, props.field.name, new_value))
                                      })
                                    }}>Update</Button>
                                    <Button type="button" className="btn btn-small btn-red" onClick={() => {
                                      const initial = editing.find(x => x.idx === idx)
                                      const new_editing = editing.filter(x => x.idx !== idx)
                                      const vat = parseFloat(getIn(props.form.initialValues, 'commission.vat', '15.0'))
                                      if (initial) {
                                        formik.setFieldValue(`commission.relationships.${idx}`, initial).then(() => {
                                          recalculateInputs({ values: getIn(formik.values, 'commission.relationships'), vat })
                                        })
                                      } else {
                                        remove(idx)
                                      }
                                      setEditing(new_editing)
                                    }}>Cancel</Button>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <tr key={`pbs-${idx}`}>
                                <td><div className="tablecell">{title}</div></td>
                                <td><div className="tablecell">{val.deduct_franchise_fee}</div></td>
                                <td><div className="tablecell">{val.role}</div></td>
                                <td className="text-center">{val.role.includes('Branch') ? '' : `${valueFormat('number', val.share_percentage)}`}</td>
                                <td className="text-right">{val.role.includes('Branch') ? '' : `${val.deduct_franchise_fee ? '(Fee Deducted) ' : ''}${valueFormat('currency', val.share_amount, { currency })}`}</td>
                                <td className="text-center"><div className="tablecell">{(val.branch && !val.role.includes('Branch')) ? `(${valueFormat('number', val.percentage)})` : `${valueFormat('number', val.percentage)}`}</div></td>
                                <td className="text-right"><div className="tablecell">{valueFormat('currency', val.nett_amount, { currency })}</div></td>
                                <td className="text-center"><div className="tablecell">{val.apply_vat ? valueFormat('yesno', val.apply_vat || false) : '-'}</div></td>
                                <td className="text-right"><div className="tablecell">{valueFormat('currency', val.gross_amount, { currency })}</div></td>
                                <td className="column-actions text-right">
                                  <div className="tablecell">
                                    {((canEdit || isSeller) && !isDealFinal) || isSuper ? (
                                      <>
                                        <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Edit" onClick={() => {
                                          formik.setFieldValue(`commission.relationships.${idx}.editing`, true)
                                          setEditing([ ...editing, val ])
                                        }} />
                                        <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Bin" onClick={() => {
                                          const current = getIn(props.form.values, name)
                                          const new_values = current
                                            .filter((c, cix) => {
                                              if (cix === idx) {
                                                // exclude current row
                                                return false
                                              }
                                              if (val.role.includes('Branch') && c.branch === val.branch) {
                                                return false
                                              }
                                              return true
                                            }).map((c, cid) => ({ ...c, idx: cid }))
                                          setInitVals(setIn(initVals, name, new_values))
                                          remove(idx)
                                        }} />
                                      </>
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                          <tr className="primary-bg">
                            <th colSpan={2}><div className="heading-wrapper">Remaining Commission</div></th>
                            <th></th>
                            <th colSpan={2}></th>
                            <th className="text-center"><div className="tablecell">-</div></th>
                            <th className="text-right"><div className="heading-wrapper">{nett_commission_remaining}</div></th>
                            <th className="text-center"><div className="tablecell">-</div></th>
                            <th className="text-right"><div className="tablecell">-</div></th>
                            <th></th>
                          </tr>
                          <tr>
                            <th colSpan={2}><div className="heading-wrapper">Commission Split</div></th>
                            <th><div className="heading-wrapper">Role</div></th>
                            <th className="text-center"><div className="heading-wrapper">Share %</div></th>
                            <th className="text-right"><div className="heading-wrapper">Share Total</div></th>
                            <th className="text-center"><div className="heading-wrapper">Comm. %</div></th>
                            <th className="text-right"><div className="heading-wrapper">Nett Total</div></th>
                            <th className="text-center"><div className="heading-wrapper">VAT</div></th>
                            <th className="text-right"><div className="heading-wrapper">Gross Total</div></th>
                            <th className="column-actions text-right">
                              <div className="heading-wrapper">
                                {((canEdit || isSeller) && !isDealFinal) || isSuper ? (
                                  <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Plus" onClick={() => {
                                    const group = getIn(groups, 'Commission Split', [])
                                    let idx = 0
                                    if (group) {
                                      idx = group[group.length - 1]?.idx + 1 || 0
                                    }
                                    const new_val = {
                                      commission_type: 'Commission Split',
                                      role: 'Selling Agent',
                                      branch: selling_branch,
                                      deduct_franchise_fee: true,
                                      idx,
                                      editing: true,
                                      input_type: 'percentage',
                                      percentage: 0,
                                      nett_amount: 0,
                                      gross_amount: 0
                                    }
                                    insert(idx, new_val)
                                  }} />
                                ) : null}
                              </div>
                            </th>
                          </tr>
                          {getIn(formik, `values.${name}`).map((val, idx) => {
                            if (val.commission_type !== 'Commission Split') {
                              return null
                            }
                            const { outer_hidden, outer_visible } = renderArrayFields(idx, formik, watched)
                            let title = val.name
                            if (val.agent) {
                              title = `${[ getIn(cache, `agents.${val.agent}.first_name`), getIn(cache, `agents.${val.agent}.last_name`) ].filter(v => v).join(' ')}${val.team ? ` (${getIn(cache, `teams.${val.team}.name`)})` : ''}`
                            } else if (val.team) {
                              title = `${getIn(cache, `teams.${val.team}.name`)}${val.branch ? ` (${getIn(cache, `branches.${val.branch}.name`)})` : ''}`
                            } else if (val.branch) {
                              title = getIn(cache, `branches.${val.branch}.name`)
                            }
                            let percentage = ''
                            if (val.agent && !val.branch) {
                              percentage = valueFormat('number', val.percentage)
                            } else if (val.agent && val.branch) {
                              percentage = `(${valueFormat('number', val.percentage)})`
                            }

                            return val.editing ? (
                              <tr key={`pbs-${idx}`}>
                                {outer_visible}
                                <td className="column-actions text-right">
                                  <div className="tablecell">
                                    {outer_hidden}
                                    {((val.role.includes('Team') || val.role.includes('Branch')) && val.nett_amount) ? (
                                      <Button type="button" className="btn btn-small btn-blue" onClick={() => {
                                        const group = getIn(groups, 'Commission Split', [])
                                        let next_idx = 0
                                        if (group) {
                                          next_idx = idx + 1 || 0
                                        }
                                        const new_val = {
                                          commission_type: 'Commission Split',
                                          role: val.role.replace('Branch', 'Agent'),
                                          idx: next_idx,
                                          editing: true,
                                          team: val.team,
                                          branch: val.branch,
                                          input_type: 'percentage',
                                          percentage: 0,
                                          nett_amount: 0,
                                          gross_amount: 0
                                        }
                                        insert(next_idx, new_val)
                                      }}>Split</Button>
                                    ) : null}
                                    <Button type="button" className="btn btn-small btn-green" onClick={() => {
                                      const new_editing = editing.filter(x => x.idx !== idx)
                                      setEditing(new_editing)
                                      const vat = parseFloat(getIn(props.form.initialValues, 'commission.vat', '15.0'))
                                      const new_value = recalculateInputs({ values: getIn(formik.values, 'commission.relationships'), vat })
                                      new_value[idx].editing = false
                                      formik.setFieldValue(name, new_value).then(() => {
                                        setInitVals(setIn(initVals, props.field.name, new_value))
                                      })
                                    }}>Update</Button>
                                    <Button type="button" className="btn btn-small btn-red" onClick={() => {
                                      const initial = editing.find(x => x.idx === idx)
                                      const new_editing = editing.filter(x => x.idx !== idx)
                                      const vat = parseFloat(getIn(props.form.initialValues, 'commission.vat', '15.0'))
                                      if (initial) {
                                        formik.setFieldValue(`commission.relationships.${idx}`, initial).then(() => {
                                          recalculateInputs({ values: getIn(formik.values, 'commission.relationships'), vat })
                                        })
                                      } else {
                                        remove(idx)
                                      }
                                      setEditing(new_editing)
                                    }}>Cancel</Button>
                                  </div>
                                </td>
                              </tr>
                            ) : (
                              <tr key={`pbs-${idx}`}>
                                <td><div className="tablecell">{title}</div></td>
                                <td><div className="tablecell">{val.deduct_franchise_fee}</div></td>
                                <td><div className="tablecell">{val.role}</div></td>
                                <td className="text-center">{val.role.includes('Branch') ? '' : `${valueFormat('number', val.share_percentage)}`}</td>
                                <td className="text-right">{val.role.includes('Branch') ? '' : `${val.deduct_franchise_fee ? '(Fee Deducted) ' : ''}${valueFormat('currency', val.share_amount, { currency })}`}</td>
                                <td className="text-center"><div className="tablecell">{percentage}</div></td>
                                <td className="text-right"><div className="tablecell">{valueFormat('currency', val.nett_amount, { currency })}</div></td>
                                <td className="text-center"><div className="tablecell">{val.apply_vat ? valueFormat('yesno', val.apply_vat) : '-'}</div></td>
                                <td className="text-right"><div className="tablecell">{valueFormat('currency', val.gross_amount, { currency })}</div></td>
                                <td className="column-actions text-right">
                                  <div className="tablecell">
                                    {((canEdit || isSeller) && !isDealFinal) || isSuper ? (
                                      <>
                                        <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Edit" onClick={() => {
                                          formik.setFieldValue(`commission.relationships.${idx}.editing`, true)
                                          setEditing([ ...editing, val ])
                                        }} />
                                        <Button type="button" className="btn btn-none btn-icon btn-icon-only btn-icon-16" icon="#icon16-Bin" onClick={() => {
                                          const current = getIn(formik.values, name)
                                          const new_values = current
                                            .filter((c, cix) => cix !== idx).map((c, cid) => ({ ...c, idx: cid }))
                                          setInitVals(setIn(initVals, name, new_values))
                                          remove(idx)
                                        }} />
                                      </>
                                    ) : null}
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                          <tr className={classNames('primary-bg', { success: total_splits === nett_commission_remaining, fail: total_splits !== nett_commission_remaining })}>
                            <th colSpan={2}><div className="heading-wrapper">Total Allocated Commission</div></th>
                            <th></th>
                            <th colSpan={2}></th>
                            <th className="text-center"><div className="heading-wrapper">-</div></th>
                            <th className="text-right"><div className="heading-wrapper">{total_splits}</div></th>
                            <th className="text-center"><div className="tablecell">-</div></th>
                            <th className="text-right"><div className="tablecell">-</div></th>
                            <th className="column-actions text-right"><div className="tablecell"><div className="indicator"><svg viewBox='0 0 32 32'><use href={`/images/icons-16.svg#icon16-${total_splits === nett_commission_remaining ? 'Check-Small' : 'X-Small'}`} /></svg></div></div></th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )
                }}
              />
            )}
          />
        )
      }}</Formik>
  )
}


const mapStateToProps = state => {
  const settings = SETTINGS(state)
  return ({
    currency: settings.get('default_currency')
  })
}

const CommissionTable = connect(mapStateToProps, null)(withImmutablePropsToJS(CommissionTableInput))
export default CommissionTable


CommissionTableInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  text_suffix: PropTypes.string,
  input: PropTypes.string,
  cache: PropTypes.object,
  actions: PropTypes.object,
  settings: PropTypes.object,
  model: PropTypes.object,
  dragEnded: PropTypes.func,
  fields: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  required: PropTypes.bool,
  allowdelete: PropTypes.bool,
  sortable: PropTypes.bool,
  show_required: PropTypes.bool,
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  multi: PropTypes.bool,
  nobuttons: PropTypes.bool,
  max: PropTypes.number,
  currency: PropTypes.string,
  config: PropTypes.object
}
