/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isObject } from 'formik'
import { Button } from '../../../ui/Button'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import RecipientSelectSidebar from '../../../../containers/RecipientSelectSidebar'


const RecipientLookup = props => {
  const { id, settings, field, form, user, match, label, toggleWideSidebar, models } = props
  const { name, value } = field

  const defaultvalue = {
    count: 0,
    agents: {
      selected: [],
      allselected: []
    },
    contacts: {
      selected: [],
      allselected: []
    }
  }

  const addSelected = (modelname, selected) => {
    let newrecipients = field.value
    if (!isObject(newrecipients)) { newrecipients = { ...defaultvalue } }
    newrecipients.count += selected.length
    newrecipients[modelname].selected.push(selected)
    form.setFieldValue('recipients', newrecipients)
  }

  const addAll = (modelname, model) => {
    let newrecipients = field.value
    if (!isObject(newrecipients)) { newrecipients = { ...defaultvalue } }
    newrecipients.count += model.count
    newrecipients[modelname].allselected.push(model.params)
    form.setFieldValue('recipients', newrecipients)
  }

  const resetSelected = () => {
    form.setFieldValue('recipients', null)
  }

  if (models) {
    return (
      <div id={id} className={`form-group recipient-lookup ${props.classes ? props.classes : ''}`}>

        {models.includes('contacts') &&
        <div>
          <Button
            type="button"
            id={`${name}-contact-lookup-search-btn`}
            tabIndex="-1"
            icon="#icon16-Plus"
            onClick={() => toggleWideSidebar(`show-${name}-contacts-search`) }
            className="btn btn-grey btn-icon-left btn-icon-16"
          >Contacts</Button>

          <WideSidebar sidebar={`show-${name}-contacts-search`}>
            <RecipientSelectSidebar
              label={label}
              field={field}
              modelname="contacts"
              user={user}
              key="recipient-search-contacts"
              addSelected={(modelname, selected) => addSelected(modelname, selected)}
              addAll={(modelname, model) => addAll(modelname, model)}
              settings={settings}
              siblingform={form}
              match={match}
            />
          </WideSidebar>
        </div>
        }

        {models.includes('profiles') &&
        <div>
          <Button
            type="button"
            id={`${name}-contact-lookup-search-btn`}
            tabIndex="-1"
            icon="#icon16-Plus"
            onClick={() => toggleWideSidebar(`show-${name}-profiles-search`) }
            className="btn btn-grey btn-icon-left btn-icon-16"
          >Profiles</Button>

          <WideSidebar sidebar={`show-${name}-profiles-search`}>
            <RecipientSelectSidebar
              label={label}
              field={field}
              modelname="profiles"
              user={user}
              key="recipient-search-profles"
              addSelected={(modelname, selected) => addSelected(modelname, selected)}
              addAll={(modelname, model) => addAll(modelname, model)}
              settings={settings}
              siblingform={form}
              match={match}
            />
          </WideSidebar>
        </div>
        }

        {models.includes('agents') &&
        <div>
          <Button
            type="button"
            id={`${name}-contact-lookup-search-btn`}
            tabIndex="-1"
            icon="#icon16-Plus"
            onClick={() => toggleWideSidebar(`show-${name}-agents-search`) }
            className="btn btn-grey btn-icon-left btn-icon-16"
          >Agents</Button>

          <WideSidebar sidebar={`show-${name}-agents-search`}>
            <RecipientSelectSidebar
              label={label}
              field={field}
              modelname="agents"
              user={user}
              key="recipient-search-agents"
              addSelected={(modelname, selected) => addSelected(modelname, selected)}
              addAll={(modelname, model) => addAll(modelname, model)}
              settings={settings}
              siblingform={form}
              match={match}
            />
          </WideSidebar>
        </div>
        }

        <div className="recipients-count">
          <div>
          Sending to
            {field.value && field.value.count ? (
              <strong> {field.value.count} recipients</strong>
            ) : <strong> 0 recipients</strong>}
          </div>
          <Button className="btn" type='button' onClick={() => resetSelected()}>Reset</Button>
        </div>

      </div>
    )
  }
  return (
    <div id={id} className={`form-group recipient-lookup ${props.classes ? props.classes : ''}`}>
      <Button
        type="button"
        id={`${name}-contact-lookup-search-btn`}
        tabIndex="-1"
        icon="#icon16-Plus"
        onClick={() => toggleWideSidebar(`show-${name}-contacts-search`) }
        className="btn btn-grey btn-icon-left btn-icon-16"
      >Contacts</Button>

      <WideSidebar sidebar={`show-${name}-contacts-search`}>
        <RecipientSelectSidebar
          label={label}
          field={field}
          modelname="contacts"
          user={user}
          key={`recipient-search-${name}`}
          addSelected={(modelname, selected) => addSelected(modelname, selected)}
          addAll={(modelname, model) => addAll(modelname, model)}
          settings={settings}
          siblingform={form}
          match={match}
        />
      </WideSidebar>

      <div className="recipients-count">
          Sending to
        {field.value && field.value.count ? (
          <strong> {field.value.count} recipients</strong>
        ) : <strong> 0 recipients</strong>}
      </div>

    </div>
  )
}

RecipientLookup.propTypes = {
  id: PropTypes.string.isRequired,
  toggleWideSidebar: PropTypes.func.isRequired,
  fetchOne: PropTypes.func.isRequired,
  fetchMany: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  form: PropTypes.object.isRequired,
  className: PropTypes.string,
  cache: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  model: PropTypes.object,
  match: PropTypes.object,
  selected: PropTypes.array,
  config: PropTypes.object,
  settings: PropTypes.object,
  setIgnoreFields: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.object,
  user: PropTypes.object,
  models: PropTypes.array
}


export default RecipientLookup
