
/* eslint-disable new-cap */
import classNames from 'classnames'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany } from '../../actions'
import { PORTALS, MINUSER } from '../../selectors'
import { valueFormat, useCustomCompareMemo, textToDate } from '../../utils'
import Card from '../common/Card'
import { Button } from '../ui/Button'
import InlineBranchSelect from './inputs/InlineBranchSelect'
import InlineAgentSelect from './inputs/InlineAgentSelect'
import withDelay from './withDelay'


const { start: defaultStart, end: defaultEnd } = textToDate('LAST_30_DAYS')

const UAESyndicationWidget = ({ actions, user, portals }) => {
  const [ branchId, setBranchId ] = useState('')
  const [ agentId, setAgentId ] = useState(user.agent.id || '')
  const [ { start: current, end: limit, period } ] = useState({ start: valueFormat('shortdate', defaultStart.toString()), end: valueFormat('shortdate', defaultEnd.toString()), period: 'LAST_30_DAYS' })
  const [ activePortals ] = useState(portals ? Object.keys(portals).map(pid => portals[pid]).filter(p => [ 'Bayut', 'Property Finder', 'Dubizzle', 'Houza' ].includes(p.meta.portal.name)) : [])
  const [ portalStats, setPortalStats ] = useState({})
  const [ bayut, setBayut ] = useState(false)
  const [ property_finder, setPropertyFinder ] = useState(false)
  const [ dubizzle, setDubizzle ] = useState(false)
  const [ houza, setHouza ] = useState(false)
  const abortController = useRef(new AbortController())
  const abortControllers = useRef()

  const filterLogs = useCallback(() => {
    abortControllers.current = {}
    const params = {
      active: 1,
      order_by: '-modified',
      get_count: 1

    }
    if (branchId) {
      params.listing__branch = branchId
    }
    if (agentId) {
      params.listing__agent = agentId
    }

    const counts = activePortals.map(p => new Promise((resolve, reject) => {
      abortControllers.current[p.portal] = new AbortController()
      return actions.fetchMany({
        noloader: true,
        values: {
          modelname: 'portal-configs',
          endpoint: {
            read: '/listings/api/v1/portal-configs/status-counts'
          },
          params: {
            ...params,
            portal: p.portal,
            modified__date__gte: current,
            modified__date__lte: limit
          },
          signal: abortControllers.current[p.portal].signal
        },
        resolve,
        reject
      })
    }).then(result => ({ portal: p.portal, counts: result })).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
    }))
    Promise.allSettled(counts).then(results => {
      const portal_stats = {}
      results.forEach(result => {
        if (!result.value) { return }
        portal_stats[result.value.portal] = result.value.counts
      })
      setPortalStats(portal_stats)
    })
  }, [ branchId, agentId, current, limit, useCustomCompareMemo(activePortals) ])

  useEffect(() => {
    filterLogs()
  }, [ branchId, agentId, period ])

  useEffect(() => () => {
    abortController.current.abort()
    if (abortControllers.current) {
      Object.values(abortControllers.current).forEach(controller => {
        controller.abort()
      })
    }
  }, [])

  const bt = activePortals.find(p => p.meta.portal.name === 'Bayut')

  const pf = activePortals.find(p => p.meta.portal.name === 'Property Finder')

  const dz = activePortals.find(p => p.meta.portal.name === 'Dubizzle')

  const hz = activePortals.find(p => p.meta.portal.name === 'Houza')

  const init_params = {
    active: 1,
    order_by: '-modified'
  }
  if (branchId) {
    init_params.residential__branch__or = branchId
    init_params.commercial__branch__or = branchId
    init_params.project__branch__or = branchId
    init_params.holiday__branch__or = branchId
  }
  return (
    <Card
      id="uae-syndication-widget"
      classes="grid-col-1"
      bodyclass="stats-card no-top-padding syndication-widget"
      background
      header={
        <>
          <h3>Syndication</h3>
          <div className="details-section-buttons min-flex tablemeta">
            <InlineBranchSelect
              user={user}
              actions={actions}
              selectedValue={branchId}
              onChange={e => {
                setBranchId(e.value)
              }}
            />
            <InlineAgentSelect
              user={user}
              actions={actions}
              branchId={branchId}
              selectedValue={agentId}
              onChange={e => {
                setAgentId(e.value)
              }}
            />
          </div>
        </>
      }
      body={
        <div className={classNames('flex-container', 'flex-4', 'gap-40', 'justify-content-center')}>
          {bt ? (
            <>
              <div className='uae-syndication-summary bayut'>
                <div className="syndication-meta">
                  <div className="syndication-logo"><img src={`${process.env.PUBLIC_URL}/portals/bayut.png`} alt="Property 24" /></div>
                  <div className="syndication-counts">
                    <div className="syndication-count syndication-res">
                      <Button type="button" onClick={e => {
                        e.preventDefault()
                        setBayut('bayut-res')
                      }} className={classNames({ active: bayut === 'bayut-res' })}>
                        <strong>Residential</strong>
                        <div>{valueFormat('number', getIn(portalStats, `${bt.portal}.residential`))}</div>
                      </Button>
                    </div>
                    <div className="syndication-count syndication-com">
                      <Button type="button" onClick={e => {
                        e.preventDefault()
                        setBayut('bayut-com')
                      }} className={classNames({ active: bayut === 'bayut-com' })}>
                        <strong>Commercial</strong>
                        <div>{valueFormat('number', getIn(portalStats, `${bt.portal}.commercial`))}</div>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="uae-list-actions">
                  <Button component={NavLink} className="btn btn-subtle" to={`/secure/residential?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(bt, 'portal')}&portals__active=1`}>View residental</Button>
                  <Button component={NavLink} className="btn btn-subtle" to={`/secure/commercial?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(bt, 'portal')}&portals__active=1`}>View commercial</Button>
                </div>
              </div>
            </>
          ) : null}
          {pf ? (
            <>
              <div className='uae-syndication-summary property-finder'>
                <div className="syndication-meta">
                  <div className="syndication-logo"><img src={`${process.env.PUBLIC_URL}/portals/property-finder.png`} alt="Bayut" /></div>
                  <div className="syndication-counts">
                    <div className="syndication-count syndication-res">
                      <Button type="button" onClick={e => {
                        e.preventDefault()
                        setPropertyFinder('property-finder-res')
                      }} className={classNames({ active: property_finder === 'property-finder-res' })}>
                        <strong>Residential</strong>
                        <div>{valueFormat('number', getIn(portalStats, `${pf.portal}.residential`))}</div>
                      </Button>
                    </div>
                    <div className="syndication-count syndication-com">
                      <Button type="button" onClick={e => {
                        e.preventDefault()
                        setPropertyFinder('property-finder-com')
                      }} className={classNames({ active: property_finder === 'property-finder-com' })}>
                        <strong>Commercial</strong>
                        <div>{valueFormat('number', getIn(portalStats, `${pf.portal}.commercial`))}</div>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="uae-list-actions">
                  <Button component={NavLink} className="btn btn-subtle" to={`/secure/residential?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(pf, 'portal')}&portals__active=1`}>View residental</Button>
                  <Button component={NavLink} className="btn btn-subtle" to={`/secure/commercial?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(pf, 'portal')}&portals__active=1`}>View commercial</Button>
                </div>
              </div>
            </>
          ) : null}
          {dz ? (
            <>
              <div className='uae-syndication-summary dubizzle'>
                <div className="syndication-meta">
                  <div className="syndication-logo"><img src={`${process.env.PUBLIC_URL}/portals/dubizzle.png`} alt="Dubizzle" /></div>
                  <div className="syndication-counts">
                    <div className="syndication-count syndication-res">
                      <Button type="button" onClick={e => {
                        e.preventDefault()
                        setDubizzle('dubizzle-res')
                      }} className={classNames({ active: dubizzle === 'dubizzle-res' })}>
                        <strong>Residential</strong>
                        <div>{valueFormat('number', getIn(portalStats, `${dz.portal}.residential`))}</div>
                      </Button>
                    </div>
                    <div className="syndication-count syndication-com">
                      <Button type="button" onClick={e => {
                        e.preventDefault()
                        setDubizzle('dubizzle-com')
                      }} className={classNames({ active: dubizzle === 'dubizzle-com' })}>
                        <strong>Commercial</strong>
                        <div>{valueFormat('number', getIn(portalStats, `${dz.portal}.commercial`))}</div>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="uae-list-actions">
                  <Button component={NavLink} className="btn btn-subtle" to={`/secure/residential?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(dz, 'portal')}&portals__active=1`}>View residental</Button>
                  <Button component={NavLink} className="btn btn-subtle" to={`/secure/commercial?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(dz, 'portal')}&portals__active=1`}>View commercial</Button>
                </div>
              </div>
            </>
          ) : null}
          {hz ? (
            <>
              <div className='uae-syndication-summary houza'>
                <div className="syndication-meta">
                  <div className="syndication-logo"><img src={`${process.env.PUBLIC_URL}/portals/houza.png`} alt="HouZa" /></div>
                  <div className="syndication-counts">
                    <div className="syndication-count syndication-res">
                      <Button type="button" onClick={e => {
                        e.preventDefault()
                        setHouza('houza-res')
                      }} className={classNames({ active: houza === 'houza-res' })}>
                        <strong>Residential</strong>
                        <div>{valueFormat('number', getIn(portalStats, `${hz.portal}.residential`))}</div>
                      </Button>
                    </div>
                    <div className="syndication-count syndication-com">
                      <Button type="button" onClick={e => {
                        e.preventDefault()
                        setHouza('houza-com')
                      }} className={classNames({ active: houza === 'houza-com' })}>
                        <strong>Commercial</strong>
                        <div>{valueFormat('number', getIn(portalStats, `${hz.portal}.commercial`))}</div>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="uae-list-actions">
                  <Button component={NavLink} className="btn btn-subtle" to={`/secure/residential?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(hz, 'portal')}&portals__active=1`}>View residental</Button>
                  <Button component={NavLink} className="btn btn-subtle" to={`/secure/commercial?limit=20&status__in=Active&order_by=-created&portals__portal=${getIn(hz, 'portal')}&portals__active=1`}>View commercial</Button>
                </div>
              </div>
            </>
          ) : null}
        </div>
      }
    />
  )
}


UAESyndicationWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  configs: PropTypes.object,
  portals: PropTypes.object,
  branches: PropTypes.arrayOf(PropTypes.number)
}

const mapStateToProps = state => {
  const user = MINUSER(state)
  const portals = PORTALS(state)
  return ({
    user,
    portals
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(withDelay(UAESyndicationWidget)))
