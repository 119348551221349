/* eslint-disable new-cap */
import classNames from 'classnames'
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef, useCallback } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany } from '../../actions'
import { PORTALS, MINUSER } from '../../selectors'
import { textToDate, useCustomCompareMemo, valueFormat } from '../../utils'
import Card from '../common/Card'
import SimpleTable from '../common/simpletable/SimpleTable'
import { Button } from '../ui/Button'
import InlineBranchSelect from './inputs/InlineBranchSelect'
import InlineListingTypeSelect from './inputs/InlineListingTypeSelect'
import InlinePeriodSelect from './inputs/InlinePeriodSelect'
import withDelay from './withDelay'


const { start: defaultStart, end: defaultEnd } = textToDate('LAST_30_DAYS')

const dateFilter = dates => dates.filter(o => !o.value.includes('NEXT') && !o.value.includes('TOMORROW'))

const SyndicationWidget = ({ actions, user, portals }) => {
  const [ branchId, setBranchId ] = useState('')
  const [ listingModel, setListingModel ] = useState('')
  const [ { start: current, end: limit, period }, setPeriodData ] = useState({ start: valueFormat('shortdate', defaultStart.toString()), end: valueFormat('shortdate', defaultEnd.toString()), period: 'LAST_30_DAYS' })
  const [ activePortals ] = useState(portals ? Object.keys(portals).map(pid => portals[pid]).filter(p => [ 'Property24', 'Private Property' ].includes(p.meta.portal.name)) : [])
  const [ p24Status, setP24Status ] = useState('failed')
  const [ ppStatus, setPpStatus ] = useState('failed')
  const [ { modelName }, setParams ] = useState({ modelName: null, listingType: null })
  const [ portalStats, setPortalStats ] = useState({})
  const abortController = useRef(new AbortController())
  const abortControllers = useRef()

  const filterLogs = useCallback(() => {
    abortControllers.current = {}
    const params = {
      active: 1,
      order_by: '-modified',
      get_count: 1
    }
    if (branchId) {
      if (listingModel) {
        params[`${modelName}__branch`] = branchId
      } else {
        params.listing__branch = branchId
      }
    }
    if (listingModel) {
      params[`${modelName}__isnull`] = 'false'
      params[`${modelName}__status`] = 'Active'
    } else {
      params.listing__status = 'Active'
    }
    const counts = activePortals.map(p => new Promise((resolve, reject) => {
      abortControllers.current[p.portal] = new AbortController()
      return actions.fetchMany({
        noloader: true,
        values: {
          modelname: 'portal-configs',
          endpoint: {
            read: '/listings/api/v1/portal-configs/status-counts'
          },
          params: {
            ...params,
            portal: p.portal,
            modified__date__gte: current,
            modified__date__lte: limit
          },
          signal: abortControllers.current[p.portal].signal
        },
        resolve,
        reject
      })
    }).then(result => ({ portal: p.portal, counts: result })).catch(e => {
      if (e.status !== 408) {
        console.error(e)
      }
    }))
    Promise.allSettled(counts).then(results => {
      const portal_stats = {}
      results.forEach(result => {
        if (!result.value) { return }
        portal_stats[result.value.portal] = result.value.counts
      })
      setPortalStats(portal_stats)
    })
  }, [ branchId, listingModel, current, limit, useCustomCompareMemo(activePortals) ])

  useEffect(() => {
    filterLogs()
  }, [ branchId, listingModel, period ])

  useEffect(() => () => {
    abortController.current.abort()
    if (abortControllers.current) {
      Object.values(abortControllers.current).forEach(controller => {
        controller.abort()
      })
    }
  }, [])

  if (!activePortals) { return null }

  const p24 = activePortals.find(p => p.meta.portal.name === 'Property24')

  const pp = activePortals.find(p => p.meta.portal.name === 'Private Property')

  const init_params = {
    active: 1,
    order_by: '-modified'
  }
  if (branchId) {
    init_params.residential__branch__or = branchId
    init_params.commercial__branch__or = branchId
    init_params.project__branch__or = branchId
    init_params.holiday__branch__or = branchId
  }
  if (modelName) {
    init_params[`${modelName}__isnull`] = 'false'
  }
  return (
    <Card
      id="syndication-widget"
      classes="grid-col-1"
      bodyclass="stats-card no-top-padding syndication-widget"
      background
      header={
        <>
          <h3>Syndication</h3>
          <div className="details-section-buttons min-flex tablemeta">
            <InlineBranchSelect
              user={user}
              actions={actions}
              selectedValue={branchId}
              onChange={e => {
                setBranchId(e.value)
              }}
            />
            <InlineListingTypeSelect
              user={user}
              selectedValue={listingModel}
              showAllOption
              onChange={e => {
                setListingModel(e.value)
                let newModelName = e.value
                try {
                  const re = /(?<modelname>.*)_(?<listing_type>for_sale|to_let)/g
                  const groups = re.exec(newModelName)?.groups
                  newModelName = groups?.modelname
                } catch (er) {
                  console.error(er)
                }
                setParams({ modelName: newModelName })
              }}
            />
            <InlinePeriodSelect
              optionFilter={dateFilter}
              selectedValue={period}
              onChange={setPeriodData}
            />
          </div>
        </>
      }
      body={
        <div className={classNames('flex-container', { 'flex-1': activePortals.length === 1, 'flex-2': activePortals.length === 2 })}>
          {p24 ? (
            <>
              <div className='syndication-summary syndication-property24'>
                <div className="syndication-meta">
                  <div className="syndication-logo"><img src={`${process.env.PUBLIC_URL}/portals/property24.png`} alt="Property 24" /></div>
                  <div className="syndication-counts">
                    <div className="syndication-count syndication-failures">
                      <Button type="button" onClick={e => {
                        e.preventDefault()
                        setP24Status('failed')
                      }} className={classNames({ active: p24Status === 'failed' })}>
                        <strong>FAILURES</strong>
                        <div>{valueFormat('number', getIn(portalStats, `${p24.portal}.failed`, 0))}</div>
                      </Button>
                    </div>
                    <div className="syndication-count syndication-pending">
                      <Button type="button" onClick={e => {
                        e.preventDefault()
                        setP24Status('pending')
                      }} className={classNames({ active: p24Status === 'pending' })}>
                        <strong>PENDING</strong>
                        <div>{valueFormat('number', getIn(portalStats, `${p24.portal}.pending`, 0))}</div>
                      </Button>
                    </div>
                    <div className="syndication-count syndication-success">
                      <Button type="button" onClick={e => {
                        e.preventDefault()
                        setP24Status('success')
                      }} className={classNames({ active: p24Status === 'success' })}>
                        <strong>SUCCESS</strong>
                        <div>{valueFormat('number', getIn(portalStats, `${p24.portal}.success`, 0) + getIn(portalStats, `${p24.portal}.withdrawn`, 0))}</div>
                      </Button>
                    </div>
                  </div>
                </div>
                <SimpleTable
                  config={{}}
                  action={({ params, resolve, reject }) => new Promise((res, rej) => actions.fetchMany({
                    noloader: true,
                    values: {
                      modelname: 'portal-configs',
                      endpoint: {
                        read: '/listings/api/v1/portal-configs'
                      },
                      // signal: abortController.current.signal,
                      params: {
                        ...params,
                        meta_fields: [ 'residential', 'commercial', 'project', 'holiday' ],
                        portal: p24.portal
                      }
                    },
                    resolve: res,
                    reject: rej
                  })).then(r => {
                    resolve(r)
                  }).catch(e => {
                    reject(e)
                  })}
                  params={{
                    ...init_params,
                    fields: [
                      'id',
                      'modified',
                      'last_message',
                      'residential',
                      'commercial',
                      'project',
                      'holiday',
                      'unit_number',
                      'complex_name',
                      'building_name',
                      'street_number',
                      'street_name',
                      'model'
                    ],
                    feed_status: p24Status,
                    modified__date__gte: current,
                    modified__date__lte: limit,
                    limit: 20
                  }}
                  parser={data => {
                    data.options = data.options.map(o => {
                      o.listing = null
                      if (getIn(o, 'residential')) {
                        o.listing = getIn(o, 'residential')
                        o.meta.listing = getIn(o.meta, 'residential')
                      } else if (getIn(o, 'commercial')) {
                        o.listing = getIn(o, 'commercial')
                        o.meta.listing = getIn(o.meta, 'commercial')
                      } else if (getIn(o, 'project')) {
                        o.listing = getIn(o, 'project')
                        o.meta.listing = getIn(o.meta, 'project')
                      } else if (getIn(o, 'holiday')) {
                        o.listing = getIn(o, 'holiday')
                        o.meta.listing = getIn(o.meta, 'holiday')
                      }
                      return o
                    })
                    return data
                  }}
                  header={[
                    {
                      label: 'Address',
                      name: [
                        'listing.unit_number',
                        'listing.complex_name',
                        ',',
                        'listing.building_name',
                        ',',
                        'listing.street_number',
                        'listing.street_name'
                      ],
                      container: 'meta',
                      orderable: false,
                      link: '/secure/:meta.listing.model/:listing'
                    },
                    {
                      label: 'Reason',
                      name: 'last_message',
                      orderable: false
                    },
                    {
                      label: 'Date',
                      name: 'modified',
                      orderable: false,
                      format: 'datetime'
                    }
                  ]}
                  user={user}
                />
                <div className="list-actions">
                  {listingModel ? (
                    <Button component={NavLink} className="btn btn-subtle" to={`/secure/syndication/${listingModel}`}>View entire log</Button>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
          {pp ? (
            <>
              <div className='syndication-summary syndication-private-property'>
                <div className="syndication-meta">
                  <div className="syndication-logo"><img src={`${process.env.PUBLIC_URL}/portals/private-property.png`} alt="Private Property" /></div>
                  <div className="syndication-counts">
                    <div className="syndication-count syndication-failures">
                      <Button type="button" onClick={e => {
                        e.preventDefault()
                        setPpStatus('failed')
                      }} className={classNames({ active: ppStatus === 'failed' })}>
                        <strong>FAILURES</strong>
                        <div>{valueFormat('number', getIn(portalStats, `${pp.portal}.failed`, 0))}</div>
                      </Button>
                    </div>
                    <div className="syndication-count syndication-pending">
                      <Button type="button" onClick={e => {
                        e.preventDefault()
                        setPpStatus('pending')
                      }} className={classNames({ active: ppStatus === 'pending' })}>
                        <strong>PENDING</strong>
                        <div>{valueFormat('number', getIn(portalStats, `${pp.portal}.pending`, 0))}</div>
                      </Button>
                    </div>
                    <div className="syndication-count syndication-success">
                      <Button type="button" onClick={e => {
                        e.preventDefault()
                        setPpStatus('success')
                      }} className={classNames({ active: ppStatus === 'success' })}>
                        <strong>SUCCESS</strong>
                        <div>{valueFormat('number', getIn(portalStats, `${pp.portal}.success`, 0) + getIn(portalStats, `${pp.portal}.withdrrawn`, 0))}</div>
                      </Button>
                    </div>
                  </div>
                </div>
                <SimpleTable
                  config={{}}
                  action={({ params, resolve, reject }) => new Promise((res, rej) => actions.fetchMany({
                    noloader: true,
                    values: {
                      modelname: 'portal-configs',
                      endpoint: {
                        read: '/listings/api/v1/portal-configs'
                      },
                      // signal: abortController.current.signal,
                      params: {
                        ...params,
                        meta_fields: [ 'residential', 'commercial', 'project', 'holiday' ],
                        portal: pp.portal
                      }
                    },
                    resolve: res,
                    reject: rej
                  })).then(r => {
                    resolve(r)
                  }).catch(e => {
                    reject(e)
                  })}
                  params={{
                    ...init_params,
                    feed_status: ppStatus,
                    modified__date__gte: current,
                    modified__date__lte: limit,
                    limit: 20
                  }}
                  parser={data => {
                    data.options = data.options.map(o => {
                      o.listing = null
                      if (getIn(o, 'residential')) {
                        o.listing = getIn(o, 'residential')
                        o.meta.listing = getIn(o.meta, 'residential')
                      } else if (getIn(o, 'commercial')) {
                        o.listing = getIn(o, 'commercial')
                        o.meta.listing = getIn(o.meta, 'commercial')
                      } else if (getIn(o, 'project')) {
                        o.listing = getIn(o, 'project')
                        o.meta.listing = getIn(o.meta, 'project')
                      } else if (getIn(o, 'holiday')) {
                        o.listing = getIn(o, 'holiday')
                        o.meta.listing = getIn(o.meta, 'holiday')
                      }
                      return o
                    })
                    return data
                  }}
                  header={[
                    {
                      label: 'Address',
                      name: [
                        'listing.unit_number',
                        'listing.complex_name',
                        ',',
                        'listing.building_name',
                        ',',
                        'listing.street_number',
                        'listing.street_name'
                      ],
                      container: 'meta',
                      orderable: false,
                      link: '/secure/:meta.listing.model/:listing'
                    },
                    {
                      label: 'Reason',
                      name: 'last_message',
                      orderable: false
                    },
                    {
                      label: 'Date',
                      name: 'modified',
                      orderable: false,
                      format: 'datetime'
                    }
                  ]}
                  user={user}
                />
                <div className="list-actions">
                  {listingModel ? (
                    <Button component={NavLink} className="btn btn-subtle" to={`/secure/syndication/${listingModel}`}>View entire log</Button>
                  ) : null}
                </div>
              </div>
            </>
          ) : null}
        </div>
      }
    />
  )
}

SyndicationWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  configs: PropTypes.object,
  portals: PropTypes.object
}

const mapStateToProps = state => {
  const user = MINUSER(state)
  const portals = PORTALS(state)
  return ({
    user,
    portals
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(withDelay(SyndicationWidget)))
