import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'react-fast-compare'
import { ErrorMessage, getIn } from 'formik'
import Select from 'react-select'
import classNames from 'classnames'

import { isConditional, uniqueArray } from '../../../../utils'
import { responsiveSelect } from './ResponsiveSelect'
import Label from './Label'


const ResponsiveSelect = responsiveSelect(Select)

const SelectInput = props => {
  const findValues = (v, options, values) => {
    if ([ null, undefined ].includes(v)) {
      return []
    }
    options.forEach(o => {
      if (o.options) {
        values = [ ...values, ...findValues(v, o.options, []) ]
      }
      // eslint-disable-next-line
      if ((Array.isArray(v) && v.includes(o.value)) || (!Array.isArray(v) && v == o.value)) {
        values.push(o)
      }
    })
    return uniqueArray(values.sort((a, b) => v.toString().indexOf(a.value) - v.toString().indexOf(b.value)), 'value')
  }

  const showOptions = options => {
    let shownoptions = []
    if (options.some(o => o.show)) {
      options.forEach(o => {
        if (o.show) {
          if (isConditional(o, 'show', true, props.form, props.user, props.cache)) {
            shownoptions.push(o)
          }
        } else {
          return shownoptions.push(o)
        }
        return null
      })
    } else {
      shownoptions = options
    }
    return uniqueArray(shownoptions, 'value')
  }

  const handleChange = v => {
    if (v) {
      let vals = []
      if (Array.isArray(v)) {
        if (v.length > 0) { // Array value with values
          v.forEach(i => {
            if (Array.isArray(i.value)) {
              vals.push(i.value[0])
            } else {
              vals.push(i.value)
            }
          })
        } else {
          vals = v
        }
      } else if (props.multi && v.value) {
        vals.push(v.value)
      } else if (v) { // Single value
        vals = v.value
      } else if (v.length) {
        vals = v
      } else {
        vals = null
      }
      props.form.setFieldValue(props.field.name, vals).then(() => {
        props.form.setFieldTouched(props.field.name)
      })
      if (props.dependents) { /* Unset any dependents */
        props.dependents.forEach(dependent => {
          props.form.setFieldValue(dependent, null, false)
        })
      }
    } else {
      props.form.setFieldValue(props.field.name, null).then(() => {
        props.form.setFieldTouched(props.field.name)
      })
    }
  }

  const [ val, setVal ] = useState(props.field.value)
  const [ shown_options, setShownOptions ] = useState(showOptions(props.options))
  const ref = useRef(null)

  useEffect(() => {
    let v
    if (props.field.value !== undefined) {
      v = props.field.value
    } else if (getIn(props, 'match.params.action') !== 'bulkedit' && 'defaultvalue' in props && !props.search) {
      v = props.defaultvalue
    }
    const values = findValues(v, props.options, [])
    const initialValue = props.multi ? values : values[0]

    setVal(initialValue)
  }, [])

  useEffect(() => {
    setShownOptions(showOptions(props.options))
  }, [ props.options ])

  useEffect(() => {
    if (val && (
      (Array.isArray(val) && val.length)
      || (!Array.isArray(val))
    ) && !props.field.value) {
      handleChange(val)
    }
  }, [ val ])

  useEffect(() => {
    let v = props.field.value
    if (v === undefined && getIn(props, 'match.params.action') !== 'bulkedit' && 'defaultvalue' in props && !props.search) {
      v = props.defaultvalue
    }
    const initialValue = findValues(v, props.options, [])
    const newval = props.multi ? initialValue : initialValue[0]
    if (!isEqual(newval, val)) {
      setVal(newval)
    }
  }, [ props.field.value, props.defaultvalue, shown_options ])

  const {
    field,
    form,
    id,
    noclear,
    placeholder,
    multi,
    classes,
    label,
    readonly,
    disabled,
    closemenuonselect
  } = props

  if (!field.name) { return null }
  return (
    <div
      id={id}
      key={`select-${field.name}`}
      className={classNames('selectinput', field.name, 'form-group', classes)}
      ref={ref}
    >
      <Label htmlFor={field.name}>{label}</Label>
      <div className="forminput">
        {props.prefix}
        <ResponsiveSelect
          className={classNames('react-select', { 'input-group-prefix': props.prefix, 'input-group-suffix': props.suffix })}
          classNamePrefix="react-select"
          options={shown_options}
          isDisabled={readonly || disabled ? true : false}
          getOptionLabel={opt => opt.label }
          getOptionValue={opt => opt.value || ''}
          name={field.name}
          form={form}
          field={field}
          inputId={field.name}
          isMulti={multi}
          value={val}
          onChange={props.onChange || handleChange}
          closeMenuOnSelect={closemenuonselect}
          blurInputOnSelect={closemenuonselect}
          onSelectResetsInput={closemenuonselect}
          backspaceRemovesValue={closemenuonselect}
          placeholder={placeholder}
          isClearable={noclear ? false : true}
        />
        {props.suffix}
      </div>
      <ErrorMessage render={msg => <div className="error">{msg}</div>} name={field.name} />
    </div>
  )
}

SelectInput.propTypes = {
  id: PropTypes.string.isRequired,
  form: PropTypes.object,
  user: PropTypes.object,
  cache: PropTypes.object,
  match: PropTypes.object,
  disabled: PropTypes.bool,
  search: PropTypes.bool,
  readonly: PropTypes.bool,
  field: PropTypes.object.isRequired,
  dependents: PropTypes.array,
  classes: PropTypes.string,
  defaultvalue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  noclear: PropTypes.bool,
  placeholder: PropTypes.string,
  multi: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  closemenuonselect: PropTypes.bool,
  prefix: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  suffix: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  options: PropTypes.array.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

export default SelectInput
