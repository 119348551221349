/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isObject } from 'formik'
import { Button } from '../../../ui/Button'
import { Scrollbar } from '../../../ui/Scrollbars'
import WideSidebar from '../../../ui/sidebar/WideSidebar'
import RecipientSelectSidebar from '../../../../containers/RecipientSelectSidebar'
import RecipientsManager from '../../../../containers/RecipientsManager'
import Loader from '../../../common/Loader'
import { uniqueArray } from '../../../../utils'


const RecipientLookup = props => {
  const {
    id,
    settings,
    field,
    form,
    user,
    match,
    label,
    toggleWideSidebar,
    models,
    updateMailRecipients,
    fetchMany
  } = props
  const { name, value } = field

  const [ loading, setLoading ] = useState(false)

  const defaultvalue = {
    count: 0,
    agents: {
      selected: [],
      allselected: []
    },
    profiles: {
      selected: [],
      allselected: []
    },
    contacts: {
      selected: [],
      allselected: []
    }
  }

  const deleteSelected = (refreshpage, selected) => {
    let newrecipients = field.value
    if (!isObject(newrecipients)) { newrecipients = { ...defaultvalue } }
    new Promise((resolve, reject) => {
      const params = {
        values: {
          id: form.values?.id,
          delete: selected
        },
        resolve,
        reject
      }
      return updateMailRecipients(params)
    }).then(r => {
      newrecipients.count = r.count
      form.setFieldValue('recipients', newrecipients)
      refreshpage()
    }).catch(e => {
      console.error(e)
    })
  }

  const addSelected = (modelname, selected) => {
    setLoading(true)
    let newrecipients = { ...field.value }
    if (!isObject(newrecipients)) { newrecipients = { ...defaultvalue } }
    selected.forEach(s => newrecipients[modelname].selected.push(s))
    newrecipients[modelname].selected = uniqueArray(newrecipients[modelname].selected)
    new Promise((resolve, reject) => {
      const params = {
        values: {
          id: form.values?.id,
          ...newrecipients
        },
        resolve,
        reject
      }
      return updateMailRecipients(params)
    }).then(res => {
      newrecipients.count = res.count
      form.setFieldValue('recipients', newrecipients)
      setLoading(false)
    }).catch(e => {
      console.error(e)
      setLoading(false)
    })
  }

  const addAll = (modelname, model) => {
    setLoading(true)
    let newrecipients = field.value
    if (!isObject(newrecipients)) { newrecipients = { ...defaultvalue } }
    const cleanedparams = model.params
    delete cleanedparams.limit
    Object.keys(cleanedparams).forEach(key => {
      if (cleanedparams[key] === '' || cleanedparams[key] === null || isNaN(cleanedparams[key])) {
        delete cleanedparams[key]
      }
    })
    newrecipients[modelname].allselected = { ...newrecipients[modelname].allselected, ...cleanedparams }
    new Promise((resolve, reject) => {
      const params = {
        values: {
          id: form.values?.id,
          ...newrecipients
        },
        resolve,
        reject
      }
      return updateMailRecipients(params)
    }).then(res => {
      newrecipients.count = res.count
      form.setFieldValue('recipients', newrecipients)
      setLoading(false)
    }).catch(e => {
      // eslint-disable-next-line no-console
      console.log(e)
      setLoading(false)
    })
  }

  const resetSelected = () => {
    const newrecipients = defaultvalue
    new Promise((resolve, reject) => {
      const params = {
        values: {
          id: form.values?.id,
          delete: true,
          ...defaultvalue
        },
        resolve,
        reject
      }
      return updateMailRecipients(params)
    }).then(res => {
      newrecipients.count = res.count
      form.setFieldValue('recipients', newrecipients)
      setLoading(false)
    }).catch(e => {
      // eslint-disable-next-line no-console
      console.error(e)
      setLoading(false)
    })
  }

  useEffect(() => {
    new Promise((resolve, reject) => {
      let params = { marketing_email: form.values?.id }
      if (props.modelname === 'brand-assets') {
        params = { brand_asset: form.values?.id }
      }
      fetchMany({
        values: {
          modelname: props.modelname === 'brand-assets' ? 'brand-recipients' : 'email-recipients',
          modellist: true,
          params
        },
        resolve,
        reject
      })
    }).then(res => {
      const newrecipients = field.value ? field.value : defaultvalue
      newrecipients.count = res.count
      form.setFieldValue('recipients', newrecipients)
      setLoading(false)
    }).catch(e => {
      console.error(e)
      setLoading(false)
    })
  }, [])

  if (models) {
    return (
      <div id={id} className={`form-group recipient-lookup ${props.classes ? props.classes : ''}`}>
        {loading ? (
          <Loader inline={true} />
        ) : (
          <>
            {models.includes('contacts') &&
            <div>
              <Button
                type="button"
                id={`${name}-contact-lookup-search-btn`}
                tabIndex="-1"
                icon="#icon16-Plus"
                onClick={() => toggleWideSidebar(`show-${name}-contacts-search`) }
                className="btn btn-grey btn-icon-left btn-icon-16"
              >Contacts</Button>

              <WideSidebar sidebar={`show-${name}-contacts-search`}>
                <RecipientSelectSidebar
                  label={label}
                  field={field}
                  modelname="contacts"
                  user={user}
                  key="recipient-search-contacts"
                  addSelected={(modelname, selected) => addSelected(modelname, selected)}
                  addAll={(modelname, model) => addAll(modelname, model)}
                  settings={settings}
                  siblingform={form}
                  match={match}
                />
              </WideSidebar>
            </div>
            }

            {models.includes('profiles') &&
            <div>
              <Button
                type="button"
                id={`${name}-contact-lookup-search-btn`}
                tabIndex="-1"
                icon="#icon16-Plus"
                onClick={() => toggleWideSidebar(`show-${name}-profiles-search`) }
                className="btn btn-grey btn-icon-left btn-icon-16"
              >Profiles</Button>

              <WideSidebar sidebar={`show-${name}-profiles-search`}>
                <RecipientSelectSidebar
                  label={label}
                  field={field}
                  modelname="profiles"
                  user={user}
                  key="recipient-search-profles"
                  addSelected={(modelname, selected) => addSelected(modelname, selected)}
                  addAll={(modelname, model) => addAll(modelname, model)}
                  settings={settings}
                  siblingform={form}
                  match={match}
                />
              </WideSidebar>
            </div>
            }

            {models.includes('agents') &&
            <div>
              <Button
                type="button"
                id={`${name}-contact-lookup-search-btn`}
                tabIndex="-1"
                icon="#icon16-Plus"
                onClick={() => toggleWideSidebar(`show-${name}-agents-search`) }
                className="btn btn-grey btn-icon-left btn-icon-16"
              >Agents</Button>

              <WideSidebar sidebar={`show-${name}-agents-search`}>
                <RecipientSelectSidebar
                  label={label}
                  field={field}
                  modelname="agents"
                  user={user}
                  key="recipient-search-agents"
                  addSelected={(modelname, selected) => addSelected(modelname, selected)}
                  addAll={(modelname, model) => addAll(modelname, model)}
                  settings={settings}
                  siblingform={form}
                  match={match}
                />
              </WideSidebar>
            </div>
            }

            <div className="recipients-count">
              <div>
              Sending to
                {field.value && field.value.count ? (
                  <strong> {field.value.count} recipients</strong>
                ) : <strong> 0 recipients</strong>}
              </div>
            </div>
            { field.value?.count ? (
              <>
                <Button icon="#icon16-Refresh" className="btn btn-grey btn-icon-16 btn-icon-left" type='button' onClick={() => resetSelected()}>Reset</Button>
                <Button icon="#icon16-EyeOpen" className="btn btn-grey btn-icon-16 btn-icon-left" type='button' onClick={() => toggleWideSidebar('show-mailer-recipients')}>View Recipients</Button>
                <WideSidebar sidebar={'show-mailer-recipients'}>
                  <div id="recipient-selector-sidebar" ref={el => el} className="wide-sidebar recipient-selector-sidebar">
                    <div className="wide-sidebar-container">
                      <div className="wide-sidebar-pane">
                        <div className="wide-sidebar-heading">
                          <h4>Recipients</h4>
                          <Button
                            type="button"
                            icon="#icon24-X-Large"
                            className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                            onClick={() => { toggleWideSidebar() }}
                          />
                        </div>
                        <Scrollbar
                          style={{ height: 'calc(100vh - 218px)' }}
                          renderView={({ style, ...rest }) => <div {...rest} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                        >
                          <div className="wide-sidebar-content">
                            <RecipientsManager
                              match={match}
                              modelname={props.modelname}
                              model={form.values}
                              user={user}
                              deleteSelected={deleteSelected}
                            />
                          </div>
                        </Scrollbar>
                      </div>
                    </div>
                  </div>
                </WideSidebar>
              </>
            ) : null}
          </>
        ) }
      </div>
    )
  }

  return (
    <div id={id} className={`form-group recipient-lookup ${props.classes ? props.classes : ''}`}>
      {loading ? (
        <Loader inline={true} />
      ) : (
        <>
          <Button
            type="button"
            id={`${name}-contact-lookup-search-btn`}
            tabIndex="-1"
            icon="#icon16-Plus"
            onClick={() => toggleWideSidebar(`show-${name}-contacts-search`) }
            className="btn btn-grey btn-icon-left btn-icon-16"
          >Contacts</Button>

          <WideSidebar sidebar={`show-${name}-contacts-search`}>
            <RecipientSelectSidebar
              label={label}
              field={field}
              modelname="contacts"
              user={user}
              key={`recipient-search-${name}`}
              addSelected={(modelname, selected) => addSelected(modelname, selected)}
              addAll={(modelname, model) => addAll(modelname, model)}
              settings={settings}
              siblingform={form}
              match={match}
            />
          </WideSidebar>

          <div className="recipients-count">
              Sending to
            {field.value && field.value.count ? (
              <strong> {field.value.count} recipients</strong>
            ) : <strong> 0 recipients</strong>}
          </div>
        </>
      )}
    </div>
  )
}

RecipientLookup.propTypes = {
  id: PropTypes.string.isRequired,
  toggleWideSidebar: PropTypes.func.isRequired,
  fetchOne: PropTypes.func.isRequired,
  fetchMany: PropTypes.func.isRequired,
  updateMailRecipients: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  classes: PropTypes.string,
  modelname: PropTypes.string,
  form: PropTypes.object.isRequired,
  className: PropTypes.string,
  cache: PropTypes.object,
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  model: PropTypes.object,
  match: PropTypes.object,
  selected: PropTypes.array,
  config: PropTypes.object,
  settings: PropTypes.object,
  setIgnoreFields: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.object,
  user: PropTypes.object,
  models: PropTypes.array
}


export default RecipientLookup
