/* eslint-disable new-cap */
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'
import { Map } from 'immutable'

import TableManager from '../components/common/datatable/TableManager'
import { PREFERENCES, CLEANUSER, CONFIG, UI } from '../selectors'
import * as actions from '../actions'


const mapStateToProps = (state, ownProps) => {
  const user = CLEANUSER(state)
  const preferences = ownProps.preferences?.column_preferences || PREFERENCES(state, ownProps.modelname)
  const { modelname } = ownProps
  const config = ownProps.config || CONFIG(state, modelname)
  const ui = UI(state)
  return {
    modelname,
    config,
    preferences,
    ui,
    user: Map({
      preferences: ownProps.preferences ? { [ownProps.modelname]: ownProps.preferences } : user.get('preferences'),
      availablePreferences: ownProps.availablePreferences ? ownProps.availablePreferences : user.get('availablePreferences'),
      permissions: user.get('permissions'),
      agent: user.get('agent')
    })
  }
}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  // eslint-disable-next-line no-unused-vars
  const { actions: overrides, preferences, availablePreferences, ...rest } = ownProps
  return ({
    ...stateProps,
    actions: { ...dispatchProps.actions, ...overrides },
    ...rest
  })
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(withImmutablePropsToJS(TableManager))
