/* eslint-disable new-cap */
import { getIn } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { PolarAngleAxis, RadialBar, RadialBarChart } from 'recharts'
import { bindActionCreators } from 'redux'
import withImmutablePropsToJS from 'with-immutable-props-to-js'

import { fetchMany } from '../../actions'
import { MINUSER } from '../../selectors'
import { textToDate, valueFormat } from '../../utils'
import Card from '../common/Card'
import Loader from '../common/Loader'
import { Button } from '../ui/Button'
import { ResponsiveContainer } from '../ui/graphs/ResizeContainer'
import InlineBranchSelect from './inputs/InlineBranchSelect'
import InlineAgentSelect from './inputs/InlineAgentSelect'
import InlineListingTypeSelect from './inputs/InlineListingTypeSelect'
import InlinePeriodSelect from './inputs/InlinePeriodSelect'


const { start: defaultStart, end: defaultEnd, days: defaultDays } = textToDate('LAST_30_DAYS')

const dateFilter = dates => dates.filter(o => !o.value.includes('NEXT') && !o.value.includes('TOMORROW'))

const getColour = value => {
  if (value < 65) { return '#FF6464' }
  if (value < 80) { return '#FFA564' }
  return '#73C677'
}

const ListingQualityWidget = ({ user, actions }) => {
  const abortController = useRef(new AbortController())
  const agentoptions = [ { first_name: 'All', last_name: 'Agents', id: '' } ]
  if (user.agent.id) {
    agentoptions.push({ first_name: user.agent.first_name, last_name: user.agent.last_name, id: user.agent.id })
  }

  const [ branchId, setBranchId ] = useState('')
  const [ agentId, setAgentId ] = useState(user.agent.id || '')
  const [ listingModel, setListingModel ] = useState('')
  const [ { start: current, end: limit, period }, setPeriodData ] = useState({ start: valueFormat('shortdate', defaultStart.toString()), end: valueFormat('shortdate', defaultEnd.toString()), days: defaultDays, period: 'LAST_30_DAYS' })

  const [ loading, setLoading ] = useState(true)
  const [ statistics, setStatistics ] = useState({
    agent_photos: 0,
    description: 0,
    mapped: 0,
    photography: 0,
    property_size: 0,
    rates_levies: 0,
    video: 0,
    virtual_tour: 0
  })

  const filterListingModel = useCallback(() => {
    let model
    let listing_type
    switch (listingModel) {
      case 'residential_for_sale':
        model = 'residential'
        listing_type = 'For Sale'
        break
      case 'residential_to_let':
        model = 'residential'
        listing_type = 'To Let'
        break
      case 'commercial_for_sale':
        model = 'commercial'
        listing_type = 'For Sale'
        break
      case 'commercial_to_let':
        model = 'commercial'
        listing_type = 'To Let'
        break
      case 'projects':
        model = 'projects'
        break
      case 'holiday':
        model = 'holiday'
        break
      default:
        break
    }
    // eslint-disable-next-line no-console
    console.log(model, listingModel)
    if (!model) { return }
    setLoading(true)
    setStatistics({
      photography: 0,
      description: 0,
      mapped: 0,
      virtual_tour: 0,
      video: 0,
      agent_photos: 0,
      property_size: 0,
      rates_levies: 0
    })
    // eslint-disable-next-line no-console
    console.log('fetching')
    new Promise((resolve, reject) => actions.fetchMany({
      values: {
        modelname: model,
        endpoint: {
          read: `/listings/api/v1/${model}/quality-scores`
        },
        signal: abortController.current.signal,
        fields: [ 'id' ],
        params: {
          get_count: 1,
          status: 'Active',
          listing_type,
          agents__in: agentId,
          branch: branchId,
          created__date__gte: current,
          created__date__lte: limit
        }
      },
      resolve,
      reject
    })).then(r => {
      const stats = {}
      const categories = [ 'photography', 'description', 'mapped', 'virtual_tour', 'video', 'agent_photos', 'property_size', 'rates_levies' ]
      categories.forEach(stat => {
        stats[stat] = getIn(r, stat) ? Math.round(getIn(r, stat) / getIn(r, 'count') * 100) : getIn(r, stat)
      })
      setStatistics(stats)
    }).catch(e => {
      // eslint-disable-next-line no-console
      console.trace('failed')
      if (e.status !== 408) { console.error(e) }
    }).finally(() => {
      setLoading(false)
    })
  }, [ period, current, limit, agentId, branchId, listingModel ])

  useEffect(() => {
    filterListingModel()
  }, [ period, agentId, branchId, listingModel ])

  useEffect(() => () => {
    abortController.current.abort()
  }, [])

  return (
    <Card
      id="listing-quality-widget"
      classes="grid-col-1"
      bodyclass="stats-card no-top-padding"
      background
      header={
        <>
          <h3>Listing Quality</h3>
          <div className="details-section-buttons min-flex tablemeta">
            <InlineBranchSelect
              user={user}
              actions={actions}
              selectedValue={branchId}
              onChange={e => {
                setBranchId(e.value)
                setAgentId('')
              }}
            />
            <InlineAgentSelect
              user={user}
              actions={actions}
              branchId={branchId}
              selectedValue={agentId}
              onChange={e => {
                setAgentId(e.value)
              }}
            />
            <InlineListingTypeSelect
              user={user}
              selectedValue={listingModel}
              onChange={e => {
                setListingModel(e.value)
              }}
            />
            <InlinePeriodSelect
              optionFilter={dateFilter}
              selectedValue={period}
              onChange={setPeriodData}
            />
          </div>
        </>
      }
      body={
        !loading ? (
          <div className={`flex-container flex-quality${[ 'residential_for_sale', 'residential_to_let', 'commercial_for_sale', 'commercial_to_let' ].includes(listingModel) ? '' : ' flex-3'}`}>
            <div className="quality-block">
              <div className="quality-graph">
                <ResponsiveContainer width="100%" height={100}>
                  <RadialBarChart startAngle={90} endAngle={-270} cx="50%" cy="50%" innerRadius="90%" outerRadius="100%" barSize={6} margin={0} data={[ { value: statistics.photography, fill: getColour(statistics.photography) } ]}>
                    <PolarAngleAxis
                      type="number"
                      domain={[ 0, 100 ]}
                      angleAxisId={0}
                      tick={false}
                    />
                    <RadialBar
                      angleAxisId={0}
                      minAngle={1}
                      cornerRadius={3}
                      background={{ fill: '#ffffff' }}
                      clockWise
                      dataKey="value"
                      isAnimationActive={false}
                    />
                    <path transform='translate(31.125 31.125)' d="M19.0002 27.0034L28.176 17.8276C29.7386 16.2654 32.2717 16.2654 33.8344 17.8276L37.0077 21.0009M1.69897 32.2936L10.1625 23.8301C11.7251 22.2679 14.2582 22.2679 15.8209 23.8301L28.6602 36.6694M0.992676 8.99589C0.992676 4.57577 4.57589 0.992554 8.99601 0.992554H29.0043C33.4245 0.992554 37.0077 4.57577 37.0077 8.99589V29.0042C37.0077 33.4243 33.4245 37.0076 29.0043 37.0076H8.99601C4.57589 37.0076 0.992676 33.4243 0.992676 29.0042V8.99589ZM12.9977 9.9963C12.9977 11.6538 11.654 12.9976 9.99643 12.9976C8.33888 12.9976 6.99518 11.6538 6.99518 9.9963C6.99518 8.33876 8.33888 6.99505 9.99643 6.99505C11.654 6.99505 12.9977 8.33876 12.9977 9.9963Z" stroke="#10294D" fill="none" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                  </RadialBarChart>
                </ResponsiveContainer>
              </div>
              <div className='quality-content'>
                <h3>Photography</h3>
                <strong>8+ Photos</strong>
                <h2 style={{ color: getColour(statistics.photography) }}>{valueFormat('percent', statistics.photography)}</h2>
                <Button
                  component={NavLink}
                  to={`/secure/${user.agent.site.id}/${listingModel.replace(/(_for_sale|_to_let)/gi, '')}?${listingModel.includes('for_sale') ? 'listing_type__in=For Sale&' : ''}${listingModel.includes('to_let') ? 'listing_type__in=To Let&' : ''}status__in=Active&quality__photography=0${agentId ? `&agents__in=${agentId}` : ''}${branchId ? `&branch=${branchId}` : ''}${current ? `&created__date__gte=${current}` : ''}${limit ? `&created__date__lte=${limit}` : ''}`}
                  className="btn btn-white btn-icon-right btn-icon-16"
                  icon="#icon16-ArrowExpand"
                >
                  Resolve
                </Button>
              </div>
            </div>
            <div className="quality-block">
              <div className="quality-graph">
                <ResponsiveContainer width="100%" height={100}>
                  <RadialBarChart startAngle={90} endAngle={-270} cx="50%" cy="50%" innerRadius="90%" outerRadius="100%" barSize={6} margin={0} data={[ { value: statistics.description, fill: getColour(statistics.description) } ]}>
                    <PolarAngleAxis
                      type="number"
                      domain={[ 0, 100 ]}
                      angleAxisId={0}
                      tick={false}
                    />
                    <RadialBar
                      angleAxisId={0}
                      minAngle={1}
                      cornerRadius={3}
                      background={{ fill: '#ffffff' }}
                      clockWise
                      dataKey="value"
                      isAnimationActive={false}
                    />
                    <path transform='translate(32 37.125)' d="M0.742676 0.994995H36.7577M28.7543 13H0.742676M0.742676 25.005H20.751" stroke="#10294D" fill="none" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                  </RadialBarChart>
                </ResponsiveContainer>
              </div>
              <div className='quality-content'>
                <h3>Description</h3>
                <strong>Min 250 characters</strong>
                <h2 style={{ color: getColour(statistics.description) }}>{valueFormat('percent', statistics.description)}</h2>
                <Button
                  component={NavLink}
                  to={`/secure/${user.agent.site.id}/${listingModel.replace(/(_for_sale|_to_let)/gi, '')}?${listingModel.includes('for_sale') ? 'listing_type__in=For Sale&' : ''}${listingModel.includes('to_let') ? 'listing_type__in=To Let&' : ''}status__in=Active&quality__description=0${agentId ? `&agents__in=${agentId}` : ''}${branchId ? `&branch=${branchId}` : ''}${current ? `&created__date__gte=${current}` : ''}${limit ? `&created__date__lte=${limit}` : ''}`}
                  className="btn btn-white btn-icon-right btn-icon-16"
                  icon="#icon16-ArrowExpand"
                >
                  Resolve
                </Button>
              </div>
            </div>
            <div className="quality-block">
              <div className="quality-graph">
                <ResponsiveContainer width="100%" height={100}>
                  <RadialBarChart startAngle={90} endAngle={-270} cx="50%" cy="50%" innerRadius="90%" outerRadius="100%" barSize={6} margin={0} data={[ { value: statistics.mapped, fill: getColour(statistics.mapped) } ]}>
                    <PolarAngleAxis
                      type="number"
                      domain={[ 0, 100 ]}
                      angleAxisId={0}
                      tick={false}
                    />
                    <RadialBar
                      angleAxisId={0}
                      minAngle={1}
                      cornerRadius={3}
                      background={{ fill: '#ffffff' }}
                      clockWise
                      dataKey="value"
                      isAnimationActive={false}
                    />
                    <g transform='translate(34.25 31.85)'>
                      <path fillRule="evenodd" clipRule="evenodd" d="M27.0526 5.04417C32.881 10.8726 32.881 20.3223 27.0526 26.1508L18.9862 34.2172C17.6127 35.5906 15.3859 35.5906 14.0124 34.2172L5.94603 26.1508C0.1176 20.3223 0.1176 10.8726 5.94603 5.04417C11.7745 -0.784255 21.2242 -0.784255 27.0526 5.04417Z" stroke="#10294D" fill="none" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M21.6208 15.5977C21.6208 18.4258 19.3281 20.7185 16.5 20.7185C13.6718 20.7185 11.3791 18.4258 11.3791 15.5977C11.3791 12.7695 13.6718 10.4768 16.5 10.4768C19.3281 10.4768 21.6208 12.7695 21.6208 15.5977Z" stroke="#10294D" fill="none" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                  </RadialBarChart>
                </ResponsiveContainer>
              </div>
              <div className='quality-content'>
                <h3>Mapped</h3>
                <strong>To address/suburb</strong>
                <h2 style={{ color: getColour(statistics.mapped) }}>{valueFormat('percent', statistics.mapped)}</h2>
                <Button
                  component={NavLink}
                  to={`/secure/${user.agent.site.id}/${listingModel.replace(/(_for_sale|_to_let)/gi, '')}?${listingModel.includes('for_sale') ? 'listing_type__in=For Sale&' : ''}${listingModel.includes('to_let') ? 'listing_type__in=To Let&' : ''}status__in=Active&quality__mapped=0${agentId ? `&agents__in=${agentId}` : ''}${branchId ? `&branch=${branchId}` : ''}${current ? `&created__date__gte=${current}` : ''}${limit ? `&created__date__lte=${limit}` : ''}`}
                  className="btn btn-white btn-icon-right btn-icon-16"
                  icon="#icon16-ArrowExpand"
                >
                  Resolve
                </Button>
              </div>
            </div>
            <div className="quality-block">
              <div className="quality-graph">
                <ResponsiveContainer width="100%" height={100}>
                  <RadialBarChart startAngle={90} endAngle={-270} cx="50%" cy="50%" innerRadius="90%" outerRadius="100%" barSize={6} margin={0} data={[ { value: statistics.virtual_tour, fill: getColour(statistics.virtual_tour) } ]}>
                    <PolarAngleAxis
                      type="number"
                      domain={[ 0, 100 ]}
                      angleAxisId={0}
                      tick={false}
                    />
                    <RadialBar
                      angleAxisId={0}
                      minAngle={1}
                      cornerRadius={3}
                      background={{ fill: '#ffffff' }}
                      clockWise
                      dataKey="value"
                      isAnimationActive={false}
                    />
                    <path transform='translate(31.15 31.15)' d="M10.25 1H5.75C3.264 1 1.25 3.014 1.25 5.5V10M1.25 28V32.5C1.25 34.986 3.264 37 5.75 37H10.25M28.25 37H32.75C35.236 37 37.25 34.986 37.25 32.5V28M37.25 10V5.5C37.25 3.014 35.236 1 32.75 1H28.25M23 20.22L27.216 23.614C28.034 24.272 29.25 23.69 29.25 22.64V15.36C29.25 14.31 28.034 13.728 27.216 14.386L23 17.78M20.5 25.25H11.75C10.37 25.25 9.25 24.13 9.25 22.75V15.25C9.25 13.87 10.37 12.75 11.75 12.75H20.5C21.88 12.75 23 13.87 23 15.25V22.75C23 24.13 21.88 25.25 20.5 25.25Z" stroke="#10294D" fill="none" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                  </RadialBarChart>
                </ResponsiveContainer>
              </div>
              <div className='quality-content'>
                <h3>Virtual Tour</h3>
                <strong>Added to listing</strong>
                <h2 style={{ color: getColour(statistics.virtual_tour) }}>{valueFormat('percent', statistics.virtual_tour)}</h2>
                <Button
                  component={NavLink}
                  to={`/secure/${user.agent.site.id}/${listingModel.replace(/(_for_sale|_to_let)/gi, '')}?${listingModel.includes('for_sale') ? 'listing_type__in=For Sale&' : ''}${listingModel.includes('to_let') ? 'listing_type__in=To Let&' : ''}status__in=Active&quality__virtual_tour=0${agentId ? `&agents__in=${agentId}` : ''}${branchId ? `&branch=${branchId}` : ''}${current ? `&created__date__gte=${current}` : ''}${limit ? `&created__date__lte=${limit}` : ''}`}
                  className="btn btn-white btn-icon-right btn-icon-16"
                  icon="#icon16-ArrowExpand"
                >
                  Resolve
                </Button>
              </div>
            </div>
            <div className="quality-block">
              <div className="quality-graph">
                <ResponsiveContainer width="100%" height={100}>
                  <RadialBarChart startAngle={90} endAngle={-270} cx="50%" cy="50%" innerRadius="90%" outerRadius="100%" barSize={6} margin={0} data={[ { value: statistics.video, fill: getColour(statistics.video) } ]}>
                    <PolarAngleAxis
                      type="number"
                      domain={[ 0, 100 ]}
                      angleAxisId={0}
                      tick={false}
                    />
                    <RadialBar
                      angleAxisId={0}
                      minAngle={1}
                      cornerRadius={3}
                      background={{ fill: '#ffffff' }}
                      clockWise
                      dataKey="value"
                      isAnimationActive={false}
                    />
                    <g transform='translate(31.125 31.125)'>
                      <path fillRule="evenodd" clipRule="evenodd" d="M16.882 13.1159L24.646 17.7079C25.628 18.2879 25.628 19.7099 24.646 20.2899L16.882 24.8819C15.882 25.4739 14.618 24.7519 14.618 23.5899V14.4079C14.618 13.2459 15.882 12.5239 16.882 13.1159Z" stroke="#10294D" fill="none" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                      <path fillRule="evenodd" clipRule="evenodd" d="M31 36.9999H7C3.686 36.9999 1 34.3139 1 30.9999V6.99994C1 3.68594 3.686 0.999939 7 0.999939H31C34.314 0.999939 37 3.68594 37 6.99994V30.9999C37 34.3139 34.314 36.9999 31 36.9999Z" stroke="#10294D" fill="none" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                    </g>
                  </RadialBarChart>
                </ResponsiveContainer>
              </div>
              <div className='quality-content'>
                <h3>Video</h3>
                <strong>Added to listing</strong>
                <h2 style={{ color: getColour(statistics.video) }}>{valueFormat('percent', statistics.video)}</h2>
                <Button
                  component={NavLink}
                  to={`/secure/${user.agent.site.id}/${listingModel.replace(/(_for_sale|_to_let)/gi, '')}?${listingModel.includes('for_sale') ? 'listing_type__in=For Sale&' : ''}${listingModel.includes('to_let') ? 'listing_type__in=To Let&' : ''}status__in=Active&quality__video=0${agentId ? `&agents__in=${agentId}` : ''}${branchId ? `&branch=${branchId}` : ''}${current ? `&created__date__gte=${current}` : ''}${limit ? `&created__date__lte=${limit}` : ''}`}
                  className="btn btn-white btn-icon-right btn-icon-16"
                  icon="#icon16-ArrowExpand"
                >
                  Resolve
                </Button>
              </div>
            </div>
            <div className="quality-block">
              <div className="quality-graph">
                <ResponsiveContainer width="100%" height={100}>
                  <RadialBarChart startAngle={90} endAngle={-270} cx="50%" cy="50%" innerRadius="90%" outerRadius="100%" barSize={6} margin={0} data={[ { value: statistics.agent_photos, fill: getColour(statistics.agent_photos) } ]}>
                    <PolarAngleAxis
                      type="number"
                      domain={[ 0, 100 ]}
                      angleAxisId={0}
                      tick={false}
                    />
                    <RadialBar
                      angleAxisId={0}
                      minAngle={1}
                      cornerRadius={3}
                      background={{ fill: '#ffffff' }}
                      clockWise
                      dataKey="value"
                      isAnimationActive={false}
                    />
                    <path transform='translate(31.125 31.125)' d="M6.01687 31.7334C9.0453 28.7049 13.1527 27.0034 17.4356 27.0034H19.8626C23.0509 27.0035 26.1678 27.9476 28.8204 29.7165L31.6215 31.5813M36.7577 19.0001C36.7577 28.9453 28.6954 37.0076 18.7502 37.0076C8.80491 37.0076 0.742676 28.9453 0.742676 19.0001C0.742676 9.05479 8.80491 0.992554 18.7502 0.992554C28.6954 0.992554 36.7577 9.05479 36.7577 19.0001ZM18.7502 21.5011C15.4365 21.5011 12.7477 18.8148 12.7477 15.5011V14.4957C12.7477 11.182 15.4365 8.49568 18.7502 8.49568C22.0639 8.49568 24.7527 11.182 24.7527 14.4957V15.5011C24.7527 18.8148 22.0639 21.5011 18.7502 21.5011Z" stroke="#10294D" fill="none" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                  </RadialBarChart>
                </ResponsiveContainer>
              </div>
              <div className='quality-content'>
                <h3>Agent Photos</h3>
                <strong>Added to agents</strong>
                <h2 style={{ color: getColour(statistics.agent_photos) }}>{valueFormat('percent', statistics.agent_photos)}</h2>
                <Button
                  component={NavLink}
                  to={`/secure/${user.agent.site.id}/agents/?image__isnull=True&active__in=1${listingModel === 'residential_for_sale' ? '&statistics__rfs_ac__gte=1' : ''}${listingModel === 'residential_to_let' ? '&statistics__rtl_ac__gte=1' : ''}${listingModel === 'commercial_for_sale' ? '&statistics__cfs_ac__gte=1' : ''}${listingModel === 'commercial_to_let' ? '&statistics__ctl_ac__gte=1' : ''}${listingModel === 'holiday' ? '&statistics__h_ac__gte=1' : ''}${listingModel === 'projects' ? '&statistics__cd_ac__gte__or=1&statistics__rd_ac__gte__or=1&statistics__ce_ac__gte__or=1&statistics__re_ac__gte__or=1' : ''}${agentId ? `&agents__in=${agentId}` : ''}${branchId ? `&branch=${branchId}` : ''}`}
                  className="btn btn-white btn-icon-right btn-icon-16"
                  icon="#icon16-ArrowExpand"
                >
                  Resolve
                </Button>
              </div>
            </div>
            {[ 'residential_for_sale', 'residential_to_let', 'commercial_for_sale', 'commercial_to_let' ].includes(listingModel) ? (
              <>
                <div className="quality-block">
                  <div className="quality-graph">
                    <ResponsiveContainer width="100%" height={100}>
                      <RadialBarChart startAngle={90} endAngle={-270} cx="50%" cy="50%" innerRadius="90%" outerRadius="100%" barSize={6} margin={0} data={[ { value: statistics.property_size, fill: getColour(statistics.property_size) } ]}>
                        <PolarAngleAxis
                          type="number"
                          domain={[ 0, 100 ]}
                          angleAxisId={0}
                          tick={false}
                        />
                        <RadialBar
                          angleAxisId={0}
                          minAngle={1}
                          cornerRadius={3}
                          background={{ fill: '#ffffff' }}
                          clockWise
                          dataKey="value"
                          isAnimationActive={false}
                        />
                        <path transform='translate(29.125 31.125)' d="M37.4054 28.8016L35.126 9.1985M37.4054 28.8016C36.3172 28.8016 35.2739 29.2339 34.5045 30.0033C33.735 30.7728 33.3027 31.8164 33.3027 32.9046M37.4054 28.8016C38.4936 28.8016 39.5375 29.2339 40.307 30.0033C41.0764 30.7728 41.5087 31.8164 41.5087 32.9046C41.5087 35.1706 39.6717 37.0075 37.4057 37.0075C36.3175 37.0075 35.2739 36.5753 34.5045 35.8058C33.735 35.0364 33.3027 33.9927 33.3027 32.9046M35.126 9.1985C36.2142 9.1985 37.2577 8.7662 38.0272 7.9967C38.7966 7.2273 39.2289 6.1836 39.2289 5.0955C39.2289 2.82945 37.3919 0.992489 35.1259 0.992489C32.8599 0.992489 31.0229 2.82945 31.0229 5.0955M35.126 9.1985C34.0378 9.1985 32.9941 8.7662 32.2247 7.9967C31.4552 7.2273 31.0229 6.1836 31.0229 5.0955M33.3027 32.9046H9.6977M31.0229 5.0955H11.9771M11.9771 5.0955C11.9771 4.0073 11.5452 2.96367 10.7757 2.19422C10.0062 1.42476 8.9626 0.992489 7.8745 0.992489C5.60845 0.992489 3.77148 2.82945 3.77148 5.0955C3.77148 6.1836 4.20376 7.2273 4.97322 7.9967C5.74268 8.7662 6.78599 9.1985 7.8742 9.1985M11.9771 5.0955C11.9771 6.1836 11.5452 7.2273 10.7757 7.9967C10.0063 8.7662 8.9624 9.1985 7.8742 9.1985M5.59467 28.8016C4.50649 28.8016 3.46288 29.2339 2.69342 30.0033C1.92397 30.7728 1.4917 31.8164 1.4917 32.9046C1.4917 35.1706 3.32866 37.0075 5.59467 37.0075C6.68285 37.0075 7.7265 36.5753 8.4959 35.8058C9.2654 35.0364 9.6977 33.9927 9.6977 32.9046M5.59467 28.8016C7.8607 28.8016 9.6977 30.6385 9.6977 32.9046M5.59467 28.8016L7.8742 9.1985" stroke="#10294D" fill="none" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                      </RadialBarChart>
                    </ResponsiveContainer>
                  </div>
                  <div className='quality-content'>
                    <h3>Property Size</h3>
                    <strong>Captured</strong>
                    <h2 style={{ color: getColour(statistics.property_size) }}>{valueFormat('percent', statistics.property_size)}</h2>
                    <Button
                      component={NavLink}
                      to={`/secure/${user.agent.site.id}/${listingModel.replace(/(_for_sale|_to_let)/gi, '')}?${listingModel.includes('for_sale') ? 'listing_type__in=For Sale&' : ''}${listingModel.includes('to_let') ? 'listing_type__in=To Let&' : ''}status__in=Active&quality__property_size=0${agentId ? `&agents__in=${agentId}` : ''}${branchId ? `&branch=${branchId}` : ''}${current ? `&created__date__gte=${current}` : ''}${limit ? `&created__date__lte=${limit}` : ''}`}
                      className="btn btn-white btn-icon-right btn-icon-16"
                      icon="#icon16-ArrowExpand"
                    >
                      Resolve
                    </Button>
                  </div>
                </div>
                <div className="quality-block">
                  <div className="quality-graph">
                    <ResponsiveContainer width="100%" height={100}>
                      <RadialBarChart startAngle={90} endAngle={-270} cx="50%" cy="50%" innerRadius="90%" outerRadius="100%" barSize={6} margin={0} data={[ { value: statistics.rates_levies, fill: getColour(statistics.rates_levies) } ]}>
                        <PolarAngleAxis
                          type="number"
                          domain={[ 0, 100 ]}
                          angleAxisId={0}
                          tick={false}
                        />
                        <RadialBar
                          angleAxisId={0}
                          minAngle={1}
                          cornerRadius={3}
                          background={{ fill: '#ffffff' }}
                          clockWise
                          dataKey="value"
                          isAnimationActive={false}
                        />
                        <path transform='translate(29.125 32.15)' d="M39.25 11.9999V5.9999C39.25 3.2385 37.0114 0.999939 34.25 0.999939H8.25C5.48858 0.999939 3.25 3.2385 3.25 5.9999V12.9999M39.25 11.9999H36.25C33.4886 11.9999 31.25 14.2385 31.25 16.9999C31.25 19.7614 33.4886 21.9999 36.25 21.9999H39.25M39.25 11.9999C40.3546 11.9999 41.25 12.8954 41.25 13.9999V19.9999C41.25 21.1045 40.3546 21.9999 39.25 21.9999M39.25 21.9999V27.9999C39.25 30.7614 37.0114 32.9999 34.25 32.9999H23.25M9.5498 22.9999V26.9999H12.9498M19.25 25.9999C19.25 30.9705 15.2206 34.9999 10.25 34.9999C5.27944 34.9999 1.25 30.9705 1.25 25.9999C1.25 21.0293 5.27944 16.9999 10.25 16.9999C15.2206 16.9999 19.25 21.0293 19.25 25.9999Z" stroke="#10294D" fill="none" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
                      </RadialBarChart>
                    </ResponsiveContainer>
                  </div>
                  <div className='quality-content'>
                    <h3>Rates / Levies</h3>
                    <strong>Added to listing</strong>
                    <h2 style={{ color: getColour(statistics.rates_levies) }}>{valueFormat('percent', statistics.rates_levies)}</h2>
                    <Button
                      component={NavLink}
                      to={`/secure/${user.agent.site.id}/${listingModel.replace(/(_for_sale|_to_let)/gi, '')}?${listingModel.includes('for_sale') ? 'listing_type__in=For Sale&' : ''}${listingModel.includes('to_let') ? 'listing_type__in=To Let&' : ''}status__in=Active&quality__rates_levies=0${agentId ? `&agents__in=${agentId}` : ''}${branchId ? `&branch=${branchId}` : ''}${current ? `&created__date__gte=${current}` : ''}${limit ? `&created__date__lte=${limit}` : ''}`}
                      className="btn btn-white btn-icon-right btn-icon-16"
                      icon="#icon16-ArrowExpand"
                    >
                      Resolve
                    </Button>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        ) : <div className="empty flex-container" style={{ height: 240 }}><Loader inline className="large" /></div>
      }
    />
  )
}

ListingQualityWidget.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object
}


const mapStateToProps = state => {
  const user = MINUSER(state)
  return ({
    user
  })
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchMany }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withImmutablePropsToJS(ListingQualityWidget))
